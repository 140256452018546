.flexone {
  display: flex;
  justify-content: space-between;
  width: 80%;

  .right {
    /* width: 7vw; */
    display: flex;
    justify-content: space-between;
    .icon {
      background: #ffc000;
      color: white;
      font-size: 2rem;
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }
}

.title {
  font-family: "urbanistl";
  font-size: 24px;
  margin-top: 1vh;
}

.desc {
  color: #7c7979;
  font-family: "urbanistl";
  font-size: 1rem;
  width: 80%;
}

.rt {
  margin-top: 2vh;
  label {
    font-size: 1rem;
    color: #212121;
    font-family: "urbanistl";
  }

  select {
    width: 70%;
    border: 1px solid #9e9e9e;
    height: 7vh;
    color: #9e9e9e;
    padding: 10px;
    border-radius: 6px;
    outline: none;
  }
}

.table {
  margin-top: 5vh;

  width: 70%;
  font-family: "urbanistl";
  .table-container {
    margin: 20px;
  }

  table {
    text-align: center;
    /* height: 45vh; */
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px; /* Adjust the value as needed */
    border: 1px solid #e0e0e0;
    font-size: 14px;
  }

  th,
  td {
    border: 1px solid #e0e0e0;
  }

  th {
    text-align: center;
    background-color: white;
    font-weight: bold;
  }

  td {
    text-align: left;
    padding: 5px;
    border-bottom: 1px solid #e0e0e0;
  }

  input {
    width: 100%;
    border: 1px solid #9e9e9e;
    /* height: 7vh; */
    color: #9e9e9e;
    padding: 10px;
    border-radius: 6px;
    outline: none;
    text-align: right;
  }
}

.flexform3 {
  margin-top: 3vh;
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;

  .left {
    width: 48%;

    font-size: 1.25rem;
    color: #212121;
    font-family: "urbanistl";
    font-weight: 1000;
  }

  .right {
    width: 48%;

    button {
      min-width: 100%;
      background: #5d7202;
      color: white;
      padding: 12px;
      border-radius: 8px;
      font-family: "urbanistl";

      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
        0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
        0 16px 16px rgba(0, 0, 0, 0.12);
    }
  }
}
