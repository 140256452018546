.export_dailyschedule{
  display: flex;
}

.dailyschedule_data_grid {
  padding-top: 3%;
  width: 100%;
  overflow-x: auto;
}





input[type=time]::-webkit-datetime-edit-hour-field:focus,
  input[type=time]::-webkit-datetime-edit-minute-field:focus,
  input[type=time]::-webkit-datetime-edit-second-field:focus,
  input[type=time]::-webkit-datetime-edit-ampm-field:focus,
  input[type=time]::selection  {
    background-color: var(--primary-green);
  }

   .calendar-icon {
      position: absolute;
      right: 10px;
      margin-right: 4px;
      color: white; 
      pointer-events: none; 
   }

     .export_dailyschedule .date-picker-container_dailyschedule .date-input-wrapper .rmdp-input {

      
      border: 1px solid #6C7D02;
      background-color: #6C7D02;
      color: #FFF;
       
      border-radius: 4px; 
      font-size: 16px; 
    
      outline: none; 
      transition: border-color 0.3s;
      align-items: center;
      
      border-radius: 8px;
      width: 200px;
      font-style: normal;
      font-size: var(--export-button);
      padding: 10px 16px;
    
    font-weight: 600;
    cursor: pointer;
    margin-right: 2%;
    height: 50px;

 }

 .export_dailyschedule .date-picker-container_dailyschedule
 .date-input-wrapper .rmdp-input:focus {
  border-color:#6C7D02;
}

.export_dailyschedule .date-picker-container_dailyschedule
.date-input-wrapper {
  position: relative;
}

.export_dailyschedule .date-input-wrapper {
  display: flex;
  align-items: center;
}
.export_dailyschedule .date-input-wrapper .MuiInputBase-root {
  padding-left: 30px;
   
  color: white; 
  background-color: #333; 
}
.dailyschedule_data_grid {
  padding-top: 3%;
  width: 100%;
  overflow-x: auto;
  position: relative;
}

.propActivity-table {
  position: relative;
}

.fixed-column {
  position: sticky;
  left: 0;
  z-index: 1;
  /* background-color: white; */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
}

.fixed-second-column {
  position: sticky;
  left: 62px; 
  z-index: 1;
  /* background-color: white; */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
}

.dailyschedule_data_grid .dailyscheduletableHeaderCell {
  color: var(--Greyscale-900);
  background: var(--Greyscale-50);
  font-family: Urbanist;
  font-size: var(--datagrid-header);
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
  white-space: nowrap; 
  width: 200px;
  white-space: nowrap;
}

.dailyschedule_data_grid .tableBodyCell {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--datagrid-body);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-wrap: wrap;
  width: 200px;
}








