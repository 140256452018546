@import url("https://fonts.googleapis.com/css2?family=Urbanistb:wght@700&display=swap");

.center {
  padding-top: 8rem;
}
.footer-upward-div {
  width: 70%;
  background-color: #ffc000;
  margin-left: 15%;
  position: relative;
  margin-top: 2vh;
  top: 15vh;
}
.footer-flex-div {
  padding: 40px 20px;
  display: flex;
}
.flex-left {
  font-family: Urbanist;
  font-size: 2.25rem;
  width: 70%;
}
.flex-right {
  font-family: Urbanist;
  width: 30%;
  color: white;
  margin-top: 8vh;
}
/* .footer-button {
  padding: 10px 30px;
  background: #6c7d02;
  border-radius: 86px;
  font-size: 18px;
} */

.footer-button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: #6c7d02;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  cursor: pointer;
  padding: 10px 30px;
}

.footer-button:hover {
  transform: scale(1.05);
  border-color: #fff9;
}


.footer-button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.footer-button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

.footer-below-div {
  width: 100%;
  background-color: #212121;
}
.footer-heading {
  color: white;
  font-family: Urbanist;
  font-size: 3.75rem;
}
.footer-text {
  color: white;
  font-family: Urbanist;
  font-size: 0.75rem;
  font-weight: 300;
  margin-top: 2vh;
}
.footer-button2 {
  padding: 10px 30px;
  background: #ffc000;
  border-radius: 86px;
  color: black;
  font-size: 18px;
}

@media screen and (max-width: 1122px) {
  .footer-flex-div {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
  }
  .flex-left {
    font-family: Urbanist;
    font-size: 2.25rem;
    width: 100%;
  }
  .flex-right {
    font-family: Urbanist;
    font-size: 1rem;
    width: 100%;
  }
}
@media screen and (max-width: 672px) {
  .footer-flex-div {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
  }
  .flex-left {
    font-family: Urbanist;
    font-size: 1rem;
    width: 100%;
  }
  .flex-right {
    font-family: Urbanist;
    font-size: 0.5rem;
    width: 100%;
  }
  .footer-upward-div {
    width: 70%;
    background-color: #ffc000;
    margin-left: 15%;
    position: relative;
    top: 15vh;
  }
  .center {
    padding-top: 6rem;
  }
  .footer-heading {
    color: white;
    font-family: Urbanist;
    font-size: 1.5rem;
  }
  .footer-button {
    margin-top: -5vh;
    padding: 10px 30px;
    background: #6c7d02;
    border-radius: 86px;
  }
}

@media (min-width: 784px) and (max-width: 1118px) {
  .footer-upward-div {
    width: 70%;
    background-color: #ffc000;
    margin-left: 15%;
    position: relative;
    top: 15vh;
    margin-top: -5vh;
  }

  .center {
    padding-top: 5rem;
  }
}

@media (min-width: 673px) and (max-width: 783px) {
  .footer-flex-div {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
  }
  .flex-left {
    font-family: Urbanist;
    font-size: 1.5rem;
    width: 100%;
  }
  .flex-right {
    font-family: Urbanist;
    font-size: 0.5rem;
    width: 100%;
  }
  .footer-upward-div {
    width: 70%;
    background-color: #ffc000;
    margin-left: 15%;
    position: relative;
    top: 15vh;
  }
  .center {
    padding-top: 6rem;
  }
  .footer-heading {
    color: white;
    font-family: Urbanist;
    font-size: 2.5rem;
  }
  .footer-button {
    margin-top: -5vh;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 86px;
  }
  .footer-button2 {
    padding: 10px 20px;
    background: #ffc000;
    border-radius: 86px;
    color: black;
    font-size: 14px;
  }
  .text-b {
    font-size: 14px;
  }
}
@media (max-width: 425px) {
  .center {
    padding-top: 5rem;
  }
  .footer-upward-div {
    width: 70%;
    background-color: #ffc000;
    margin-left: 15%;
    position: relative;
    margin-top: 0vh;
    top: 10vh;
  }
  .footer-button {
    padding: 10px 20px;
    font-size: 12px;
  }
  .footer-button2 {
    padding: 10px 20px;
    font-size: 12px;
  }
  .text-b {
    font-size: 12px;
  }
}

.bottom-last {
  background: #212121;
}
.text-b {
  text-align: left;
  color: #ffffff;
  padding: 16px 10px;
  padding-left: 5%;
  font-weight: normal;
}
/* .bottom-last-right{
padding-left: 50%;
} */
