.container {
    width: 70%;
    max-width: 800px;
    padding: 30px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

h4 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
}

.form-field {
    width: 48%; 
    text-align: left;
}


.Input_area,
Text_area {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: 0.3s;
} 

/* input:focus,
textarea:focus {
    border-color: #28a745;
    box-shadow: 0 0 5px rgba(40, 167, 69, 0.3);
} */

Text_area {
    height: 42px;
    resize: none;
}
.rating-group {
    width: 100%;
    margin-top: 10px;
}

.stars {
    display: flex;
    justify-content: center;
    gap: 5px;
}

.star {
    font-size: 30px;
    cursor: pointer;
    color: #ccc;
    transition: 0.3s;
}

.star.filled {
    color: gold;
}

.star:hover {
    transform: scale(1.2);
}
@media (max-width: 768px) {
    .form-field {
        width: 100%; /* One field per row on mobile */
    }
}
