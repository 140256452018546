.profile-header-details {
    display: grid;
    grid-template-columns: auto auto; 
    justify-content: center; 
    align-items: center;
    gap: 12px 30px; 
    font-size: 18px; 
    margin-top: 20px;
}

.profile-header-details strong {
    font-weight: bold;
    text-align: left; 
}

.profile-header-details span {
    text-align: left; 
}


