.daily {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.daily .daily1 {
  width: 60%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding-left: 2%;
  padding-top: 2%;
  padding-right: 2%;
}

.daily1 .Heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.daily1 .Heading svg:first-child {
  margin-right: 10px;
  justify-self: flex-start;
}

.daily1 .Heading svg:last-child {
  margin-left: 10px;
  justify-self: flex-end;
}

.daily1 .activity {
  display: flex;
  flex-direction: column;
  line-height: 40px;
}

.daily1 .activity input {
  border: 1px solid #a7acb1;
  border-radius: 8px;
  outline: none;
  width: 35%;
  height: 3vw;
  padding-left: 3%;
  letter-spacing: 0.8px;
}
.rbc-event-label {
  display: none;
}
.rbc-time-slot {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.rbc-time-slot:nth-child(2) {
  display: none;
}
.activity .checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
}

.activity .check {
  display: grid;
  grid-template-columns: auto auto;
  width: 30%;
}

.activity .check .check1 {
  line-height: 2px;
}

.activity .button {
  display: flex;
  flex-direction: row;
  gap: 3%;
  padding-top: 2%;
}

.activity .button .button1 {
  background: #ffc000;
  border-radius: 8px;
  border: none;
  width: 27%;
  height: 3vw;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}

/* .activity .button .button1:hover{
    color: #FFC000;
    background: #ffffff;
    border: 1px solid #FFC000;
} */

.activity .button .button2 {
  background: #6c7d02;
  border-radius: 8px;
  border: none;
  width: 25%;
  height: 3vw;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}

.activity .button .button2:hover {
  background: #b4ba3d;
}

.popup {
  font-family: Arial, sans-serif;
  line-height: 225%;
}

.popup .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup .top .X {
  color: #999797;
  display: flex;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #c5c4c4;
  align-items: center;
  justify-content: center;
}

.popup .top .X:hover {
  color: #c5c4c4;
  background: #999797;
  cursor: pointer;
}

.popup .button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 3%;
}

.popup .button .button1 {
  background: #ffff;
  border-radius: 8px;
  border: none;
  width: 35%;
  height: 3vw;
  font-size: 15px;
  color: #424242;
  cursor: pointer;
  border: 1px solid #424242;
}

/* .popup .button .button1:hover{
    color: #ffffff;
    background: #535353;
} */

.popup .button .button2 {
  background: #ffc000;
  border-radius: 8px;
  border: none;
  width: 35%;
  height: 3vw;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}

/* .popup .button .button2:hover{
    color: #6C7D02;
    background: #ffffff;
    border: 1px solid #6C7D02;
} */


.MuiOutlinedInput-root {
  padding: 0 !important;
  top: -6px;
  height: 2.6rem !important;
}

.daily .daily2 {
  width: 40%;
  padding-left: 8%;
  padding-top: 0.5%;
}

.Tablerow1 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #212121;
}

.Tablerow2 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #212121;
}

/* BIG CALENDAR */

.rbc-timeslot-group {
  border-bottom: none !important;
  min-height: 100px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 0;
  word-wrap: break-word;
  line-height: 1;
  height: auto;
  min-height: auto;
}
.currDate {
  font-weight: lighter;
  color: white;
  text-align: center;
  background-color: var(--primary-green);
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 50px;
}
.allDate {
  color: var(--default-black);
  text-align: center;
  background-color: var(--default-white);
  padding: 8px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  width: 50px;
}

.rbc-event {
  background-color: #becacc;
  color: black;
  top: 16.6667%;
  height: 4.16667%;
  width: 100%;
  left: 0%;
  border-radius: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: none !important;
  border-left: 6px solid rgb(206, 108, 108) !important;
  padding-left: 5%;
  font-weight: bold;
}
.rbc-event:nth-child(1) {
  border-left: 6px solid #f75555 !important;
}
.rbc-event:nth-child(2) {
  border-left: 6px solid #246bfd !important;
}
.rbc-event:nth-child(3) {
  border-left: 6px solid #0abe75 !important;
}
.rbc-event:nth-child(4) {
  border-left: 6px solid #ffeb4f !important;
}
.rbc-event:nth-child(5) {
  border-left: 6px solid #673ab3 !important;
}
.rbc-event:nth-child(6) {
  border-left: 6px solid #ff981f !important;
}
.rbc-event:nth-child(7) {
  border-left: 6px solid #2f80ed !important;
}
.rbc-event:nth-child(8) {
  border-left: 6px solid #673ab3 !important;
}
.rbc-event:nth-child(9) {
  border-left: 6px solid #ff981f !important;
}
.rbc-event:nth-child(10) {
  border-left: 6px solid #000000 !important;
}
.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #becacc;
  color: black;
  top: 16.6667%;
  height: 4.16667%;
  width: 100%;
  left: 0%;
  border-radius: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: none !important;
  border-left: 6px solid rgb(206, 108, 108) !important;
  padding-left: 5%;
}
.rbc-event:nth-child(2) {
  border-left: 6px solid rgb(109, 109, 204) !important;
}

.rbc-time-content > * + * > * {
  border-left: none !important;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
  background-color: #ebebeb;
}
.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  border: none !important;
  min-height: 0;
}

.rbc-time-content {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: none !important;
  overflow-y: auto;
  position: relative;
}

.rbc-time-header {
  display: none !important;
}

.special-therapies{
  font-size: 25px;
  font-weight: 600;
}


.dynamic-therapies{
  font-size: 25px;
  font-weight: 600;
}
.css-zow5z4-MuiGrid-root .css-78trlr-MuiButtonBase-root-MuiIconButton-root{
  background-color:var(--primary-green);
  color: var(--default-white);
  margin: 10px;
  
}

.css-zow5z4-MuiGrid-root .css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover{
  background-color: var(--secondary-green);
  color: var(--default-white);
}

.special-therapies .css-i4bv87-MuiSvgIcon-root{
  font-size: 15px;
}

.dynamic-therapies .css-i4bv87-MuiSvgIcon-root{
  font-size: 15px;
}


.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  width: 80%;
}

.css-1p2jrvr-MuiFormControl-root{
  width: 80%;
}
.single-therapy-select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dynamic-employee-select .css-1nrlq1o-MuiFormControl-root{
  width: 90%;
}


/* ---------- BIG CALENDAR END ------------*/

@media screen and (max-width: 800px) {
  .daily1 .activity input {
    border: 1px solid #a7acb1;
    border-radius: 8px;
    outline: none;
    width: 55%;
    height: 5vw;
    padding-left: 3%;
    letter-spacing: 0.8px;
  }
  .activity .check {
    display: grid;
    grid-template-columns: auto auto;
    width: 60%;
  }
  .activity .button .button1 {
    width: 44%;
    height: 5vw;
  }
  .activity .button .button2 {
    width: 44%;
    height: 5vw;
  }

  .dynamic-employee-select .css-1nrlq1o-MuiFormControl-root{
    width: 80%;
  }
}
