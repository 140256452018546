.parent-box-dropdown-content::-webkit-scrollbar {
  display: scroll;
}
.parent-box-dropdown-content {
  -ms-overflow-style: scroll; /* IE and Edge */
  scrollbar-width: scroll; /* Firefox */
}
.parent-box-dropdown-content-big {
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 280px;
}
.parent-box-dropdown-content-small {
  height: fit-content;
}
/* .css-12l7263-MuiInputBase-input-MuiInput-input::placeholder{
  color: red !important;
} */

.css-12l7263-MuiInputBase-input-MuiInput-input::placeholder{
  color:rgb(0, 0, 0) !important;
  padding-top: 3% !important;
  
font-family: Urbanist !important;
font-size: 14px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: 150% !important;
  
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input{
  cursor: pointer;
}

.css-j6gj8r:hover{
  background-color: #b4ba3d !important;
}
