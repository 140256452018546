.serviceActivity_MainPae {
  width: 95%;
  height: 100%;
  text-align: center;
  justify-content: center;
  padding-top: 1%;
  z-index: 1;
  margin: 0 auto;
}

.export_service{
  display: flex;
}
.serviceActivity_sect-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.combo_CustomService{
  max-height: 60%;
}

.serviceActivity_add {
  display: flex;
  justify-content: flex-end;
  width: 17.4%;
}
.serviceActivity_manage {
  width: 45%;
}
/* .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: none;
} */
.css-wop1k0-MuiService_DataGrid-footerContainer {
  display: none !important;
}
.css-wjh20t-MuiPagination-ul {
  display: flex !important;
  /* justify-content: flex-end !important; */
  justify-content: right;
}
/* nav li {
  margin: 0px !important;
} */
.pagination-selected {
  color: var(--Secondary-500) !important;
  background-color: white !important;
}
.pagination-selected::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 28%;
  width: 45%;
  height: 2px;
  border-radius: 50px;
  background-color: var(--Secondary-500);
}


.Table {
  position: relative;
  padding: 0%;
  margin-bottom: 10%;
}

.serviceActivity_sect-2 {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: white;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;
  justify-content: space-between;
}

.serviceActivity_toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
}

.ToggleButtonGroups button.Mui-selected {
  background-color: var(--primary-green);
  color: white;
  font-weight: 600;
}

.ToggleButtonGroups button:hover {
  background-color: var(--primary-green);
  color: white;
}

.ToggleButtonGroups-root {
  width: 30px;
  height: 32px;
}

.ToggleButtonGroups {
  background-color: rgb(255, 255, 255);
  height: 40px !important;
}

.Service_DataGrid {
  padding-top: 3%;
}

.Service_DataGrid .serviceActivity-table {
  width: 100%;
  overflow-x: auto;
}

.Service_DataGrid .tableHeaderCell {
  color: var(--Greyscale-900);
  background: var(--Greyscale-50);
  font-family: Urbanist;
  font-size: var(--datagrid-header);
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
}
.Service_DataGrid .tableBodyCell {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--datagrid-body);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.css-1ex1afd-MuiTableCell-root {
  padding: 1rem 1rem !important;
}
.css-1sl31xx {
  display: flex;
  align-items: center;
}
.property-details-container {
  display: flex;
  height: fit-content;
  align-items: center;
  margin-top: 20px;
}
.property-details-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 10%;
}
.property-detail-item {
  flex: 0 0 28.33%;
  max-width: 33.33%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.property-detail-label {
  margin-bottom: 5px;
  font-weight: 600;
  font-style: normal;
}
.property-detail-input {
  width: 60%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.property-edit-button-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.property-details-button {
  background-color: var(--primary-green);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.serviceActivity_Togglebutton-1 {
  padding-right: 0;
  width: 100%;
  padding-left: 3%;
}
.serviceActivity_ToggleButtonGroups-1{
  height: 40px !important;
}
.serviceActivity_Togglebutton-1 .serviceActivity_ToggleButtonGroups-1 button {
  color: #000000;
  border: none;
  background: #f5f5f5;
  width: 40%;
}

.serviceActivity_Togglebutton-1
  .serviceActivity_ToggleButtonGroups-1
  .Mui-selected {
  background-color: var(--primary-green);
  color: white;
}
.serviceActivity_Togglebutton-1
  .serviceActivity_ToggleButtonGroups-1
  button:hover {
  background-color: var(--primary-green);
  color: white;
}
.serviceActivity_Togglebutton-1 .serviceActivity_ToggleButtonGroups-1-root {
  height: 32px;
}
.serviceActivity_Togglebutton-1 .serviceActivity_ToggleButtonGroups-1 {
  /* background-color: rgb(255, 255, 255); */
  color: #000000;
  height: 100%;
  width: 40%;
}
.serviceActivity_PopMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 40%;
  height: 100vh;
  background: var(--Greyscale-50);
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  scrollbar-width: thin; 
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;
}
.serviceActivity_PopMain::-webkit-scrollbar {
  width: 5px;
}
.serviceActivity_PopMain::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.Service_Form{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 5%;
  gap: 0.5rem;
}

.Toast {
  line-height: 1px;
}
.Toast h5 {
  color: rgb(255, 255, 255);
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.4px;
}
.error_Service {
  color: #f75555;
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 1px;
  padding-top: 2%;
}
.serviceActivity_First {
  display: flex;
  justify-content: space-around; 
  height: 8%;
  width: 100%;
  background: var(--default-white);
  cursor: pointer;
  align-items: center;
  font-style: normal;
  font-size: 18px;
}
.serviceActivity_First p {
  width: 80%;
  height: 100%;
  display: flex;
  font-family: "Urbanist ", Arial, sans-serif;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: var(--popupform-header);
  line-height: 150%;
  color: #424242;
}
.serviceActivity_PopMain .serviceActivity_Name {
  width: 100%;
  padding-left: 3%;
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  
}
.serviceActivity_PopMain .serviceActivity_Name input {
  width: 100%;
  align-items: center;
  background: var(--Greyscale-50);
  padding: 10px 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  outline: none;
}
.serviceActivity_Name label {
  color: var(--Greyscale-900);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.serviceActivity_Name input::placeholder{
color: var(--Greyscale-500);
font-family: Urbanist;
font-size: var(--popupform-placeholder);
font-style: normal;
font-weight: 500;
line-height: 150%;
}
.serviceActivity_PopMain .serviceActivity_Name label sup {
  color: #dc3545;
}
.serviceActivity_PopMain .Text {
  width: 100%;
  justify-content: center;
  padding-left: 5%;
  line-height: 34px;
}
.serviceActivity_PopMain .Text input {
  width: 95%;
  height: 100px;
  padding: 8px 16px;
  border: 1px solid #899197;
  border-radius: 8px;
  outline: none;
  font-family: "Urbanist ", Arial, sans-serif;
}
.serviceActivity_ProductDropdown {
  width: 100%;
  padding-left: 3%;
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.serviceActivity_ProductDropdown_Add {
  width: 10%;
  padding-left: 2%;
}
.serviceActivity_ProductDropdown label {
  color: var(--Greyscale-900);
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.icon {
  transition: transform 0.3s ease; 
}
.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1;
  width: 100%;
}
.dropdown-content a {
  display: block;
  padding: 10px 16px;
  color: #000000;
  text-decoration: none;
}
.dropdown-content a:hover {
  color: #ffffff;
}
.dropdown-content .clicked {
  display: none;
}

.show {
  display: block;
  cursor: pointer;
}
.data:hover {
  background-color: var(--primary-green);
  transition: 10ms;
  transform: scaleY(1);
  border-radius: 2px;
  margin-top: 0.5%;
}
.serviceActivity_Togglebutton-1 .serviceActivity_ToggleButtonGroups-1 button {
  color: #000000;
  border: none;
  background: #f5f5f5;
  font-size: 14px;
}
.serviceActivity_Togglebutton-1
  .serviceActivity_ToggleButtonGroups-1
  .Mui-selected {
  background-color: var(--primary-green);
  color: white;
}
.serviceActivity_Togglebutton-1
  .serviceActivity_ToggleButtonGroups-1
  button:hover {
  background-color: var(--primary-green);
  color: white;
}
.serviceActivity_Togglebutton-1 .serviceActivity_ToggleButtonGroups-1-root {
  width: 30px;
  height: 32px;
}
.serviceActivity_Togglebutton-1 .serviceActivity_ToggleButtonGroups-1 {
  /* background-color: rgb(255, 255, 255); */
  color: #000000;
}
.serviceActivity_End {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding-top: 20px;
  width: 100%;
  padding-left: 3%;
  padding-right: 2%;
}
.serviceActivity_End .serviceActivity_buttona,
.serviceActivity_End .serviceActivity_buttonb {
  width: 100%;
  height: 38px;
  color: #616161;
  font-style: normal;
  font-size: var(--popupform-submit-cancel-button);
  border: 1px solid #616161;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}
.serviceActivity_End .serviceActivity_buttonb {
  background: var(--primary-green);
  color: #fefefe;
  border: none;
}
.serviceActivity_End .serviceActivity_buttonb:hover {
  background: var(--primary-green);
}
.combo_CustomService .css-1dssmlf {
  height: 91% !important;
  border-radius: 8px !important;
  border: 1px solid #cccccc;
}
.combo_CustomService .css-1k9ek97 {
  width:95% !important
}
.combo_CustomService .css-1aa5qj0-MuiInputBase-root-MuiInput-root {
  padding-right: 50% !important;
}
.combo_CustomService .css-j6gj8r {
  border-bottom: 1px solid #cccccc;
}

.combo_CustomService .css-1irf9e3-MuiInputBase-root-MuiInput-root{
  padding-top: 1px !important;
}

@media (max-width: 1024px) {
  .serviceActivity_add {
    width: 23.4%;
  }
  .serviceActivity_export {
    width: 40%;
    margin-right: 1%;
  }
  .AddAmenity,
  .MainPae {
    position: relative;
  }

 

  .serviceActivity_toggle {
    width: 100%;
  }

  .Service_DataGrid {
    padding-top: 2%; /* Adjust padding */
  }

  .property-details-form {
    justify-content: space-around !important;
  }
  .property-detail-input {
    min-width: 95%;
  }
}
@media (max-width: 768px) {
  .serviceActivity_MainPae {
    margin: 0;
    width: 100%;
  }
  .serviceActivity_export {
    width: 30%;
    margin-right: 1%;
  }
  .serviceActivity_manage {
    width: 40%;
  }
  .serviceActivity_add {
    width: 29.4%;
  }

  .toggle {
    width: 16%;
  }
  .b {
    width: 64%;
  }
  .serviceActivity_manage {
    width: 60%;
  }
  .property-detail-item {
    flex: 0 0 39.33%;
    max-width: unset;
  }
}
@media (max-width: 600px) {
  .serviceActivity_End {
    flex-direction: column;
    align-items: center;
  }

  .serviceActivity_End .serviceActivity_buttona,
  .serviceActivity_End .serviceActivity_buttonb {
    width: 100%;
    margin-bottom: 10px;
  }
}
