.flexone {
  display: flex;
  justify-content: space-between;
  width: 90%;

  .icon {
    background: #ffc000;
    color: white;
    font-size: 2rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding-left: 4%;
  }
}

.title {
  font-family: "urbanistl";
  font-size: 24px;
  margin-top: 1vh;
}

.desc {
  color: #7c7979;
  font-family: "urbanistl";
  font-size: 1rem;
  width: 100%;
}

.flexform {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 3vh;
  label {
    font-size: 1rem;
    color: #212121;
    font-family: "urbanistl";
  }

  input {
    width: 100%;
    border: 1px solid #9e9e9e;
    height: 7vh;
    color: #9e9e9e;
    padding: 10px;
    border-radius: 6px;
    outline: none;
  }
}

.address {
  margin-top: 3vh;
  label {
    font-size: 1rem;
    color: #212121;
    font-family: "urbanistl";
  }

  input {
    width: 100%;
    border: 1px solid #9e9e9e;
    height: 7vh;
    color: #9e9e9e;
    padding: 10px;
    border-radius: 6px;
    outline: none;
  }
}

.gst {
  margin-top: 3vh;
  label {
    font-size: 1rem;
    color: #212121;
    font-family: "urbanistl";
  }

  input {
    width: 100%;
    border: 1px solid #9e9e9e;
    height: 7vh;
    color: #9e9e9e;
    padding: 10px;
    border-radius: 6px;
    outline: none;
  }
}
