.guestActivity_MainPae {
  width: 95%;
  height: 100%;
  text-align: center;
  justify-content: center;
  padding-top: 1%;
  z-index: 1;
  margin: 0 auto;
}

.guest_activity_export{
  display: flex;
}
.add-time-slot{
  display: flex;
  justify-content: flex-end;
  color: var(--primary-green);
  font-weight: 900;
}
.remove-time-slot{
  font: bold;
  padding: 20px;
  color: var(--primary-color);
  font-weight: 900;
}

.css-8klcfp {
  display: flex;
  align-items: center;
}

/* for overlay */
.css-14kxmxj-MuiPaper-root-MuiAppBar-root{
  z-index: 100 !important;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
  z-index: 100 !important;
}
.css-1n3knsv-MuiPaper-root-MuiAppBar-root {
  z-index: 100 !important;

}
.start-end-time{
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 8px;
  background-color: var(--Greyscale-50);
}

/* overlay end */
.guestActivity_sect-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}



.guestActivity_manage {
  width: 45%;
}

/* not known */
/* .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: none;
} */
.css-wjh20t-MuiPagination-ul{
  display: flex !important;
  /* justify-content: flex-end !important; */
}
/* nav li{
  margin: 0px !important;
} */

.pagination-selected {
  color: var(--Secondary-500) !important;
  background-color: white !important;
}

.pagination-selected::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 28%;
  width: 45%;
  height: 2px;
  border-radius: 50px;
  background-color: var(--Secondary-500);
}


.Table {
  position: relative;
  padding: 0%;
  margin-bottom: 10%;
}

.guestActivity_Search {
  position: relative;
  display: flex;
  font-weight: 600;
}

.guestActivity_sect-2 {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: white;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;
  justify-content: space-between;
}

.guestActivity_toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
}

.ToggleButtonGroups button.Mui-selected {
  background-color: var(--primary-green);
  color: white;
  font-weight: 600;
}

.ToggleButtonGroups button:hover {
  background-color: var(--primary-green);
  color: white;
}

.ToggleButtonGroups-root {
  width: 30px;
  height: 32px;
}

.ToggleButtonGroups {
  background-color: rgb(255, 255, 255);
}

.Guest_Activity_DataGrid {
  padding-top: 3%;
}


.Guest_Activity_DataGrid .guestActivity-table {
  width: 100%;
  overflow-x: auto;
}
.Guest_Activity_DataGrid .tableHeaderCell {
  color: var(--Greyscale-900);
  background: var(--Greyscale-50);
  font-family: Urbanist;
  font-size: var(--datagrid-header) ;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
}

.Guest_Activity_DataGrid .tableBodyCell {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--datagrid-body);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.css-1sl31xx{
  display: flex;
  align-items: center;
}

.option {
  flex-direction: row;
  padding-left: 3rem !important;
}
.checkbox {
  position: absolute;
  right: 42%;
}
/* dropdown label */
.multiSelectContainer input {
  margin-top: 4px !important;
  width: 100%;
}

.guestActivity_Name label, .guestActivity_ActivityDropdown label, .guestActivity_ProductDropdown label
{
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%; 
}
.guestActivity_ActivityDropdown .css-1jqq78o-placeholder {
  padding-left: 2% !important;
}
.guestActivity_Name input::placeholder, .searchBox::placeholder, textarea::placeholder, 
.propertyName_select{
  color: var(--Greyscale-500);
  font-size: var(--popupform-placeholder);
}
.guestactivity-detail-dropdown .css-1jqq78o-placeholder{
  color: var(--Greyscale-500) !important;
  font-size: var(--popupform-placeholder) !important;
}

.searchWrapper{
  min-height: 45px!important;
  padding: 8px 4%!important;
  border-radius: 8px !important;
  border: 1px solid #ccc!important;
  background-color: var(--Greyscale-50);
}
.guestActivity_PopMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  height: 100%;
  background-color: var(--Greyscale-50);
  z-index: 600;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  scrollbar-width: thin; 
  scrollbar-color: transparent transparent; 
  -ms-overflow-style: none;
  padding-top: unset;
}

.guestActivity_PopMain::-webkit-scrollbar {
  width: 5px;
}

.guestActivity_PopMain::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.Toast {
  line-height: 1px;
}

.Toast h5 {
  color: rgb(255, 255, 255);
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.4px;
}

.error_GuestActivity {
  color: #f75555;
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 1px;
  padding-top: 3%;
  padding-bottom: 2%;
}

.guestActivity_First {
  display: flex;
  justify-content: space-between; 
  height: 8%;
  width: 109%;
  cursor: pointer;
  align-items: center;
  font-style: normal;
  font-size: 18px;
  padding-inline: 6%;
  padding: 3%;
  background-color: var(--default-white);

}

.guestActivity_First p {
  height: 100%;
  padding-left: 6%;
  display: flex;
  font-family: "Urbanist ", Arial, sans-serif;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: var(--popupform-header);
  line-height: 150%;
  color: #424242;
}
.guestActivity_PopMain .guestActivity_Name {
  width: 50%;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem; 
}
.guestActivity_PopMain .guestActivity_Name input {
  width: 100%;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  background: var(--Greyscale-50);
}

.guestActivity_PopMain .guestActivity_Name label sup {
  color: #dc3545;
}

.guestActivity_PopMain .Text {
  width: 100%;
  justify-content: center;
  padding-left: 5%;
  line-height: 34px;
}

.guestActivity_PopMain .Text input {
  width: 95%;
  height: 100px;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  font-family: "Urbanist ", Arial, sans-serif;
}

.guestActivity_Togglebutton-1 {
  /* padding-top: 2.5%; */
  padding-left: 5%;
}
.combo_Custom{
  height: 3.15rem;
}
    .guestActivity_form{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      padding-right: 4%;
      align-items: center;
    }
     .guestActivity_ActivityDropdown {
      width: 50%;
      padding-left: 5%;
    
      display: flex;
      flex-direction: column;
      gap: 0.3rem; 
    }
.guestActivity_ProductDropdown {
  width: 50%;
  padding-left: 5%;
 
  display: flex;
    flex-direction: column;
    gap: 0.3rem; 
}

.dropdown {
  padding-left: 5%;
  position: relative;
  display: inline-block;
  width: 100%;
}
 .dropbtn {
  background-color: #ffffff;
  color: #000000;
  padding: 10px 16px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  transition: transform 0.3s ease; 
}
.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1;
  width: 100%;
}
.dropdown-content a {
  display: block;
  padding: 10px 16px;
  color: #000000;
  text-decoration: none;
}
.dropdown-content a:hover {
  color: #ffffff; 
}
 .dropdown-content .clicked {
  display: none;
}

.show {
  display: block;
  cursor: pointer;
}
.data:hover {
  background-color: var(--primary-green);
  transition: 10ms;
  transform: scaleY(1);
  border-radius: 2px;
  margin-top: 0.5%;
}

.guestActivity_Togglebutton-1 .guestActivity_ToggleButtonGroups-1 button {
  color: #000000;
  border: none;
  background: #F5F5F5;
}

.guestActivity_Togglebutton-1
  .guestActivity_ToggleButtonGroups-1
  .Mui-selected {
  background-color: var(--primary-green);
  color: white;
}

.guestActivity_Togglebutton-1 .guestActivity_ToggleButtonGroups-1 button:hover {
  background-color: var(--primary-green);
  color: white;
}

.guestActivity_Togglebutton-1 .guestActivity_ToggleButtonGroups-1-root {
  width: 30px;
  height: 32px;
}

.guestActivity_Togglebutton-1 .guestActivity_ToggleButtonGroups-1 {
  background-color: rgb(255, 255, 255);
  color: #000000; 
  height: 45%;
  margin-top: 34%;
  
}
.css-13cymwt-control{
  /* background-color: var(--Greyscale-50) !important; */
  border-radius: 8px !important;
}
.guestActivity_End {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-top: 20px;
  width: 100%;
  padding-left: 5%;
}

.guestActivity_End .guestActivity_buttona,
.guestActivity_End .guestActivity_buttonb {
  width: 49%;
  height: 38px;
  color: #616161;
  font-style: normal;
  font-size: var(--popupform-submit-cancel-button);
  border: 1px solid #616161;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}
/* Remove up and down arrows on hover */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]:hover::-webkit-inner-spin-button,
input[type=number]:hover::-webkit-outer-spin-button {
    display: none;
}

.guestActivity_End .guestActivity_buttonb {
  background: var(--primary-green);
  color: #fefefe;
  border: none;
}

.guestActivity_End .guestActivity_buttonb:hover {
  background: var(--primary-green);
}
.css-1k9ek97 {
  position: absolute;
  z-index: 1;
  width: 41%;
}
.css-9icqbm {
  border: 1px solid #ccc !important;
}

.combo_Custom .css-1dssmlf {
  height: 91% !important;
  border-radius: 8px !important;
  border: 1px solid #cccccc;
}
.combo_Custom .css-1k9ek97 {
  width:43% !important
}
.combo_Custom .css-1aa5qj0-MuiInputBase-root-MuiInput-root {
  padding-right: 50% !important;
}
.combo_Custom .css-j6gj8r {
  border-bottom: 1px solid #cccccc;
  width: 100% !important;
}
.combo_Custom .css-zly3fy {
  padding: 1% !important;
}
.combo_CustomGuest .css-1irf9e3-MuiInputBase-root-MuiInput-root{
  padding-top: 1px !important;
}
.combo_Custom .css-rjb0x-MuiFormControl-root-MuiTextField-root  {
  padding: 2.5% 1% 1% 1% !important;
}

.highlightOption {
  background-color: var(--primary-green) !important;
}
.option:hover {

  background-color: #b4ba3d !important;

}
.chip {
  background:var(--primary-green) !important ;
}
.toast_pop{
  width: 350px;
  background-color: #0ABE75 !important;
  color: #FFFFFF;
}

.popup_Close_icon{
  padding-right: 6%;
}
@media (max-width: 1024px) {
  
  .guestActivity_export {
    width: 40%;
    margin-right: 1%;
  }
  .AddAmenity,
  .MainPae {
    position: relative;
  }

  .guestActivity_Search {
    width: 50%;
  }

  .guestActivity_toggle {
    width: 100%;
  }

  .Guest_Activity_DataGrid {
    padding-top: 2%; 
  }

}
@media (max-width: 768px) {

  .guestActivity_MainPae {
    margin: 0;
    width: 100%;
  }

  .guestActivity_export{
    width: 30%;
    margin-right: 1%;
  }
  .guestActivity_manage{
    width: 40%;
  }
 
  .guestActivity_PopMain{
    width: 90%;
  }

}
.guestactivity-detail-dropdown .css-1gytqch-ValueContainer{
  padding: 6px !important;
}
@media (max-width: 600px) {
  .guestActivity_End {
    flex-direction: column;
    align-items: center;
  }

  .guestActivity_End .guestActivity_buttona,
  .guestActivity_End .guestActivity_buttonb {
    width: 100%;
    margin-bottom: 10px;
  }
  .toggle {
    width: 16%;
  }
  .b {
    width: 64%;
  }
  .guestActivity_manage {
    width: 60%;
  }
}
