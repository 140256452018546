.demo_container {
    display: flex;
    width: 100%;
    background-image: radial-gradient(#eef38f 2px, transparent 2px);
    background-size: 30px 30px;
    padding-bottom: 50px;
  }
  
  @media screen and (max-width: 500px) {
    .demo_container {
      height: auto;
      padding-bottom: 50px;
    }
  }
  
  .demo_secleft {
    width: 50%;
    padding: 30px;
  }
  
  @media screen and (max-width: 800px) {
    .demo_secleft {
      width: 100%;
    }
  }
  
  .demo_fleximagediv {
    width: 65%;
    margin-left: 15%;
    display: flex;
    margin-top: 5vh;
  }
  
  .demo_flexSelectWellnessdiv {
    width: 65%;
    margin-left: 15%;
    display: flex;
    margin-top: 5vh;
    flex-direction: column;
  }
  
  .demo_maincontent {
    width: 80%;
    margin-left: 15%;
    margin-top: 10%;
  }
  
  @media screen and (max-width: 500px) {
    .demo_maincontent {
      margin-top: 2%;
    }
  }
  
  .demo_heading {
    font-size: 2.5rem;
    font-family: "Urbanist", sans-serif;
    line-height: 62.4px;
    font-weight: bold;
  }
  
  @media screen and (max-width: 500px) {
    .demo_heading {
      font-size: 1.5rem;
      line-height: 30px;
    }
  }
  
  .demo_text {
    font-size: 1.5rem;
    font-family: "Urbanist", sans-serif;
    font-weight: 100;
  }
  
  .demo_secright {
    width: 50%;
    position: relative;
  }
  
  @media screen and (max-width: 800px) {
    .demo_secright {
      width: 0%;
    }
  }
  
  .demo_lower {
    float: right;
    width: 75%;
    height: 100%;
  }
  
  .demo_upper {
    width: 70%;
    position: absolute;
    right: 0;
  }
  
 
  
  .demo_button {
    background-color: #6c7d02;
    color: white;
    width: 150px;
    height: 48px;
    border-radius: 8px;
    margin-top: 12px;
    transition: background-color 0.3s ease;
  }
  
  .demo_button:hover {
    background-color: #9cad2c;
  }
  