.flexone {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .right {
    // width: 7vw;
    display: flex;
    justify-content: space-between;
    .icon {
      background: #ffc000;
      color: white;
      font-size: 2rem;
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }
}

.title {
  font-family: "urbanistl";
  font-size: 24px;
  margin-top: 1vh;
}

.desc {
  color: #7c7979;
  font-family: "urbanistl";
  font-size: 1rem;
  width: 100%;
}

.slider {
  margin-top: 3vh;

  width: 100%;

  label {
    font-size: 1rem;
    color: #212121;
    font-family: "urbanistl";
  }

  .sliderbar {
    width: 100%;
    height: 4px;
    background-color: #ccc;
    position: relative;

    &::-moz-range-track::before,
    &::-webkit-slidebar-runnable-track::before,
    &::-ms-track::before {
      background: #ffc000;
    }

    input[type="range"] {
      width: 100%;
      background: transparent;
      outline: none;
      height: 4px;
      position: relative;

      &::-webkit-sliderbar-thumb {
        -webkit-appearance: none;
        width: 14px;
        height: 14px;
        background-color: #ffc000;
        border: 2px solid #ffc000;
        border-radius: 50%;
        position: relative;
        z-index: 2;
        margin-top: -6px;
      }

      &::-moz-range-thumb {
        -moz-appearance: none;
        width: 14px;
        height: 14px;
        background-color: #ffc000;
        border: 2px solid #ffc000;
        border-radius: 50%;
        position: relative;
        z-index: 2;
        margin-top: -6px;
      }

      &::-ms-thumb {
        -ms-appearance: none;
        width: 14px;
        height: 14px;
        background-color: #ffc000;
        border: 2px solid #ffc000;
        border-radius: 50%;
        position: relative;
        z-index: 2;
        margin-top: -6px;
      }
    }
  }

  .rooms {
    float: right;
    box-shadow: 0px 0px 1px gray;
  }
}

.rt {
  margin-top: 3vh;
  label {
    font-size: 1rem;
    color: #212121;
    font-family: "urbanistl";
  }

  input {
    width: 100%;
    border: 1px solid #9e9e9e;
    height: 7vh;
    color: #9e9e9e;
    padding: 10px;
    border-radius: 6px;
    outline: none;
  }
}

.flexform2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 3vh;
  label {
    font-size: 1rem;
    color: #212121;
    font-family: "urbanistl";
  }

  input {
    width: 100%;
    border: 1px solid #9e9e9e;
    height: 7vh;
    color: #9e9e9e;
    padding: 10px;
    border-radius: 6px;
    outline: none;
  }
}

.flexform3 {
  margin-top: 3vh;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .left {
    width: 48%;

    font-size: 1.25rem;
    color: #212121;
    font-family: "urbanistl";
    font-weight: 1000;
  }

  .right {
    width: 48%;

    button {
      min-width: 100%;
      background: #5d7202;
      color: white;
      padding: 12px;
      border-radius: 8px;
      font-family: "urbanistl";

      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
        0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
        0 16px 16px rgba(0, 0, 0, 0.12);
    }
  }
}

.element {
  border: 1px solid black;
  display: flex;
  width: fit-content;
  padding: 10px;
  align-items: center;
  border-radius: 6px;
  margin: 5px;
}

.editDiv {
  margin-top: 35px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  width: 70%;
}

.icondiv {
  margin-left: 5px;
}
