.contact-form {
  max-width: 550px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  padding-top: 8vh;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-group label {
  position: relative;
}

.form-group-double {
  display: flex;
  flex-wrap: wrap;
}

.form-group-double .form-group {
  flex: 1;
  margin-right: 10px;
}

.form-input {
  width: 100%;
  padding: 10px 40px; /* Adjust to accommodate icon width */
  border: 1px solid #000;
  border-radius: 4px;
  resize: vertical; /* Allow vertical resizing of the textarea */
}

.form-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #b3b3b3;
  font-size: small;
}
.form-icon1{
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #b3b3b3;
  font-size: small;
  margin-left: 10px;
}
.form-input1{
  width: 100%;
  padding: 10px 40px;
  border: 1px solid #000;
  border-radius: 4px;
  resize: vertical; 
  margin-left: 10px;
}

@media screen and (max-width:767px) {
  .form-icon1{
    margin-left: 0;
  }
  .form-input1{
    margin-left: 0;
  }
}
.input-container {
  position: relative;
}
.form-icon2{
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #b3b3b3;
  font-size: small;
}
.form-input2{
  width: 100%;
  height: 100px;
  padding: 10px 40px;
  border: 1px solid #000;
  border-radius: 4px;
  resize: vertical; 
}
.submit-button {
  padding: 10px 20px;
  background-color: var(--primary-green);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #4caf50;
}

.error {
  color: red;
  font-family: 'Quicksand', sans-serif;
  font-weight: 100;
}

@media screen and (max-width: 767px) {
  .form-group-double {
    display: block;
  }
  .form-group-double .form-group {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
