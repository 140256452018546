.input {
  height: 7vh;
  /* width: 48%; */
  border: 1px lightgrey solid;
  border-radius: 5px;
  outline: none;
  padding: 20px;
}

.input:focus {
  outline: none;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  background-color: var(--primary-green);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid var(--secondary-green);}

.inputn {
  height: 7vh;
  /* width: 36vw; */
  border: 1px lightgrey solid;
  border-radius: 5px;
  outline: none;
  padding: 20px;
}

label {
  font-size: 0.8rem;
}

.inputl {
  /* height: 6vh; */
  width: 20vw;
}

#typo1 {
  font-weight: bolder;
  font-family: urbanist;
  font-size: 2.5rem;
}

#typo2 {
  font-weight: bolder;
  font-family: urbanist;
  color: #f9c70c;
  font-size: 1.5rem;
  margin-top: -20px;
}

#typo3 {
  font-family: "urbanistl";
  color: gray;
  margin-top: -10px;
  font-size: 1rem;
  max-width: 36vw;
  padding-top: 2%;
}

#i2 {
  margin-left: 2vw;
}

#number {
  margin-top: 1vh;
  
}

#password {
  margin-top: 1vh;
}

.css-zlh8is-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #5d7202 !important;
}

.error {
  color: red;
  /* width: 17vw; */
  font-family: "urbanistl";
  font-size: 0.75rem;
}

.errorl {
  color: red;
  font-family: "urbanistl";
  font-size: 0.75rem;
}

.foram-text {
  padding-top: 2vh;
  font-family: "urbanist";
  font-weight: lighter;
  color: #000;
  /* font-size: 1.2rem; */
}

#ca-button {
  /* width: 80%; */
  padding: 10px 50px;
  background: #5d7202;
  color: white;
  border-radius: 6px;
  /* height: 6vh; */
  margin-top: 2vh;
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12); */
}

#ca-button:hover {
  /* min-width: 36vw; */
  padding: 10px 50px;
  background: #778b1e;
  color: white;
  border-radius: 6px;
  transition: all ease 500ms;
  margin-top: 2vh;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
}

#b-para {
  margin-top: 1vh;
  cursor: pointer;
  display: flex;
  /* justify-content: center; */
  text-align: center;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
  padding: 0 !important;
}

.input2 {
  height: 7vh;
  width: 17vw;
  border: 1px lightgrey solid;
  border-radius: 5px;
  outline: none;
  padding-left: 20px;
  /* padding: 20px; */
}

.input2:focus {
  outline: none;
}

@media only screen and (max-width: 800px) {
  .input {
    height: 7vh;
    width: 100%;
    border: 1px lightgrey solid;
    border-radius: 5px;
  }

  .input2 {
    height: 7vh;
    width: 44vw;
    border: 1px lightgrey solid;
    border-radius: 5px;
  }

  .inputn {
    height: 7vh;
    width: 100%;
    border: 1px lightgrey solid;
    border-radius: 5px;
  }

  .css-zlh8is-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #5d7202 !important;
  }

  #ca-button {
    width: 100%;
    padding: 10px 50px;
    background: #5d7202;
    color: white;
    border-radius: 6px;
    /* height: 6vh; */
    margin-top: 3vh;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
      0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
      0 16px 16px rgba(0, 0, 0, 0.12);
  }

  #typo3 {
    max-width: 90vw;
  }

  #logo {
    margin-left: 20px;
  }

  #form {
    margin-left: 20px;
  }

  #b-para {
    text-align: center;
  }

  .error {
    width: 40vw;
  }
}

@media (max-width: 768px) {
  .usernew{
    display: flex;
    justify-content: center;
    margin-top: 2%;
  }
}

@media (max-width: 383px) {
  .signup_gender{
    display: flex;
    flex-direction: column;
  }
}