*{
    font-family: 'Urbanist';
}


.header{
    background-color: white;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.05);
   
   }
   
   .menu-bars {
       margin-right: 20px;
       margin-left: 2rem;
       font-size: 2rem;
       background: none;
       color: var(--primary-green);
   }
   
   .menu-bars :hover{
       color: var(--primary-green);
   }
   
   .nav-menu {
       background-color: white;
       width: 250px;
       height: 180vh;
       display: flex;;
       justify-content: center;
       position: fixed;
       top: 0;
       left: -100%;
       transition: 850ms;
   }
   
   
   .header h3 {
       margin: 0;
     }
   
     .RightIcons {
       display: flex;
       align-items: center;
     }
   .nav-menu.active {
       left: 0;
       transition: 350ms;
       z-index: 2;
   }
   
   .nav-text{
       display: flex;
       justify-content: start;
       align-items: center;
       /* padding: 8px 0px 8px 16px; */
       list-style: none;
       height: 60px;
   }
   
   .nav-text a{
       text-decoration: none;
       color: #000000;
       font-size: 18px;
       width: 95%;
       height: 100%;
       display: flex;
       align-items: center;
       padding: 0 16px;
       border-radius: 4px;
   }
   
   .nav-text a:hover {
       background-color: aliceblue;
   }
   
   .nav-menu-items {
       width: 100%;
       box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.05);
       padding-left: 0px;
   
   }
   
   .navbar-toggle {
       background-color: white;
       width: 100%;
       height: 80px;
       display: flex;
       justify-content: end;
       align-items: center;
       gap:60px;
   }
   
   #arrow {
       margin-left: 16px;
   }
   
   #drawer-title{
    font-size: 24px;
    color: var(--primary-green);
    font-weight: 900;
   
   }

   .redirect  {
    text-decoration: none;
   }
   .redirect:hover, .redirect:focus   {
    text-decoration: none;
   }
  
   .logo4s {
       width: 45px;
   }
   
   .logo5s {
       width: 10px;
   }
   .logo6{
       width: 20px;
   }
   .drop{
       display: flex;
       flex-direction: row;
       align-items: center;
       padding-left: 14%;
       color: #212121;
       cursor: pointer;
   }
   
   .css-j4w5wr {
       right: 0;
       left: 91%;
   }

   
   @media (min-width: 300px) and (max-width: 500px) {
       .Search {
           display: none;
       }
   }