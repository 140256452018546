.AddProduct {
  position: fixed;
  top: 0%;
  right: 25%;
  z-index: 9999;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.9);
}
.css-pftjpk {
  padding: 0;
}

.MainPae {
  width: 95%;
  height: 100%;
  text-align: center;
  justify-content: center;
  padding-top: 1%;
  z-index: 1;
  margin: 0 auto;
  /* add this line */
}
#demo-simple-select-autowidth-label , #outlined-basic-label{
  top:-10px;
}

.sect-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.product_manage {
  width: 45%;
}

.product_add {
  display: flex;
  justify-content: flex-end;
  width: 17.4%;
}

.product_export {
  display: flex;
  justify-content: flex-end;
  width: 66%;
}
/* .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: none;
} */

.sect-1 .button_export {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: var(--primary-green);
  width: 176px;
  color: var(--primary-green);
  font-style: normal;
  font-size: 16px;
  padding: 10px 16px;
  font-weight: 600;
  cursor: pointer;
}

.sect-1 .button_add {
  background-color: var(--primary-green);
  border: none;
  border-radius: 8px;
  width: 176px;
  /* height: 35px; */
  color: rgb(255, 255, 255);
  font-style: normal;
  font-size: 16px;
  padding: 10px 16px;
  font-weight: 600;
  cursor: pointer;
}
.sect-1 .button_add:hover {
  background: #b4ba3d;
}

/* .ProductTable {
    padding-top: 3%;
  } */

.Table {
  position: relative;
  padding: 0%;
  margin-bottom: 10%;
}

.product_Search {
  position: relative;
  display: flex;
  font-weight: 600;
}

.sect-2 {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: white;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;
  justify-content: space-between;
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
}

.ToggleButtonGroups button.Mui-selected {
  background-color: var(--primary-green);
  color: white;
  font-weight: 600;
}

.ToggleButtonGroups button:hover {
  background-color: var(--primary-green);
  color: white;
}

.ToggleButtonGroups-root {
  width: 30px;
  height: 32px;
}

.ToggleButtonGroups {
  background-color: rgb(255, 255, 255);
}

.DataGrid {
  padding-top: 3%;
}

.DataGrid .product-table {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 1024px) {
  .AddAmenity,
  .MainPae {
    position: relative;
  }
  .product_Search {
    width: 50%;
  }

  .toggle {
    width: 100%; /* Full width for toggle buttons */
  }

  .DataGrid {
    padding-top: 2%; /* Adjust padding */
  }
}

@media (max-width: 768px) {
  .toggle {
    width: 16%;
  }
  .b {
    width: 64%;
  }
  .manage {
    width: 40%;
  }
}

.PopMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 400px;
  height: 100vh;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  background: rgb(255, 255, 255);
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  padding: 20px;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
  -ms-overflow-style: none;
}

.PopMain::-webkit-scrollbar {
  width: 5px;
}

.PopMain::-webkit-scrollbar-thumb {
  background-color: transparent;
}


.Toast {
  line-height: 1px;
}

.Toast h5 {
  color: rgb(255, 255, 255);
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.4px;
}

.error {
  color: #f75555;
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 1px;
}

.First {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Align X button to the right */
  height: 8%;
  width: 100%;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.02);
  cursor: pointer;
  align-items: center;
  font-style: normal;
  font-size: 18px;
}

.First p {
  width: 70%; /* Adjust as needed */
  padding-top: 5%;
  display: flex;
  font-family: "Urbanist ", Arial, sans-serif;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #424242;
}

.PopMain .Name {
  width: 100%;
  justify-content: center;
  padding-left: 5%;
  line-height: 34px;
  font-family: "Urbanist ", Arial, sans-serif;
}

.PopMain .Name input {
  width: 95%;
  align-items: center;
  padding: 13px 16px;
  border: 1px solid #899197;
  border-radius: 8px;
  outline: none;
}
.PopMain .Pcode {
  width: 100%;
  justify-content: center;
  padding-left: 5%;
  line-height: 34px;
  font-family: "Urbanist ", Arial, sans-serif;
}

.PopMain .Pcode input {
  width: 95%;
  align-items: center;
  padding: 13px 16px;
  border: 1px solid #899197;
  border-radius: 8px;
  outline: none;
}
.PopMain .Pcode label sup {
  color: #dc3545;
}

.PopMain .Name label sup {
  color: #dc3545;
}

.PopMain .Text {
  width: 100%;
  justify-content: center;
  padding-left: 5%;
  line-height: 34px;
}

.PopMain .Text input {
  width: 95%;
  height: 100px;
  padding: 8px 16px;
  border: 1px solid #899197;
  border-radius: 8px;
  outline: none;
  font-family: "Urbanist ", Arial, sans-serif;
}

.Togglebutton-1 {
  padding-right: 80%;
}

.Togglebutton-1 .ToggleButtonGroups-1 button {
  color: #000000;
  border: none;
  background: #f5f5f5;
}

.Togglebutton-1 .ToggleButtonGroups-1 .Mui-selected {
  background-color: var(--primary-green);
  color: white;
}

.Togglebutton-1 .ToggleButtonGroups-1 button:hover {
  background-color: var(--primary-green);
  color: white;
}

.Togglebutton-1 .ToggleButtonGroups-1-root {
  width: 30px;
  height: 32px;
}

.Togglebutton-1 .ToggleButtonGroups-1 {
  background-color: rgb(255, 255, 255);
  color: #000000;
}

.End {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* Ensure buttons are spaced evenly */
  padding-top: 20px;
  /* Adjust as needed */
  width: 100%;
}

.End .buttona,
.End .buttonb {
  width: 48%;
  /* Adjust button width */
  height: 38px;
  color: #616161;
  font-style: normal;
  font-size: 16px;
  border: 1px solid #616161;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}

.End .buttonb {
  background: var(--primary-green);
  color: #fefefe;
  border: none;
}

.End .buttonb:hover {
  background: var(--primary-green);
}

@media (max-width: 600px) {
  .End {
    flex-direction: column;
    align-items: center;
  }

  .End .buttona,
  .End .buttonb {
    width: 100%;
    margin-bottom: 10px;
  }
}

.drop-down {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-block: 15px;
}
