.employeemaster_MainPae {
  width: 95%;
  height: 100%;
  text-align: center;
  justify-content: center;
  padding-top: 1%;
  z-index: 1;
  margin: 0 auto;
}

.export_employee{
  display: flex;
}

.rmdp-container{
  width: 100%;
}
.employeemaster_sect-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between
}
sup {
  color: #dc3545;
}
/* single select placeholder */
.css-1jqq78o-placeholder{
  color: var(--Greyscale-500) !important;
font-family: Urbanist;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 150%; 
}
.employee-detail-item .css-13cymwt-control{
  background: var(--default-white) !important;
}

.employeemaster_add {
  display: flex;
  justify-content: flex-end;
  width: 17.4%;
}

.employeemaster_manage {
  width: 45%;
}

.employeemaster_export {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}

/* .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: none;
} */
 
.MuiPopover-paper .MuiMenu-paper{
 box-shadow: none;
}
.css-wop1k0-Muiempdatgrid-footerContainer {
  display: none !important;
}
.css-wjh20t-MuiPagination-ul {
  display: flex !important;
  
  /* justify-content: flex-end !important; */
}
/* nav li {
  margin: 0px !important;
} */
.pagination-selected {
  color: var(--Secondary-500) !important;
  background-color: white !important;
}

.pagination-selected::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 28%;
  width: 45%;
  height: 2px;
  border-radius: 50px;
  background-color: var(--Secondary-500);
}
/* .employeemaster_sect-1 .button_export {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: var(--primary-green);
  width: 176px;
  color: var(--primary-green);
  font-style: normal;
  font-size: 16px;
  padding: 10px 16px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 2%;
} */

.employeemaster_sect-1 .button_add {
  background-color: var(--primary-green);
  border: none;
  border-radius: 8px;
  width: 176px;
  color: rgb(255, 255, 255);
  font-style: normal;
  font-size: 16px;
  padding: 10px 16px;
  font-weight: 600;
  cursor: pointer;
}
.employeemaster_sect-1 .button_add:hover {
  background: #b4ba3d;
}

.Table {
  position: relative;
  padding: 0%;
  margin-bottom: 10%;
}

.employee-property-detail {
  width: 100%;
}
.employeeActivity_ActivityDropdown {
  width: 34%;
  margin-bottom: 20px;
}

.employeemaster_sect-2 {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: white;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;
  justify-content: space-between;
}

.employeemaster_toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* z-index: 2; */
}

.ToggleButtonGroups button.Mui-selected {
  background-color: var(--primary-green);
  color: white;
  font-weight: 600;
}

.ToggleButtonGroups button:hover {
  background-color: var(--primary-green);
  color: white;
}

.ToggleButtonGroups-root {
  width: 30px;
  height: 32px;
}

.ToggleButtonGroups {
  background-color: rgb(255, 255, 255);
}

.empdatgrid {
  padding-top: 3%;
}

.empdatgrid .employeemaster-table {
  width: 100%;
  overflow-x: auto;
}

.empdatgrid .tableHeaderCell {
  color: var(--Greyscale-900);
  background: var(--Greyscale-50);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
}

.empdatgrid .tableBodyCell {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
}
.css-1ex1afd-MuiTableCell-root {
  padding: 1rem 1rem !important;
}
.css-1sl31xx {
  display: flex;
  align-items: center;
}

/*  */
.css-1kuq5xv-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
}
/* single employee */

.employee-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.employee-details-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.employee-detail-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 33%;
}
.hidden {
  display: none;
}
.employee-detail-Address {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 65%;
}
.employee-detail-preaddress {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 35%;
  padding-left: 1.5%;
}
.fieldset{
  border-top: 2px solid #f2eded;
  width: 100%;

}
.employee-detail-label {
  margin-bottom: 5px;
  color: var(--Greyscale-900, #212121);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.employee-detail-input {
  width: 60%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: 0;
 
}

.employee-detail-input-1 {
  width: 60%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: 0;
 
}

.employee-detail-input option:hover {
  background-color: var(--primary-green);
  color: white;
}

.employee-detail-input::placeholder{
  color: var(--Greyscale-500);
font-family: Urbanist;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 150%; 
}
.employee-detail-dropdown{
 width: 60% !important;
}
.employee-detail-dropdown .css-1gytqch-ValueContainer{
  padding-left: 2% !important;
}
.placeholder{
  color: var(--Greyscale-500);
  font-size: 14px;
}
.employee-detail-textarea {
  width: 82%;
  padding: 5px;
  padding-bottom: 8%;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: 0;
  resize: none;
}
.employee-detail-textarea::placeholder {
  padding: 5px;
  color: var(--Greyscale-500);
font-family: Urbanist;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 150%; 
}

.employee-detail-drop {
  width: 60%;
  padding: 6.5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.employee-details-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.employee-edit-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 20%;
}

.error-message {
  color: red;
  font-size: 0.75rem;
  margin-top: 4px;
}
legend {
  padding-left: 5%;
}

.employee-details-cancelbutton {
  color: var(--Greyscale-700, #616161);
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
border: 1px solid var(--Greyscale-700, #616161);
font-weight: 550;
}
.employee-details-addbutton {
  background-color: var(--primary-green);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}

.rmdp-arrow {
  border: solid var(--primary-green);
  border-width: 0 2px 2px 0;
}
.rmdp-header-values {
  color: var(--Greyscale-800);
  margin: auto;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
}
.rmdp-week-day:nth-child(1) {
  color: var(--alert-status-error);
}
.rmdp-week-day:nth-child(7) {
  color: var(--alert-status-error);
}
.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid var(--default-white);
  border-width: 0 2px 2px 0;
}
.rmdp-arrow-container {
  padding-top: 1%;
}
.rmdp-left {
  padding-right: 0.5%;
}
.rmdp-arrow-container:hover {
  background-color: var(--Secondary-500);
}
.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--Secondary-500);
}
.rmdp-day span {
  border-radius: 8px;
}
.rmdp-week-day {
  color: var(--default-black);
  font-weight: bold;
}

.employee-detail-item .rmdp-input {
  width: 60%;
  height: 35px;
}
.employee-detail-item .rmdp-input::placeholder{
  color: var(--Greyscale-500);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; 
}


.multiSelectContainer input {
  margin-top: 0px !important;
}
.multiSelectContainer input::placeholder{
  color: var(--Greyscale-500);
font-family: Urbanist;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 150%; 
}

/* employeemaster Pop Up */


.employeemaster_PopMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 400px;
  height: 100vh;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  background: rgb(255, 255, 255);
  z-index: 10000;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  padding: 20px;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
  -ms-overflow-style: none;
}

.employeemaster_PopMain::-webkit-scrollbar {
  width: 5px;
}

.employeemaster_PopMain::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.mainBorder{
  display: flex;
  flex-wrap: wrap;
  margin-top: 1%;
  padding-left: 10%;
}
.formfielddata{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.form_field_container{
  display: flex;
  gap: 0.5rem;
}
.DetailsField{
  width: 50%;
}
.mainBorder2{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1%;
  padding-left: 10%;
}
.present_div{
  display: flex;
  width: 100%;
}


.Toast {
  line-height: 1px;
}

.Toast h5 {
  color: rgb(255, 255, 255);
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.4px;
}

.error {
  color: #f75555;
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 1px;
  margin-top: 2%;
}

.employeemaster_First {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-around; /* Align X button to the right */
  height: 8%;
  width: 100%;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.03);
  cursor: pointer;
  align-items: center;
  font-style: normal;
  font-size: 18px;
}

.employeemaster_First p {
  width: 80%;
  height: 100%;
  /* padding-top: 5%; */
  display: flex;
  font-family: "Urbanist ", Arial, sans-serif;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #424242;
}

.employeemaster_PopMain .employeemaster_Name {
  width: 100%;
  justify-content: center;
  padding-left: 5%;
  /* line-height: 34px; */
  font-family: "Urbanist ", Arial, sans-serif;
}

.employeemaster_PopMain .employeemaster_Name input {
  width: 100%;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  outline: none;
  /* height: 75%; */
}
.rmsc .dropdown-heading {
  height: 45px !important;
}

.employeemaster_PopMain .employeemaster_Name label sup {
  color: #dc3545;
}

.employeemaster_PopMain .Text {
  width: 100%;
  justify-content: center;
  padding-left: 5%;
  line-height: 34px;
}

.employeemaster_PopMain .Text input {
  width: 95%;
  height: 100px;
  padding: 8px 16px;
  border: 1px solid #899197;
  border-radius: 8px;
  outline: none;
  font-family: "Urbanist ", Arial, sans-serif;
}

.employeemaster_Togglebutton-1 {
  padding-right: 80%;
}

/* Styles for the dropdown container */
.employeemaster_ProductDropdown {
  width: 100%;
  padding-left: 5%;
}

.dropdown {
  padding-left: 5%;
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Styles for the dropdown button */
.dropbtn {
  background-color: #ffffff;
  color: #000000;
  padding: 10px 16px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  transition: transform 0.3s ease; /* Add transition for transform property */
}

/* Styles for the dropdown content */
.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1;
  width: 100%;
}

/* Styles for the dropdown content links */
.dropdown-content a {
  display: block;
  padding: 10px 16px;
  color: #000000;
  text-decoration: none;
}
.dropdown-content a:hover {
  color: #ffffff;
}

/* Show dropdown content when dropdown button is clicked */
.dropdown-content .clicked {
  display: none;
}

.show {
  display: block;
  cursor: pointer;
}
.data:hover {
  background-color: var(--primary-green);
  transition: 10ms;

  /* border-bottom: 1px solid grey; */
  transform: scaleY(1);
  border-radius: 2px;
  margin-top: 0.5%;
}

.employeemaster_Togglebutton-1 .employeemaster_ToggleButtonGroups-1 button {
  color: #000000;
  border: none;
  background: #f5f5f5;
}

.employeemaster_Togglebutton-1
  .employeemaster_ToggleButtonGroups-1
  .Mui-selected {
  background-color: var(--primary-green);
  color: white;
}

.employeemaster_Togglebutton-1
  .employeemaster_ToggleButtonGroups-1
  button:hover {
  background-color: var(--primary-green);
  color: white;
}

.employeemaster_Togglebutton-1 .employeemaster_ToggleButtonGroups-1-root {
  width: 30px;
  height: 32px;
}

.employeemaster_Togglebutton-1 .employeemaster_ToggleButtonGroups-1 {
  background-color: rgb(255, 255, 255);
  color: #000000;
}

.employeemaster_End {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  width: 100%;
}

.employeemaster_End .employeemaster_buttona,
.employeemaster_End .employeemaster_buttonb {
  width: 48%;
  height: 38px;
  color: #616161;
  font-style: normal;
  font-size: 16px;
  border: 1px solid #616161;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}

.employeemaster_End .employeemaster_buttonb {
  background: var(--primary-green);
  color: #fefefe;
  border: none;
}

.employeemaster_End .employeemaster_buttonb:hover {
  background: var(--primary-green);
}

@media (max-width: 1024px) {
  .employeemaster_add {
    width: 23.4%;
  }
  .employeemaster_export {
    width: 40%;
    margin-right: 1%;
  }
  .AddAmenity,
  .MainPae {
    position: relative;
  }



  .employeemaster_toggle {
    width: 100%;
  }

  .empdatgrid {
    padding-top: 2%; /* Adjust padding */
  }

  .employee-details-form {
    justify-content: space-around !important;
    padding: 0%;
  }
  .employee-edit-button-container {
    width: 100%;
    justify-content: space-around;
    padding-inline: 35%;
  }
  .employee-detail-input {
    min-width: 95%;
  }
  .employee-detail-dropdown {
    width: 95% !important;
  }
  .employee-detail-item .rmdp-input  {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .employeemaster_MainPae {
    margin: 0;
    width: 100%;
  }

  .employeemaster_export {
    width: 30%;
    margin-right: 1%;
  }
  .employeemaster_manage {
    width: 40%;
  }
  .employeemaster_add {
    width: 29.4%;
  }
  /* .employeemaster_sect-1 .button_export,
  .employeemaster_sect-1 .button_add {
    padding: 10px 12px; 
    font-size: 14px; 
  } */
  .toggle {
    width: 16%;
  }
  .b {
    width: 64%;
  }
  .employeemaster_manage {
    width: 60%;
  }
  .employee-detail-item {
    flex: 0 0 33.33%;
    max-width: unset;
  }
  legend {
    padding: unset;
  }
  .employee-edit-button-container {
    padding-inline: 30%;
  }
  
}
@media (max-width: 600px) {
  .employeemaster_End {
    flex-direction: column;
    align-items: center;
  }

  .employeemaster_End .employeemaster_buttona,
  .employeemaster_End .employeemaster_buttonb {
    width: 100%;
    margin-bottom: 10px;
  }
}
