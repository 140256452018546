.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
  font-size: 14px;
}
.MuiTypography-root {
  border: none !important;
}
.header {
  font-weight: 700 !important;
  font-size: larger;
}
.cell {
  border: none !important;
}

.header-cell {
  border: none !important;
  font-weight: bold !important;
}
.Grid {
  padding: unset;
}
.Grid .tableHeaderCell {
  color: var(--Greyscale-900);
  background: var(--Greyscale-50);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.Grid .tableBodyCell {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
nav ul {
  padding-right: 5%;
}
.pagination-selected {
  color: var(--Secondary-500) !important;
  background-color: white !important;
}

.pagination-selected::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 28%;
  width: 45%;
  height: 2px;
  border-radius: 50px;
  background-color: var(--Secondary-500);
}
