.container {
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: radial-gradient(#eef38f 2px, transparent 2px);
  background-size: 30px 30px;

  .heading {
    padding-top: 10vh;
    font-size: 2.5rem;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
  
  }
  @media screen and (max-width: 384px) {
    .heading {
      font-size: 2rem;
      font-weight: 700;
      padding-top: 10px;
    }
  }
  .text {
    font-family: "Quicksand", sans-serif;
  }
}

.container2 {
  display: flex;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }

  .container3 {
    width: 40%;
    margin-left: 10%;

    @media screen and (max-width: 991px) {
      width: 90%;
    }

    .heading {
      padding-top: 8vh;
      font-size: 1.5rem;
      font-weight: 700;
      font-family: "Quicksand", sans-serif;
    }
    @media screen and (max-width: 384px) {
      .heading {
        font-size: 1.2rem;
      }
    }

    .flexfiv {
      display: flex;
      margin-top: 5vh;

      a {
        width: 60%;
        margin-left: 4%;
      }
    }
  }
}
.container4 {
  width: 40%;
  margin-right: 10%;
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
}

.con3 {
  width: 80%;
  height: 60%;
  margin-left: 10%;
  margin-top: 10vh;
  
@media screen and (max-width:768px) {
  
  .con3{
    height: 20%;
    width: auto;
  }
}
}

