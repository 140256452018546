.room-master-container {
  width: 95%;
  height: 100%;
  text-align: center;
  justify-content: center;
  padding-top: 1%;
  z-index: 1;
  margin: 0 auto;
}
.room-master-container-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.room-master-manage {
  width: 45%;
}

.room-master-button-export {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}
.room-master-export {
  background-color: var(--default-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: var(--primary-green);
  width: 176px;
  color: var(--primary-green);
  font-style: normal;
  font-size: var(--addpopup-button);
  padding: 10px 16px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 2%;
}
.room-master-button-add {
  display: flex;
  justify-content: flex-end;
  width: 17.4%;
}
.room-master-add {
  background-color: var(--primary-green);
  border: none;
  border-radius: 8px;
  width: 176px;
  /* height: 35px; */
  color: var(--default-white);
  font-style: normal;
  font-size: var(--addpopup-button);
  padding: 10px 16px;
  font-weight: 600;
  cursor: pointer;
}
.room-master-add:hover {
  background: var(--secondary-green);
}
.room-master-export,
.room-master-add {
  padding: 10px 12px;
  font-size: var(--addpopup-button);
}
.roomMaster-components {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: var(--default-white);
  flex-direction: row;
  width: 100%;
  padding-top: 20px;
  justify-content: space-between;
}
.room-master-search {
  position: relative;
  display: flex;
  font-weight: 600;
}
.roomMaster-state-select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
}
.ToggleButtonGroups button.Mui-selected {
  background-color: var(--primary-green);
  color: var(--default-white);
  font-weight: 600;
}

.ToggleButtonGroups button:hover {
  background-color: var(--primary-green);
  color: var(--default-white);
}

.ToggleButtonGroups-root {
  width: 30px;
  height: 32px;
}

.ToggleButtonGroups {
  background-color: rgb(255, 255, 255);
  height: 3.5rem;
}
.room-table-container {
  padding-top: 3%;
}
.room-table {
  width: 100%;
  overflow-x: auto;
}
.room-table .tableHeaderCell {
  cursor: pointer;
  color: var(--Greyscale-900);
  background: var(--Greyscale-50);
  font-family: Urbanist;
  font-size: var(--datagrid-header);
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
/* .tableHeaderCell .asc-arrow , .tableHeaderCell .desc-arrow{
      display: none;
    }
    .tableHeaderCell:hover .asc-arrow,
    .tableBodyCell:hover .desc-arrow{
      display: block;
    } */

.tableHeaderCell .asc-arrow,
.tableHeaderCell .desc-arrow {
  display: none;
}

.room-table-container .tableBodyCell {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--datagrid-body);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.css-1q1u3t4-MuiTableRow-root:nth-child(odd) {
  background-color: var(--default-white);
}

.css-1q1u3t4-MuiTableRow-root:nth-child(even) {
  background-color: var(--Greyscale-50);
}
/* room master popup */

.roomPopupOpen {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 70%;
  /* max-width: 50%; */
  height: 100vh;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  background: var(--Greyscale-50);
  z-index: 9500;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  /* padding: 20px; */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
  -ms-overflow-style: none;
}
.propActivity_PopMain::-webkit-scrollbar {
  width: 5px;
}

.propActivity_PopMain::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.roomPopHeading {
  display: flex;
  justify-content: space-around; /* Align X button to the right */
  height: 8%;
  width: 100%;
  /* box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.03); */
  cursor: pointer;
  align-items: center;
  font-style: normal;
  font-size: var(--popupform-header);
  background: var(--default-white);
}
.roomMaster_p {
  width: 80%;
  height: 100%;
  display: flex;
  font-family: "Urbanist ", Arial, sans-serif;
  align-items: center;
  /* justify-content: center; */
  font-style: normal;
  font-weight: 700;
  font-size: var(--popupform-header);
  line-height: 150%;
  color: #424242;
}
.toggleRoomStatus {
  width: 100%;
  padding-left: 2%;
  padding-top: 2%;
  font-family: "Urbanist ", Arial, sans-serif;
}
.room_field {
  width: 50%;
  justify-content: center;
  padding-left: 2%;
  /* line-height: 34px; */
  font-family: "Urbanist ", Arial, sans-serif;
}
.room_field label {
  color: var(--Greyscale-900);
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.roomMaster_amenitiesDropdown {
  width: 50%;
  padding-left: 2%;
}
.roomMaster_amenitiesDropdown label {
  color: var(--Greyscale-900);
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.roomMaster_amenitiesDropdown .searchWrapper {
  min-height: 45px !important;
  padding: 8px 4% !important;
  border-radius: 8px !important;
  border: 1px solid #ccc !important;
  background-color: var(--Greyscale-50);
}
.room_field input,
.roomMaster_season {
  width: 100%;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  outline: none;
  background-color: var(--Greyscale-50);
}
.room_field input::placeholder {
  color: var(--Greyscale-500);
  font-family: Urbanist;
  font-size: var(--popupform-placeholder);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.error {
  color: var(--alert-status-error);
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: var(--error);
  letter-spacing: 1px;
}
.roomRate_Field_Table {
  width: 100%;
  padding-left: 2%;
  font-family: "Urbanist ", Arial, sans-serif;
  padding-bottom: 2%;
}
.roomMaster_table {
  margin-top: 3vh;

  width: 100%;
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #e0e0e0;
}
.data_container {
  min-width: 35%;
  max-width: 35%;
}
.roomMaster_table_head th {
  border: 1px solid #e0e0e0;
  text-align: center;
  font-weight: bold;
}
.roomMaster_table_body td {
  border: 1px solid #e0e0e0;
  text-align: left;
  padding: 5px;
}
.RoomMaster_table_field {
  width: 100%;
  border: 1px solid var(--Greyscale-500);
  /* height: 7vh; */
  padding: 10px;
  border-radius: 6px;
  outline: none;
  text-align: right;
  background-color: var(--Greyscale-50);
}
.roomPop_buttons {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 20px;
  width: 100%;
}
.roomMaster_buttonn,
.roomMaster_button {
  width: 47%;
  height: 38px;
  color: var(--Greyscale-700);
  font-style: normal;
  font-size: var(--popupform-submit-cancel-button);
  border: 1px solid var(--Greyscale-700);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--default-white);
}
.roomMaster_buttonn {
  background: var(--primary-green);
  color: #fefefe;
  border: none;
}
.roomMaster_buttonn:hover {
  background: var(--secondary-green);
}

/* Assign popup */

.AssignPopupOpen {
  width: 50%;
  height: 100vh;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  background: var(--Greyscale-50);
  z-index: 9999;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
  -ms-overflow-style: none;
}
.Assignpophead {
  display: flex;
  justify-content: space-around; /* Align X button to the right */
  height: 8%;
  width: 100%;
  /* box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.03); */
  cursor: pointer;
  align-items: center;
  font-style: normal;
  font-size: var(--popupform-header);
  background: var(--default-white);
}
.Assign_p {
  width: 80%;
  height: 100%;
  display: flex;
  font-family: "Urbanist ", Arial, sans-serif;
  align-items: center;
  /* justify-content: center; */
  font-style: normal;
  font-weight: 700;
  font-size: var(--popupform-header);
  line-height: 150%;
  color: #424242;
  padding-left: 1.5%;
}
.AsssignForm {
  padding-left: 2.5%;
  width: 60%;
}
.AssignPopupOpen .AssignPopField {
  display: flex;
  flex-direction: column;
  width: 95%;
  justify-content: center;
  padding-left: 3%;
  padding-bottom: 2%;
  /* line-height: 34px; */
  font-family: "Urbanist ", Arial, sans-serif;
}
.AssignPopField-label {
  color: var(--Greyscale-900);

  /* Body Large/Semibold/16px */
  font-family: Urbanist;
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.AssignPopupOpen .AssignPopField input {
  width: 100%;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  outline: none;
  background: var(--Greyscale-50);
  /* height: 75%; */
}
.formic-container {
  display: flex;
  padding-top: 3%;
}
.Room_End {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding-top: 20px;
  width: 100%;
  padding: 5% 3%;
}
.button_container {
  display: flex;
  gap: 1rem;
  padding-left: 3%;
  padding-bottom: 2%;
}
.room_save {
  color: var(--default-white);
  padding: 0.5rem;
  background: var(--primary-green);
  border-radius: 8px;
  border: none;
}
.room_update {
  background: var(--primary-green);
  color: var(--default-white);
  padding: 0.5rem;
  border-radius: 8px;
  border: none;
  width: 50%;
}
.room_data {
  gap: 3rem;
  display: flex;
  align-items: center;
  max-width: 100%;
  min-width: 12%;
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  background: var(--default-white);
  border: 1px solid var(--Greyscale-500);
}
.room_content {
  line-height: 1.3rem;
  width: 85%;
}
.err_button {
  background: var(--alert-status-error);
  color: var(--default-white);
  padding: 0.5rem;
  border-radius: 8px;
  border: none;
  width: 50%;
}

.Room_End .Room_buttona,
.Room_End .Room_buttonb {
  width: 100%;
  height: 38px;
  color: #616161;
  font-style: normal;
  font-size: var(--popupform-submit-cancel-button);
  border: 1px solid #616161;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--default-white);
  border-radius: 8px;
}

.Room_End .Room_buttonb {
  background: var(--primary-green);
  color: #fefefe;
  border: none;
}
/* checkbox color */
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: var(--primary-green) !important;
}

.btneditRoom {
  position: absolute;
  left: 87.5%;
  padding-bottom: 6%;
}
.btndeleteRoom {
  position: absolute;
  left: 91%;
  padding-bottom: 6%;
}

@media screen and (max-width: 1024px) {
  .room-master-button-add {
    width: 23.4%;
  }
  .room-master-button-export {
    width: 40%;
    margin-right: 1%;
  }
}
@media (max-width: 768px) {
  .room-master-container {
    margin: 0;
    width: 100%;
  }
  .room-master-manage {
    width: 40%;
  }

  .room-master-button-expor {
    width: 30%;
    margin-right: 1%;
  }
  .room-master-button-add {
    width: 29.4%;
  }
  .room-master-export,
  .room-master-add {
    padding: 10px 12px; /* Adjusted padding */
    font-size: var(--addpopup-button); /* Adjusted font size */
  }
  .roomPopupOpen {
    width: 80%;
  }
}
@media (max-width: 600px) {
  .roomPop_buttons {
    flex-direction: column;
    align-items: center;
  }
  .roomMaster_buttonn,
  .roomMaster_button {
    width: 100%;
    margin-bottom: 10px;
  }
}
