  /* @import 'tailwindcss/base';
  @import 'tailwindcss/components';
  @import 'tailwindcss/utilities'; */
/* @import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100&display=swap'); */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Urbanist', sans-serif;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  font-family:  'Urbanist', sans-serif !important;
}


.Overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100 !important;
}

.manage_page_heading
{
  float: left;
  font-family: "Urbanist", Arial, sans-serif;
  font-style: normal;
  color: #212121;
  font-size: var(--page-header);
  font-weight: bolder;
}

.sitemap-container {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
 margin-top: 8rem;
}

.sitemap-header {
  font-size: x-large;
  color: var(--primary-green);
  text-align: center;
  padding: 10px 0;
  font-weight: 800;
}

.sitemap-header h1 {
  margin: 0;
  color: var(--primary-green);
}


.sitemap-content {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.sitemap-section-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: var(--primary-green);
}

.sitemap-list {
  list-style-type:disc;
  padding-left: 20px;
}

.sitemap-list li {
  margin-bottom: 10px;
}

.sitemap-list li a {
  color: #333;
  text-decoration: none;
}

.sitemap-list li a:hover {
  color: var(--secondary-green);
  text-decoration: underline;
}
