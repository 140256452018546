.itemRequest_PopMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 100%;
    background-color: var(--Greyscale-50);
    z-index: 600;
    position: fixed;
    overflow-y: auto;
    top: 0;
    right: 0;
    scrollbar-width: thin; 
    scrollbar-color: transparent transparent; 
    -ms-overflow-style: none;
    padding-top: unset;
  }
  
  .itemRequest_PopMain::-webkit-scrollbar {
    width: 5px;
  }
  
  .itemRequest_PopMain::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  
  .quantity-count-input {
    width: 50%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: 0;
   
  }

  .item_End {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 20px;
    width: 100%;
    gap: 40px;
  }
  
  .item_End .item_buttona,
  .item_End .item_buttonb {
    width: 40%;
    height: 38px;
    color: #616161;
    font-style: normal;
    font-size: var(--popupform-submit-cancel-button);
    border: 1px solid #616161;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;
  }
  
  .item_End .item_buttonb {
    background: var(--primary-green);
    color: #fefefe;
    border: none;
  }
  
  .item_End .item_buttonb:hover {
    background: #b4ba3d;
  }