/* Wrapper for the entire grid container */
.primary_start {
  border: 1px solid #eeeeee;
  padding: 20px;
}

.primary_booking_status{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/* Guest Details Section */
.primary_guest_details,
.primary_room_details,
.primary_balance_section {
  border: 1px solid #eeeeee;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
}

.primary_logo img {
  max-width: 100px;
}

.primary_subcontainer1 .primary_JohnDoe {
  font-size: 18px;
  font-weight: bold;
}

.primary_subcontainer1 .primary_Num1 {
  font-size: 14px;
  color: #555;
}

.primary_subcontainer2 .primary_Num1 {
  font-size: 14px;
  color: #555;
}

.primary_container_2 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.primary_sub_container {
  display: flex;
  align-items: center;
}

.primary_document_logo {
  width: 4vw; 
  padding-left: 44%;
}

.primary_document_text {
  font-size: 14px;
  margin-left: 5px;
}

.primary_view_button {
  color: #246bfd;
  width: 66%;
  display: flex;
  justify-content: right;
  cursor: pointer;
  font-weight: bold;
}

.primary_view_button:hover {
  text-decoration: underline;
}

.primary_room_details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.primary_room_detail_row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  font-size: 14px;
}

.primary_room_label {
  font-weight: bold;
}

.primary_room_value {
  text-align: right;
  flex: 1;
}

/* Room Card Styling */
.primary_room_card {
  border: 1px solid #eeeeee;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9f9f9; /* Default background color */
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  color: #fff; /* Text color inside the card */
  transition: background-color 0.3s ease-in-out;
}

.primary_room_card_title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.primary_room_label {
  font-weight: bold;
}

.primary_room_value {
  font-size: 16px;
  font-weight: bold;
}

.primary_room_card_body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.primary_room_detail {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

/* Optional: Add color styles for specific room statuses */
.primary_room_status_occupied {
  background-color: green !important; /* Green for Occupied */
}

.primary_room_status_available {
  background-color: red !important; /* Red for Available */
}

.primary_room_status_pending {
  background-color: gray !important; /* Gray for Pending */
}

.primary_balance_section {
  display: flex;
  flex-direction: column; 
  justify-content: flex-start; 
}

.primary_container2 {
  display: flex;
  flex-direction: column;
}

.primary_box {
  display: flex;
  gap: 10px;
  margin: 10px 0px;
  flex-wrap: wrap;
  align-items: stretch;
}

.primary_box_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f1f1f1;
  padding: 10px;

  flex: 1 1 30%;
  border-radius: 4px;
  color: #555;
  text-align: center;
}

.primary_box_item div {
  font-size: 16px;
  font-weight: bold;
}

.primary_sub_container {
  display: flex;
  flex-direction: row;
  background: #f5f5f5;
  height: 3rem;
  width: 105%;
  padding: 5%;
  border-radius: 8px;
  align-items: center;
}


.primary_start .MuiGrid-item {
  display: flex;
  align-items: stretch;
}

.primary_main_balance_section {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.primary_invoice {
    border: 1px solid #eeeeee;
    padding: 20px;
  }
  h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Table Styling for Invoice */
  .invoice_table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .invoice_table th,
  .invoice_table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .invoice_table th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  .invoice_table td {
    background-color: #fff;
    color: #333;
  }
  
  /* Service Tax & GST Table */
  .invoice_service_tax_table,
  .invoice_net_total_table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .invoice_service_tax_table th,
  .invoice_net_total_table th,
  .invoice_service_tax_table td,
  .invoice_net_total_table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .invoice_service_tax_table th,
  .invoice_net_total_table th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  .invoice_service_tax_table td,
  .invoice_net_total_table td {
    background-color: #fff;
    color: #333;
  }
  
  /* Buttons */
  .generate-pdf {
    color: #ffff;
    background-color:var(--primary-green);
    font-size: 26px;
    cursor: pointer;

  }
  


@media (max-width: 1024px) {

  .primary_start .MuiGrid-container {
    flex-direction: column; 
  }

  .primary_box_item {
    flex: 1 1 100%; 
  }

  .primary_box {
    flex-direction: column; 
  }

  .primary_balance_section {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  
  .primary_start .MuiGrid-container {
    flex-direction: column; 
  }

  .primary_box_item {
    flex: 1 1 100%; 
  }

  .primary_box {
    flex-direction: column; 
  }

  .primary_container_2 {
    flex-direction: column; 
  }
  .primary_document_logo{
    padding: 0px;
  }
}
