.physiotherapy-main-conatiner {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-content: center;
  position: relative;
  border: 1px solid #c41b1b;
}
.physiotherapy-title {
  font-size: 28px;
  color: black;
  font-weight: 800;
  align-items: center;
  text-align: center;
  /* + icon  */
  display: flex;
  justify-content: center;
}
.physiotherapy-main-container h1 {
  font-size: 28px;
  color: red;
}
.physiotherapy-title-icon {
  background: #6c7d02;
  border-radius: 50%;
  border: none;
  width: 2.7%;
  height: 35px;
  font-size: 26px;
  color: #ffffff;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  position: absolute;
  right: 5%;
  transition: 0.6s;
}

.physiotherapy-title-icon:hover {
  background: #b4ba3d;
  color: #ffffff;
}

.physiotherapy-card-section {
  width: 98%;
  border: 1px solid #ccc;
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 15px;
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 5%;
}
.physiotherapy-card-left {
  width: 45%;
  /* border: 3px solid purple; */
  padding: 25px;
}

.physiotherapy-card-left-description {
  margin-top: 8%;
}

.physiotherapy-card-left-title,
.physiotherapy-card-left-description {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.physiotherapy-card-left-title label,
.physiotherapy-card-left-description label {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  width: 22%;
  text-align: center;
}
.physiotherapy-in-out select {
  color: #6c7d02;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  width: 20%;
  height: 19%;
  padding: 0.5%;
  font-size: 18px;
  border: 2px solid #eee;
  border-radius: 5px;
  cursor: pointer;
}

.physiotherapy-in-out select:focus {
  outline: none;
}

.physiotherapy-in-out select option {
  color: #6c7d02;
  background-color: #f0f0f0;
  cursor: pointer;
  font-weight: bold;
}

.physiotherapy-card-left-title input,
.physiotherapy-card-left-description input {
  padding: 10px;
  font-size: 16px;
  width: 60%;
  transition: border-color 0.3s;
}
.physiotherapy-card-left-title input:focus {
  border-bottom: 1px solid #ccc;
  outline: none;
}

.physiotherapy-card-left-description input:focus {
  border: 1px solid #ccc;
  outline: none;
}

.physiotherapy-card-left-title input {
  border-bottom: 1px solid #ccc;
  align-self: flex-start;
}

.physiotherapy-card-left-description input {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 80px;
  align-self: flex-start;
}

.physiotherapy-card-right-btn {
  background: #6c7d02;
  border-radius: 8px;
  border: none;
  width: 12%;
  height: 3.5vw;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  /* margin-left: 1080px; */
}

.error {
  display: flex;
  color: red;
  flex-direction: column;
  font-size: 12px;
}

/* RIGHT SIDE  */
.physiotherapy-card-right {
  width: 55%;
  /* border: 3px solid rgb(230, 162, 230); */
  padding: 25px;
}

.physiotherapy-card-right-date {
  display: flex;
  width: 100%;
  gap: 30px;
  justify-content: center;
}

.physiotherapy-card-right-start-date label,
.physiotherapy-card-right-end-date label {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  width: 22%;
}

.physiotherapy-card-right-notes-section {
  margin-top: 25px;
}

.physiotherapy-card-right-notes-section h3 {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.physiotherapy-card-right-notes-entry {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.physiotherapy-card-right-notes-entry span {
  font-weight: 900;
  width: 100px;
  font-size: 16px;
}

.physiotherapy-card-right-notes-entry input {
  /* margin-left: 10px; */
  padding: 5px;
  width: 280px;
  height: 45px;
  border: 1px solid black;
  border-radius: 8px;
}

.physiotherapy-card-section-add {
  background: #6c7d02;
  border-radius: 50%;
  border: none;
  width: 3.7%;
  height: 45px;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0%;
  right: 2%;
}
.delete-section-btn {
  position: absolute;
  right: 40px;
  cursor: pointer;
  font-size: 26px;
  color: red;
  font-weight: bold;
}
.physiotherapy-card-right-notes-input {
  padding: 10px;
  font-size: 16px;
  width: 60%;
  transition: border-color 0.3s;
}

.physiotherapy-card-right-notes-input:focus {
  border-bottom: 1px solid #ccc;
  outline: none;
}

/* chat  */

.physiotherapy-card-right-notes-section {
  margin-top: 25px;
  position: relative;
}

.physiotherapy-title-icon {
  background: #6c7d02;
  border-radius: 50%;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}

.physiotherapy-title-icon1 {
  background: #6c7d02;
  border-radius: 50%;
  border: none;
  width: 52px;
  height: 35px;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 7px;
  margin-left: 5px;
}

.physiotherapy-card-right-notes-entry {
  display: flex;
  align-items: center;
  margin-top: 15px;
  gap: 10px;
}

.physiotherapy-card-right-date-picker {
  border: none;
  font-size: 16px;
  font-weight: bold;
  width: 30%;
}

.physiotherapy-card-right-notes-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 60%;
}
