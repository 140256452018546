/* Import Google Font */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap");

/* Main Section */
.ads_secondmain {
  background: #ffc000;
  width: 100%;
  height: 100vh;  /* 100vh for desktop */
  z-index: -10;
}

@media screen and (max-width: 1024px) {
  /* Tablet - Adjust the height */
  .ads_secondmain {
    height: 450px;  /* Fixed height for tablets */
  }
}

@media screen and (max-width: 425px) {
  /* Mobile - Adjust the height */
  .ads_secondmain {
    height: 350px;  /* Fixed height for mobile */
  }
}

/* Round element */
.ads_round {
  border-radius: 50%;
  position: sticky;
  float: right;
  background-color: #ffd146;
  height: 80%;  /* Percentage of the parent container's height */
  width: 40vw;
  margin-right: -15vw;
  margin-top: -10vw;
}

/* Heading Section */
.ads_second-heading {
  font-family: "Quicksand", sans-serif;
  font-size: 2.5rem;
  width: 55%;
  padding-top: 5%;  /* Changed from vh to percentage */
}

@media screen and (max-width: 800px) {
  .ads_second-heading {
    width: 75%;
    font-size: 1.8rem;
  }
}

/* Image Styling */
.ads_image {
  width: 80%;
  position: absolute;
  left: 10%;
  box-shadow: 0px 10px 14px 0px #0000000D;

}

@media screen and (max-width: 500px) {
  .ads_second-heading {
    font-size: 1.5rem;
  }
  .ads_image {
    width: 80%;
  }
}
