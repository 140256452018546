.export_property{
    display: flex;
  }

.categoryMaster_main_component{
    width: 95%;
    height: 100%;
    text-align: center;
  justify-content: center;
  padding-top: 1%;
  z-index: 1;
  margin: 0 auto;
}
.categoryMaster_main_sec_1{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.categoryMaster_main_heading{
    width: 46%;
}
.category_search_main_container{
    position: relative;
    padding: 0%;
    margin-bottom: 10%;
}
.catrgory_data_grid{
    padding-top: 3%;
    width: 100%;
    overflow-x: auto;
}
.catrgory_data_grid .categorytableHeaderCell{
  color: var(--Greyscale-900);
  background: var(--Greyscale-50);
  font-family: Urbanist;
  font-size: var(--datagrid-header);
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.catrgory_data_grid .categorytableBodyCell{
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--datagrid-body);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

}
.category_popup{
    width: 40%;
    height: 100vh;
    background-color: var(--Greyscale-50);
    z-index: 1200;
    position: fixed;
    overflow-y: auto;
    top: 0;
    right: 0;
    padding-bottom: 2%;
    padding-top: unset;
    scrollbar-width: thin;/* Firefox */
    scrollbar-color: transparent transparent;/* Firefox */
    -ms-overflow-style: none;
}
.category_popup::-webkit-scrollbar{
  width: 5px;
}
.category_popup::-webkit-scrollbar-thumb{
    background-color: transparent;
}
.category_popup_first{
    display: flex;
  justify-content: space-between;
  height: 8%;
  width: 103%;
  cursor: pointer;
  align-items: center;
  font-style: normal;
  font-size: 18px;
  padding-left: 4%;
  padding-right: 6%;
  margin-left: -3%;
  background-color: #fff;
}
.category_popup_first p{
    height: 100%;
  padding-left: 3%;
  display: flex;
  font-family: "Urbanist ", Arial, sans-serif;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: var(--popupform-header);
  line-height: 150%;
  color: #424242;

}
.category_popup .category_field_container{
    width: 100%;
    justify-content: center;
    padding-left: 4%;
    padding-right: 4%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.category_popup .category_field_container input{
    width: 100%;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  outline: none;
  background: var(--Greyscale-50);
}
.category_popup .category_field_containerlabel sup{
    color: #dc3545;
}
.category_button_container{
    display: flex;
  flex-direction: row-reverse;
  justify-content: center;
 
  padding-top: 3%;
  width: 100%;
}
.category_button_container .category_buttonB,
.category_button_container .category_buttonA{
    width: 15.25rem;
  height: 38px;
  color: #616161;
  font-style: normal;
  font-size: var(--popupform-submit-cancel-button);
  border: 1px solid #616161;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}

.category_button_container .category_buttonB{
    background: var(--primary-green);
    color: #fefefe;
    border: none;
}
.Category_field_label{
    color: var(--Greyscale-900);
    font-family: Urbanist;
    font-size: var(--popupform-label);
    font-style: normal;
    font-weight: 600;
    line-height: 150%; 
}
.category_field{
  background-color: var(--Greyscale-50);

}
.category_field::placeholder{
    color: var(--Greyscale-500);
  font-family: Urbanist;
  font-size: var(--popupform-placeholder);
  font-style: normal;
  font-weight: 500;
  line-height: 150%; 
}
.category_button_container .category_buttonB:hover{
  background: var(--primary-green);

}

/* toast message */

.category-field-error{
    color: #f75555;
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 1px;
  margin-top: 4px;
  margin-bottom: 4px;
}

@media (max-width: 1024px){
    .category_button_container .category_buttonB,
.category_button_container .category_buttonA{
    width: 12rem;
}

}
@media (max-width: 768px) {
    .categoryMaster_main_component{
margin: 0;
width: 100%;

    }
    .category_popup{
        width: 45%;
    }
    category_button_container .category_buttonB,
.category_button_container .category_buttonA{
    width: 10rem;
}
}