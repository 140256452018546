/* Card Container */

.ListingPageMain{
    margin-top: 2%;
    padding: 0% 1%;
}
.company-card {
    display: flex;
    /* width: 850px; */
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 16px;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    margin-bottom: 1%;
  }
  
  /* Left Section */
  .company-left {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    width: 70px;
  }
  
  .company-logo {
    width: 50px;
    height: 50px;
    background: black;
    color: white;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  
  /* Right Section */
  .company-right {
    flex: 1;
    padding-left: 16px;
  }
  
  /* Top Row */
  .top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .company-name {
    font-size: 18px;
    margin: 0;
  }
  
  .verified-badge {
    background: #008000;
    color: white;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 12px;
    font-weight: bold;
  }
  
  /* Rating Section */
  .rating {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  
  .stars {
    color: #ff9900;
    margin-right: 8px;
    display: flex;
  }
  
  .star {
    font-size: 16px;
  }
  
  .reviews {
    font-size: 14px;
    color: #666;
  }
  
  /* Details Section */
  .details {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    font-size: 14px;
    color: #555;
    margin-top: 8px;
  }
  
  .details div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  /* Services Section */

  .services-title {
    font-size: 12px;
    color: #555;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }

  .services-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .service-item {
    background-color: #6c7d02;
    color: #ffffff;
    padding: 8px 12px;
    border-radius: 20px;
    font-size: 14px;
    display: inline-block;
    max-width: 100%;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .service-item:hover {
    background-color: var(--secondary-green);
    color: white;
  }
  /* .services {
    margin: 12px 0;
  }
  
  .service-bar {
    background: #ddd;
    height: 8px;
    border-radius: 4px;
    display: flex;
    overflow: hidden;
  }
  
  .service {
    font-size: 12px;
    text-align: center;
    color: white;
    padding: 4px 8px;
  }
  
  .mobile-app {
    background: #007bff;
  }
  
  .marketing {
    background: #28a745;
  }
  
  .uiux {
    background: #ff9900;
  } */
  
  /* Description */
  .description {
    font-size: 14px;
    color: #444;
    margin: 12px 0;
  }
  
  /* Buttons */
  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  button {
    padding: 8px 12px;
    border: none;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .view-profile {
    background: #6c7d02 !important;
  }

  .social-links {
    margin-top: 15px;
    display: flex;
    gap: 15px;
  }
  
  .social-icon {
    font-size: 24px;
    color: #6c7d02; /* Blue color */
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: var(--secondary-green); /* Green on hover */
  }
  

  .view-profile:hover{
    background: var(--secondary-green) !important;
  }
  