.inr {
  font-family: "Urbanist", sans-serif;
  font-size: 1.6rem;
}

.f-card-button1 {
  display: flex;
  color: #6c7d02;
  border: 1px solid #6c7d02;
  border-radius: 5px;
  width: 80%;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.f-card-button1-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.f-card-button1:hover {
  background-color: #6c7d02;
  color: white;
}

.fourth-heading {
  font-family: Quicksand;
  font-size: 3rem;
}

.price {
  font-family: "Urbanist", sans-serif;
  font-size: 3rem;
}

.subscription-cards {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}

.subscription-card {
  padding: 20px;
  margin: 10px;
  /* text-align: center; */
  border: 1px solid lightgray;
  border-radius: 10px;
  width: 30%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.firstcard1 {
  display: flex;
  justify-content: space-between;
}

.fourth-text {
  font-family: "Urbanist", sans-serif;
  font-size: 1.2rem;
}
.option1 {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap:20px;
  margin-right: 3%;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner {
  margin: 0px;
  width: 200px;
  height: 40px;
  background-color: var(--secondary-green);
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
    /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
  display: block;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: 18px;
  font-weight: 600;
  top: 7px;
  right: 20px;
  color: #222;
  cursor: pointer;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 95px;
  height: 35px;
  background: #fff;
  border-radius: 26px;
  position: absolute;
  font-size: 18px;
  display: flex;
  justify-content: center;
  left: 2px;
  top: 2px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 5px 0px;
  color: #222;
  font-weight: 600;
  cursor: pointer;
}

.btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner {
  background-color: var(--secondary-green);
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 100px;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 20px;
}


/* Media queries for responsiveness */
@media (max-width: 768px) {
  .subscription-card {
    width: 47%;
  }
}

@media (max-width: 480px) {
  .subscription-card {
    width: 100%;
  }

  .option1 {
    display: flex;
    flex-direction: column-reverse;
    align-items: end;
    gap: 5px;
  }
}

.switch-container {
  display: inline-block;
}

.switch-input {
  display: none;
}

.switch-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 25px;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
}

.switch-label:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3.5px;
  width: 20px;
  height: 20px;
  background-color: #ff5555;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.switch-label .switch-text {
  flex: 1;
  text-align: center;
}

.switch-input:checked + .switch-label:before {
  transform: translateX(22.5px);
}

.switch-text {
  color: #333;
}

.switch-text:first-child {
  margin-left: 5px;
}

.switch-text:last-child {
  margin-right: 5px;
}

.switch_ {
  margin-right: 50px;
}

.switch_ main {
  display: flex;
  align-items: center;
  background: #6c7d02;
  padding: 5px;
  border-radius: 8px;
}

.switch_ ._label {
  margin: 0 7px;
  color: #f3f3f3;
  font-size: 15px;
  letter-spacing: 0.5px;
}

.switch_1 {
  margin-right: 50px;
}

.switch_1 main {
  display: flex;
  align-items: center;
  background: #6c7d02;
  padding: 5px;
  border-radius: 8px;
}

.switch_1 ._label {
  margin: 0 7px;
  color: #f3f3f3;
  font-size: 15px;
  letter-spacing: 0.5px;
}
