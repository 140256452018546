.testimonial_main_container {
  width: 100%;
  max-width: 1440px;
  height: 100%;
  margin: auto;
  text-align: center;
  position: relative;
  padding: 0px 50px;
  margin-top: 10%;
}

.testimonial_header p {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.05em;
  color: var(--black);
  margin: 0;
  margin-top: 1%;
}

.testimonial_header h3 {
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  color: var(--black);
  margin-top: 3px;
}

.testimonial_review_section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 15px;
  gap: 20px;
  width: 100%;
}

.testimonial_review_section > div {
  width: calc(45% - 16px);
  height: 320px;
  background-color: var(--ghost-white);
  border-radius: 8px;
  padding: 30px 20px;
  display: block;
  opacity: 1;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.testimonial_review_section_item {
  position: relative;
}

.testimonial_review_section_content_h3 {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #424242;
}

.testimonial_review_section_content_position {
  font-size: 12px;
  line-height: 18px;
  color: #424242;
  text-align: left;
}

.testimonial_review_section_content_content {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: justify;
  color: #424242;
  margin-top: 7%;
}

.testimonial_review_section_double_quote_mark {
  width: 48px;
  height: 39px;
  position: absolute;
  top: 5%;
  right: 5%;
}

.testimonial_navigationbar {
  width: 100px;
  height: 8px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 0;
  cursor: pointer;
}

.navigation_dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #d1d1d1;
  transition: background-color 0.3s ease;
}

.navigation_dot.active {
  background-color: #ffcc00; /* Yellow when active */
}

/* ============================================= ADD MEDIA QUERY ====================================== */

@media (max-width: 1024px) {
  .testimonial_review_section > div {
    width: calc(50% - 16px);
    height: 300px;
  }
  .testimonial_main_container{
    margin-top: 20%;
  }
}

@media (max-width: 768px) {
  .testimonial_review_section {
    display: block;
  }

  .testimonial_main_container {
    padding: 0px 20px;
    margin-top: 6%;
  }

  .testimonial_review_section > div {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    margin: 0 auto;
  }

  .testimonial_header p {
    font-size: 12px;
    font-weight: 600;
    color: var(--TopBar-Light-Green);
  }

  .testimonial_header h3 {
    font-size: 28px;
    font-weight: 700;
  }

  .testimonial_review_section {
    flex-direction: column;
  }

  .testimonial_review_section_content_content {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    margin-top: 2%;
  }
}
