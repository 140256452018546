.slider-container {
  position: relative;
  overflow: hidden;
  width: 60%;
  left: 20%;
  padding-top: 5%;
  height: 100vh;
}

.slider-wrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.slide {
  flex: 1 0 100%;
}

.slide img {
  width: 100%;
  height: 86vh;
}

.slider-indicators {
  position: absolute;
  bottom: 3vh;
  left: 54%;
  transform: translateX(-50%);
  display: flex;
}

.slider-indicator {
  width: 50px;
  height: 3px;
  margin-right: 10px;
  background-color:#5d7202;
  cursor: pointer;
  position: relative;
}

.slider-indicator.active {
  background-color: #c3d477 ;
}

.slider-text{
  color: white;
  position: relative;
  top: -214px;
  width: 80%;
  margin-left: 10%;
  font-family: 'urbanist';
  font-size: 1.3rem;
  text-align: center;
}


@media screen and (max-width:1024px) {
  
.slider-text{

 font-size: 18px;
}
.slider-container {
  width:75%;
  left: 13%;
}
 
}
@media screen and (max-width:760px) {
  .slider-container{
    visibility: collapse;
  }
  .sec-left{
    width: 0%;
  }
}




