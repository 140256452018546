.reservation_MainPae {
  width: 95%;
  height: 100%;
  text-align: center;
  justify-content: center;
  padding-top: 1%;
  z-index: 1;
  margin: 0 auto;
}

.reservation_sect-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1%;

}


/*   
  .reservation_add {
    display: flex;
    justify-content: flex-end;
  }
   */
.reservation_manage {
  width: 45%;
}

.reservation_export {
  display: flex;
  justify-content: flex-end;
  width: 66%;
}

/* .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: none;
} */

.reservation_sect-1 .button_export {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: var(--primary-green);
  width: 176px;
  color: var(--primary-green);
  font-style: normal;
  font-size: 16px;
  padding: 10px 16px;
  font-weight: 600;
  cursor: pointer;
}



/* .button_add_ {
  background-color: var(--primary-green);
  border: none;
  border-radius: 8px;
  width: 53px;
  color: rgb(255, 255, 255);
  font-style: normal;
  font-size: 16px;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
} */

.Table {
  position: relative;
  padding: 0%;
  margin-bottom: 10%;
}


.reservation_toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
}

.ToggleButtonGroups button.Mui-selected {
  background-color: var(--primary-green);
  color: white;
  font-weight: 600;
}

.ToggleButtonGroups button:hover {
  background-color: var(--primary-green);
  color: white;
}

.ToggleButtonGroups-root {
  width: 30px;
  height: 32px;
}

.ToggleButtonGroups {
  background-color: rgb(255, 255, 255);
}

.DataGrid {
  padding-top: 3%;
}

.DataGrid .feature-table {
  width: 100%;
  overflow-x: auto;
}

.button_search {
  background-color: var(--primary-green);
  border: none;
  border-radius: 8px;
  width: 7rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  /* height: 35px; */
  color: rgb(255, 255, 255);
  font-style: normal;
  font-size: 14px;
  padding: 12px 16px;
  font-weight: 600;
  cursor: pointer;
}

.react-datepicker-wrapper {
  margin-right: 1%;
}

.booksect1 {
  /* box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.02); */
  padding: 2%;
  width: 68%;
  background-color: var(--default-white);
}

.booksect2 {
  background-color: var(--default-white);
  /* box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.02); */
  padding: 2%;
  z-index: 10;
  min-width: 28%;
  max-width: 28%;
}

.btnedit {
  position: absolute;
  left: 90%;
}

.btndelete {
  position: absolute;
  left: 92.5%;
}

.guest_non_content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  min-height: 12%;
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  background: var(--Greyscale-50);
}

.guest_content {
  max-width: 100%;
  min-height: 12%;
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  background: var(--Greyscale-50);
  display: flex;
  gap: 0.75rem;
}

/* .react-datepicker-wrapper{
    border: 1px solid black;
    border-radius: 5px;
    margin-left: 2.5rem;
  } */

@media (max-width: 1024px) {

  .AddAmenity,
  .MainPae {
    position: relative;
  }

  .css-1riu5e0-MuiInputBase-root .MuiInputBase-input {
    padding-left: 20% !important;
    width: 24ch !important;
  }

  
  /* .reservation_toggle {
    width: 100%;
  } */

  .DataGrid {
    padding-top: 2%;
  }

  .reservation_First {
    width: 94% !important;
    height: 7% !important;
    background-color: red;
    position: absolute;
    /* left: 9%; */
  }

  .reservation_PopMain {
    width: 62% !important;
  }

  .lg\:flex {
    flex-direction: column;
  }

  .mt-6 {
    margin-top: 2.5rem;
  }

  .px-6 {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }

  .book {
    flex-direction: column;
  }

  .booksect1 {
    width: auto;
  }

  .booksect2 {
    max-width: unset;
  }

  .btnedit {
    left: 88%;
  }
}

@media (max-width: 768px) {
  .toggle {
    width: 16%;
  }

  .b {
    width: 64%;
  }

  .feature_manage {
    width: 60%;
  }

  .css-1riu5e0-MuiInputBase-root .MuiInputBase-input {
    padding-left: 20% !important;
    width: 20ch !important;
  }

  .button_search {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .reservation_PopMain {
    width: 83% !important;
  }

  .reservation_First {
    height: 5% !important;
  }
}

/* Booking Pop Up */

.reservation_PopMain {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 80%;

  height: 100vh;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  background: rgb(255, 255, 255);
  z-index: 9998;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  padding: 20px;
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: transparent transparent;
  /* Firefox */
  -ms-overflow-style: none;
}

.res_parent-box-dropdown-content::-webkit-scrollbar {
  display: scroll;
}

.res_parent-box-dropdown-content {
  -ms-overflow-style: scroll;
  /* IE and Edge */
  scrollbar-width: scroll;
  /* Firefox */
}

.res_parent-box-dropdown-content-big {
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 280px;
}

.res_parent-box-dropdown-content-small {
  height: fit-content;
}

.reservation_PopMain::-webkit-scrollbar {
  width: 5px;
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-prev-btn,
.ant-picker-header-super-next-btn,
.ant-picker-header-next-btn {
  display: none;
}

.ant-picker .ant-picker-outlined .css-dev-only-do-not-override-1k979oh {
  min-width: fit-content;
}

/* Manually target Ant Design DatePicker CSS classes */

.ant-picker-cell.ant-picker-cell-hover.ant-picker-cell-selected.ant-picker-cell-in-view .ant-picker-cell-inner {
  color: #fff;
  background: #ffc000;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-picker-cell.ant-picker-cell-selected.ant-picker-cell-in-view .ant-picker-cell-inner:not(:hover) {
  color: #fff;
  background: #ffc000;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.checking {
  min-width: fit-content;
}

.reservation_PopMain::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.Toast {
  line-height: 1px;
}

.Toast h5 {
  color: rgb(255, 255, 255);
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.4px;
}

.error {
  color: #f75555;
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 1px;
}

.reservation_First {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* Align X button to the right */
  height: 8%;
  width: 100%;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.02);
  cursor: pointer;
  align-items: center;
  font-style: normal;
  font-size: 18px;
}

.reservation_First p {
  width: 100%;
  display: flex;
  font-family: "Urbanist ", Arial, sans-serif;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #424242;
}

.reservation_PopMain .reservation_Name {
  width: 100%;
  justify-content: center;
  padding-left: 5%;
  line-height: 34px;
  font-family: "Urbanist ", Arial, sans-serif;
}

.reservation_PopMain .reservation_Name input {
  width: 95%;
  align-items: center;
  padding: 13px 16px;
  border: 1px solid #899197;
  border-radius: 8px;
  outline: none;
}

.reservation_PopMain .reservation_Name label sup {
  color: #dc3545;
}

.reservation_PopMain .Text {
  width: 100%;
  justify-content: center;
  padding-left: 5%;
  line-height: 34px;
}

.reservation_PopMain .Text input {
  width: 95%;
  height: 100px;
  padding: 8px 16px;
  border: 1px solid #899197;
  border-radius: 8px;
  outline: none;
  font-family: "Urbanist ", Arial, sans-serif;
}

.reservation_Togglebutton-1 {
  padding-right: 80%;
}

.reservation_PopMain .reservation_ProductDropdown {
  width: 100%;
  justify-content: center;
  padding-left: 5%;
  line-height: 34px;
  font-family: "Urbanist ", Arial, sans-serif;
}

.reservation_PopMain .reservation_ProductDropdown select {
  width: 95%;
  align-items: center;
  padding: 13px 16px;
  border: 1px solid #899197;
  border-radius: 8px;
  outline: none;
}

.reservation_PopMain .reservation_ProductDropdown label sup {
  color: #dc3545;
}

.reservation_Togglebutton-1 .reservation_ToggleButtonGroups-1 button {
  color: #000000;
  border: none;
  background: #f5f5f5;
}

.reservation_Togglebutton-1 .reservation_ToggleButtonGroups-1 .Mui-selected {
  background-color: var(--primary-green);
  color: white;
}

.reservation_Togglebutton-1 .reservation_ToggleButtonGroups-1 button:hover {
  background-color: var(--primary-green);
  color: white;
}

.reservation_Togglebutton-1 .reservation_ToggleButtonGroups-1-root {
  width: 30px;
  height: 32px;
}

.reservation_Togglebutton-1 .reservation_ToggleButtonGroups-1 {
  background-color: rgb(255, 255, 255);
  color: #000000;
}

.reservation_End {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* Ensure buttons are spaced evenly */
  padding-top: 20px;
  /* Adjust as needed */
  width: 100%;
}

.reservation_End .reservation_buttona,
.reservation_End .reservation_buttonb {
  width: 48%;
  /* Adjust button width */
  height: 38px;
  color: #616161;
  font-style: normal;
  font-size: 16px;
  border: 1px solid #616161;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}

.reservation_End .reservation_buttonb {
  background: var(--primary-green);
  color: #fefefe;
  border: none;
}

.reservation_End .reservation_buttonb:hover {
  background: var(--primary-green);
}

@media (max-width: 600px) {
  .reservation_End {
    flex-direction: column;
    align-items: center;
  }

  .reservation_End .reservation_buttona,
  .reservation_End .reservation_buttonb {
    width: 100%;
    margin-bottom: 10px;
  }
}


.css-bkpkvc-MuiButtonBase-root-MuiToggleButton-root {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  padding: 8px 16px !important;
}