.MuiTabs-indicator {
  background-color: #5d7202 !important;
}

.MuiButtonBase-root.MuiTab-root {
  color: #424242 !important;
  text-transform: none;
  font-family: "Urbanist", Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
}
 

.guest_sect-2 {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: white;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.css-1q1u3t4-MuiTableRow-root .check-in-text {
  color: var(--alert-status-success);
}

.css-1q1u3t4-MuiTableRow-root .check-out-text {
  color: var(--alert-status-error);
}

.propActivity-table .MuiTableCell-head {
  color: var(--Greyscale-900);
  background: var(--Greyscale-50);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
}

.MuiButtonBase-root.MuiTab-root:hover {
  color: #5d7202 !important;
  font-weight: bolder;
}

.containerGD {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.container-1 {
  display: flex;
  flex-direction: column;
  width: 65%;
  border-style: solid;
  border-width: 1px;
  border-color: #eeeeee;
  border-radius: 8px;
  padding-left: 1.5%;
  padding-top: 1.5%;
}

.container1 {
  display: flex;
  flex-direction: row;
}

.container1 .Logo {
  padding-top: 1%;
  width: 11%;
  cursor: pointer;
}

.guestdocument {
  display: flex;
  flex-direction: row;
  height: 40vh;
}

.guestdocument .guestdocument-container {
  padding-top: 1%;
  width: 100%;
  cursor: pointer;
  object-fit: contain;
}

.guestdocument .guestdocument-container img {
  width: 100%;
  height: 100%;
}

.container1 .subcontainer1 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  width: 31%;
  padding-top: 5px;
}

.container1 .subcontainer1 .JohnDoe {
  font-style: normal;
  font-size: 16px;
  color: #424242;
}

.container1 .subcontainer1 .Num1 {
  font-style: normal;
  font-size: 12px;
  color: #616161;
}

.container1 .subcontainer2 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  width: 52%;
  padding-top: 5px;
}

.container1 .subcontainer2 .JohnDoe {
  font-style: normal;
  font-size: 16px;
  color: #424242;
}

.container1 .subcontainer2 .Num1 {
  font-style: normal;
  font-size: 12px;
  color: #616161;
}

.container1 .Logo2 .MuiSvgIcon-root {
  color: #616161;
  cursor: pointer;
}

.container1 .Logo2 .MuiSvgIcon-root:hover {
  color: #444343;
  cursor: pointer;
}

/* ****** */
.container-1 .container2 {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.container-1 .container2 .subcontainer-1 {
  display: flex;
  flex-direction: row;
  width: 48%;
  row-gap: 10px;
}

.container-1 .container2 .subcontainer-1 .subcontainer-1-1 {
  width: 50%;
}
.container-1 .container2 .subcontainer-1 .subcontainer-1-1 p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 220%;
  color: #9e9e9e;
}

.container-1 .container2 .subcontainer-1 .subcontainer-1-2 {
  padding-top: 11.5%;
  justify-content: space-between;
}

.container-1 .container2 .subcontainer-1 .subcontainer-1-2 {
  width: 41%;
  text-align: right;
}
.container-1 .container2 .subcontainer-1 .subcontainer-1-2 p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 220%;
  color: #424242;
}

/* ****** */
.container-1 .container3 {
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  width: 100%;
  margin-left: 0%;
}

.container-1 .container3 .subcontainer-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container-1 .container3 .subcontainer-1 .Cancel {
  padding-right: 4%;
  text-align: right;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #ec0909;
  cursor: pointer;
}

.container-1 .container3 .subcontainer-1 .Cancel:hover {
  color: #f75555;
}

.container-1 .container3 .subcontainer-2 {
  display: flex;
  flex-direction: row;
}

.container-1 .container3 .subcontainer-2 .subcontainer-2-1 {
  display: flex;
  flex-direction: row;
}

/* ****** */
.container-1 .container3 .subcontainer-2 {
  display: flex;
  flex-direction: row;
  width: 48%;
  row-gap: 10px;
}

.container-1 .container3 .subcontainer-2 .subcontainer-1-1 {
  width: 50%;
}

.container-1 .container3 .subcontainer-2 .subcontainer-1-1 p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 220%;
  color: #9e9e9e;
  width: 20vw;
}

.container-1 .container3 .subcontainer-2 .subcontainer-1-2 {
  /* width: 100%; */
  width: 42%;
  justify-content: space-between;
}

.container-1 .container3 .subcontainer-2 .subcontainer-1-2 p {
  text-align: right;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 220%;
  color: #424242;
  width: 8vw;
}

/* .container-1 .container3 .subcontainer-3 .button1 {
    width: 43%;
    height: 6vh;
    color: #ffffffff;
    background: #FFC000;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}
.container-1 .container3 .subcontainer-3 .button2 {
    width: 43%;
    height: 6vh;
    color: #ffffffff;
    background: #6C7D02;
    border-radius: 8px;
    border: none;
    cursor: pointer;
} */
.container-1 .container3 .subcontainer-3 .button2:hover {
  background: #b4ba3d;
}

/* ****** */
.container2 {
  display: flex;
  flex-direction: column;
  width: 30%;
  padding-left: 2%;
}

.container2 .container-1 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  border-style: solid;
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-color: #eeeeee;
  border-radius: 8px;
  /* padding-left: 5%;
    padding-top: 5%; */
  padding: 5%;
  width: 105%;
}

.container2 .container-1 .box {
  display: flex;
  flex-direction: row;
}

.container2 .container-1 .box .box1 {
  background: #f5f5f5;
  border-radius: 8px;
  width: 70%;
  padding: 4%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 200%;
}

/* ****** */
.container2 .container-1 .sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container2 .container-1 .sub-container p {
  color: #5d7202;
  width: 66%;
  display: flex;
  justify-content: right;
  cursor: pointer;
  font-weight: bold;
}

.container2 .container-1 .sub-container p:hover {
  color: #374204;
}

.container2 .container-1 {
  color: #424242;
  line-height: 220%;
}

.container2 p {
  padding-right: 2%;
  font-size: 14px;
  line-height: 150%;
  color: #9e9e9e;
}

/* ****** */
.container2 .container-2 .sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f5f5f5;
  height: 3rem;
  width: 105%;
  padding-right: 5%;
  border-radius: 8px;
  align-items: center;
  align-content: center;
}

.container2 .container-2 .sub-container p1 {
  color: #000000;
  display: flex;
  justify-content: right;
  align-items: center;
}

.container2 .container-2 .sub-container img {
  width: 4vw;
  padding-left: 44%;
  align-items: center;
}

.container2 .container-2 .sub-container p {
  color: #246bfd;
  width: 66%;
  display: flex;
  justify-content: right;
  cursor: pointer;
  font-weight: bold;
}

.container2 .container-2 .sub-container p:hover {
  color: #1244a8;
}

.container2 .container-2 {
  color: #424242;
}

.button {
  display: flex;
  flex-direction: row;
  gap: 3%;
  padding-top: 2%;
}
.button .button1 {
  background: #ffc000;
  border-radius: 8px;
  border: none;
  width: 25%;
  height: 3vw;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}
.button .button2 {
  background: #6c7d02;
  border-radius: 8px;
  border: none;
  width: 25%;
  height: 3vw;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .containerGD {
    flex-direction: column;
    gap: 20px;
  }
  .container-1 {
    width: 100%;
  }
  .container-1 .container3 .subcontainer-2 .subcontainer-1-2 p {
    width: 12vw;
  }
  .container-1 .container3 .subcontainer-3 .button1 {
    height: 8vh;
  }
  .container-1 .container3 .subcontainer-3 .button2 {
    height: 8vh;
  }
  .container2 {
    flex-direction: row;
    width: 100%;
    padding: 0%;
    gap: 10px;
  }
  .container2 .container-2 .sub-container {
    display: none;
  }
  .container2 .container-1 .sub-container {
    height: max-content;
  }
  .container2 .container-1 .sub-container p {
    width: 56%;
  }
}
