/* BlogInfo.css */
.blog-info-container {
  margin-top: 6rem;

}

.blog-info-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  
}



.blog-info-heading {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.blog-info-image-container {
  margin-bottom: 20px;
}

.blog-info-img {
  width: 70%;
  height: auto;

}

.blog-info-text {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 20px;
  text-align: justify;
}


.blog-info-text  h1 {
  font-size: 1.5rem;
}
.blog-info-images {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

.blog-info-section1,
.blog-info-section2 {
  flex: 1;
}

.blog-info-section1 img,
.blog-info-section2 img {
  width: 150%;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.blog-info-section2 p {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  text-align: justify;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .blog-info-images {
    flex-direction: column;
  }
  .blog-info-section1,
  .blog-info-section2 {
    flex: unset;
  }
}

@media (max-width: 480px) {
  .blog-info-heading {
    font-size: 28px;
  }

  .blog-info-text {
    font-size: 14px;
    text-align: left;
  }
}



/* .container-blog {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: justify;

  .heading {
    font-size: 2rem;
    font-family: "Quicksand", sans-serif;
    font-weight: 1000;
    margin-top: 3rem;
    @media screen and (max-width: 991px) {
      margin-top: 2rem;
    }
    @media screen and (max-width: 500px) {
      margin-top: 15vh;
      font-size: 1.5rem;
    }
  }
  .para {
    margin-top: 5vh;
    font-family: "Nunito", sans-serif;
    text-align: justify;
  }

  img {
    margin-top: 20px;
    width: 60%;
  }
}

.container3 {
  width: 80%;
  display: flex;
  margin-left: 10%;
  padding-top: 5vh;
  padding-bottom: 5vh;
  @media screen and (max-width: 991px) {
    margin-left: 5%;
    display: flex;
    flex-direction: column;
  }
  .section1 {
    padding: 20px;
    width: 50%;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
  .section2 {
    width: 50%;
    @media screen and (max-width: 991px) {
      width: 110%;
    }

    .h1 {
      font-size: 1.5rem;
      font-weight: 1000;
      font-family: "Quicksand", sans-serif;
      text-align: justify;
      color: #6c7d02;
    }

    .para {
      font-size: 1rem;
      font-family: "Nunito", sans-serif;
      font-weight: 400;
      text-align: justify;
    }
  }
  .para {
    font-family: "Nunito", sans-serif;
  }
} */
