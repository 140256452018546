:root {
    --datagrid-header: 12px;
    --datagrid-body: 14px;
    --page-header: 32px;
    --addpopup-button: 16px;
    --export-button: 16px;
    --popupform-label: 16px;
    --popupform-header: 18px;
    --popupform-submit-cancel-button: 16px;
    --popupform-placeholder: 14px;
    --error:13px;
    --lagrger-font: 3rem;
}