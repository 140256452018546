.export_dept{
  display: flex;
}


  .department_DataGrid {
    padding-top: 3%;
  }
  .department_DataGrid .department_DataGrid {
    width: 100%;
    overflow-x: auto;
  }
  .department_DataGrid .tableHeaderCell {
    color: var(--Greyscale-900);
    background: var(--Greyscale-50);
    font-family: Urbanist;
    font-size: var(--datagrid-header);
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    cursor: pointer;
  }
  .department_DataGrid .tableBodyCell {
    color: var(--Greyscale-900);
    font-family: Urbanist;
    font-size: var(--datagrid-body);
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  .department_PopMain {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 40%;
    height: 100vh;
    background-color: var(--Greyscale-50);
    z-index: 1200;
    position: fixed;
    overflow-y: auto;
    top: 0;
    right: 0;
    padding-bottom: 2%;
    padding-top: unset;
    scrollbar-width: none; 
    scrollbar-color: transparent transparent; 
    -ms-overflow-style: none;
  }
  
  .ddepartment_PopMain::-webkit-scrollbar {
    width: 5px;
  }
  
  .department_PopMain::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  
  .department_First {
    display: flex;
    justify-content: space-between; 
    height: 8%;
    width: 100%;
    cursor: pointer;
    align-items: center;
    font-style: normal;
    font-size: 18px;
    padding-left: 3%;
    padding-right: 2%;
    margin-left: -3%;
    margin-bottom: 3%;
    background-color: #FFF;
  }
  
  .department_First p {
    height: 100%;
    padding-left: 6%;
    display: flex;
    font-family: "Urbanist ", Arial, sans-serif;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: var(--popupform-header);
    line-height: 150%;
    color: #424242;
  }
  
  .department_PopMain .department_Name {
    width: 95%;
    justify-content: center;
    padding-left: 3%;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
  
  .department_PopMain .department_Name input {
    width: 100%;
    align-items: center;
    padding: 10px 16px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    outline: none;
    background: var(--Greyscale-50);
    
  }
  .department_PopMain .department_Name textarea{
    width: 100%;
    align-items: center;
    padding: 10px 16px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    outline: none;
    background: var(--Greyscale-50);
  }
  input[type=time]::-webkit-datetime-edit-hour-field:focus,
  input[type=time]::-webkit-datetime-edit-minute-field:focus,
  input[type=time]::-webkit-datetime-edit-second-field:focus,
  input[type=time]::-webkit-datetime-edit-ampm-field:focus,
  input[type=time]::selection  {
    background-color: var(--primary-green);
  }
  .rmsc .dropdown-heading {
    height: 45px !important;
  }
  
  .department_PopMain .department_Name label sup {
    color: #dc3545;
  }


  .department_Togglebutton-1{
    width: 100%;
    padding-left: 5%;
    width: 50%;

  }


  .department_Togglebutton-1 .department_ToggleButtonGroups-1 button {
    color: #000000;
    border: none;
    background: #f5f5f5;
    width: 45%;
  }

  
  
  .department_Togglebutton-1
    .department_ToggleButtonGroups-1
    .Mui-selected {
    background-color: var(--primary-green);
    color: white;
  }
  
  .department_Togglebutton-1
    .department_ToggleButtonGroups-1
    button:hover {
    background-color: var(--primary-green);
    color: white;
  }

  .department_Togglebutton-1 .department_ToggleButtonGroups-1 {
    background-color: rgb(255, 255, 255);
    color: #000000;
    height: 100%;
    width: 100%;}
  
    .department_End {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding-top: 20px;
      width: 100%;
      padding: 5% 3%; 
  }


.department_End .department_buttona, .department_End .department_buttonb {
  width: 100%;
  height: 38px;
  color: #616161;
  font-style: normal;
  font-size: var(--popupform-submit-cancel-button);
  border: 1px solid #616161;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  border-radius: 8px;
}

.department_End .department_buttonb {
  background: var(--primary-green);
  color: #fefefe;
  border: none;
}

.department-label{
    color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%; 
  }
  .custom-field{
    background-color: var(--Greyscale-50);
  }
  .custom-field::placeholder{
  color: var(--Greyscale-500);
  font-family: Urbanist;
  font-size: var(--popupform-placeholder);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  }
  .department_form{
    padding-left: 2.5%;
  }

  @media (max-width: 1024px) {
    .department_PopMain {
      width: 45%;
    }
  }
  @media (max-width: 768px) {
    .department_PopMain {
      width: 50%;
    }

    .department_Togglebutton-1{
      width: 55%;
    }
  }