@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap");


.main {
  display: flex;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .rightcat {
    padding-left: 2%;
    margin-top: 10vh;
    width: 20%;
    @media screen and (max-width: 991px) {
      width: 80%;
      margin-top: 15vh;
      margin-left: 10%;
      padding-left: 0;
    }
  }

  .recent {
    margin-top: 10vh;
    .recentheading {
      margin-bottom: 10px;
      font-size: 1.5rem;
      font-family: "Quicksand", sans-serif;
      font-weight: 1000;
      color: #6c7d02;
    }
    .mainrecent {
      width: 100%;
      border: solid 1px #cfd891;
      padding: 5px 5px;
      border-radius: 8px;
      margin-top: 5px;
    }
    a {
      text-decoration: none;
      color: black;
    }
    .title {
      font-size: 1rem;
      font-family: "Urbanist", sans-serif;

      font-weight: 1000;
    }
    .date {
      font-size: 0.8rem;
      margin-top: 1px;
      color: grey;
    }
  }
}

.blogs {
  margin-top: 5vh;
  display: grid;
  grid-template-columns: auto auto auto;
  width: 75%;
  column-gap: 1%;
  @media screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: auto;
  }

  @media (min-width: 601px) and (max-width: 991px) {
    display: grid;
    grid-template-columns: auto auto;
  }

  .container {
    margin-top: 2vh;
    width: 24vw;

    border-radius: 12px;
    margin-left: 10%;
    padding: 20px 20px;

    @media screen and (max-width: 600px) {
      width: 86vw;
    }

    @media (min-width: 600px) and (max-width: 991px) {
      width: 46vw;
    }

    .section1 {
      width: 100%;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
    .section2 {
      width: 100%;
      @media screen and (max-width: 991px) {
        width: 100%;
      }

      a {
        text-decoration: none;
      }

      .h1 {
        font-size: 1.2rem;
        font-weight: 100;
        color: black;
        font-family: "Quicksand", sans-serif; 
      }

      .para {
        font-size: 1rem;
        font-family: "Nunito", sans-serif;
        font-weight: 400;
      }
    }
  }
}

.categorySelector {
  margin-bottom: 20px;
  width: 100%;

  h2 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-family: "Quicksand", sans-serif;
    font-weight: 1000;
    color: #6c7d02;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    column-gap: 5px;
    justify-content: space-evenly;
  }

  li {
    font-size: 16px;
    padding: 11px 0;
    cursor: pointer;
    border-radius: 6px;
    padding-left: 15px;
    margin-top: 5px;
    font-family: "Urbanist", sans-serif;

    &:hover {
      background-color: #6c7d02;
      color: white;
      transition: all ease-in 300ms;
    }

    &.selected {
      background-color: #6c7d02;
      color: white;
    }
  }
}

.pagination {
  display: flex;
  justify-content: right;
  margin-right: 10%;
  align-items: center;
  margin-top: 10vh;

  button {
    margin: 0 5px;
    padding: 8px 12px;
    background-color: #f2f2f2;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      color: #f2f2f2;
      background: #6c7d02;
      transition: all ease 500ms;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &.active {
      background-color: #6c7d02;
      color: #fff;
    }
  }
}
