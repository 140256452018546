.HealthMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  padding: 0;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px; 
}

.HealthMain .Health1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding-left: 2%;
  position: relative;
}

.Health1 .Heading {
  font-family: "Urbanist", Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
}

.Health1 h2 {
  font-size: 24px;
  font-weight: 700;
  color: black;
  line-height: 150%;
  text-align: center;
}
.Health1 .Description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #616161;
}

/* Height start */
.Health1 .Height {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  text-align: left;
  gap: 5px;
  margin-top: 25px;
}

.Health1 .Height label,
.Health1 .Weight label,
.Health1 .blood-drop label,
.Health1 .MaleFemale label,
.Health1 .Appetite label,
.Health1 .Bowel label,
.Health1 .Urination label,
.Health1 .Menstruation label,
.Health1 .Sleep label,
.Health1 .Walk label,
.Health1 .Skin label,
.Health1 .MentalStatus label,
.Health1 .FoodAllergy label {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  width: 100%;
}

.Health1 .Height select {
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
  width: 30%;
}

.Health1 .Height input {
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 90%;
}

.Health1 .Height input {
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 70%;
}

.Health1 .Height .error {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.Health1 .blood-drop {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-content: center;
  gap: 10px;
  width: 70%;
  margin-top: 20px;
}

.Health1 .MaleFemale,
.Health1 .Appetite,
.Health1 .Bowel,
.Health1 .Urination,
.Health1 .Menstruation,
.Health1 .Sleep,
.Health1 .Walk,
.Health1 .Skin,
.Health1 .MentalStatus {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-content: center;
  gap: 10px;
  margin: 20px 0px 0px 0px;
  width: 70%;
}

.Health1 .PresentClinicalComplaint,
.Health1 .PastClinicalillness,
.Health1 .Medications,
.Health1 .FamilyHistory,
.Health1 .Investigations,
.Health1 .ProvisionalDiagnosis,
.Health1 .FoodAllergy {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  margin: 20px 0px 0px 0px;
  float: right;
  justify-content: right;
}

.Health1 .helath_history_master_button {
  background: #6c7d02;
  border-radius: 8px;
  border: none;
  width: 15%;
  height: 3vw;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  margin: 10px 0px;
  /* margin-left: 970px; */
}
.Health1 .healthhistory_select_main {
  margin-top: 3%;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.Health1 .healthhistory_input_box {
  margin-top: 5%;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

/* ******************************************* Health 2 ********************************************* */

.HealthMain .Health2 {
  display: flex;
  flex-direction: column;
  width: 30%;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding-left: 2%;
  padding-top: 1%;
  line-height: 250%;
}

.Health2 p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #616161;
}

.Health2 input {
  border: 1px solid #9e9e9e;
  border-radius: 8px;
  width: 90%;
  height: 42px;
  padding-left: 5%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  outline: none;
}

.Select {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.Select .Condition {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
}

.Select .Condition button {
  border: none;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
}

.Select .Condition button.minus {
  border: 1px solid black;
}

/* Button css */
.button-group {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  gap: 25px;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px;
}

.toggle-button {
  flex: 1;
  padding: 10px 0;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.toggle-button.active {
  background-color: #6c7d02;
  color: white;
  font-weight: bold;
}

.toggle-button:hover {
  background-color: #ddd;
}

.toggle-button.active:hover {
  background-color: #6d7d02f1;
}

/* ******************************************* Health 3 ********************************************* */
/*  */

.HealthMain .Health3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding-left: 2%;
  margin: 0 auto;
}

.Health3 .AllergyHistory {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding-left: 5%;
  padding-top: 5%;
}

.AllergyHistory .button {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 90%;
}

.AllergyHistory .button button {
  border: none;
  height: 6vh;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #424242;
  border-radius: 4px;
}

.AllergyHistory .button button:hover {
  background-color: #d2d613;
  cursor: pointer;
}
.selectable {
  background-color: transparent;
}

.selectede {
  background-color: #d2d613;
}
.AllergyHistory .togglebutton {
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 16px;
}

.AllergyHistory .togglebutton p input {
  border: 1px solid #a7acb1;
  border-radius: 4px;
}

.Health3 .HabitHistory {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding-left: 5%;
  padding-top: 5%;
}

.HabitHistory P {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.condit {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 15px;
}

@media screen and (max-width: 800px) {
  .HealthMain .Health1 {
    width: 100%;
  }
  .HealthMain .Health2 {
    width: 100%;
  }

  .image-container p {
    top: 53%;
    left: 39%;
  }
}

.health_history_upadated_btn {
  background: #6c7d02;
  border-radius: 8px;
  border: none;
  width: 25%;
  height: 3vw;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*  */

.Health3 h2 {
  font-size: 24px;
  font-weight: 700;
  color: black;
  line-height: 150%;
  text-align: center;
}
.Health3 .daily_normal_details {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  width: 100%;
  justify-content: space-between;
}

.Health3 .daily_normal_details .Weight {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  text-align: left;
  gap: 5px;
  margin: 20px 0px 20px 0px;
}

.Health3 .daily_normal_details .Weight label {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.Health3 .daily_normal_details .Weight select {
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
  width: 25%;
}

.Health3 .daily_normal_details .Weight input {
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 55%;
}

.Health3 .daily_normal_details .Weight .error {
  font-size: 13.5px;
  width: 100%;
}

.Health3 .daily_body_chechup {
  width: 100%;
  margin-top: 5%;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.Health3 .daily_body_chechup .pulse_rate,
.Health3 .daily_body_chechup .bloodPressure,
.Health3 .daily_body_chechup .Respiratory_Rate,
.Health3 .daily_body_chechup .FBS,
.Health3 .daily_body_chechup .ppbs,
.Health3 .daily_body_chechup .RBS {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}
.Health3 .daily_body_chechup .pulse_rate label,
.Health3 .daily_body_chechup .bloodPressure label,
.Health3 .daily_body_chechup .Respiratory_Rate label,
.Health3 .daily_body_chechup .FBS label,
.Health3 .daily_body_chechup .ppbs label,
.Health3 .daily_body_chechup .RBS label {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  width: 25%;
}
.Health3 .daily_body_chechup .pulse_rate input,
.Health3 .daily_body_chechup .bloodPressure input,
.Health3 .daily_body_chechup .Respiratory_Rate input,
.Health3 .daily_body_chechup .FBS input,
.Health3 .daily_body_chechup .ppbs input,
.Health3 .daily_body_chechup .RBS input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 45%;
}
.Health3 .daily_normal_details_update {
  background: #6c7d02;
  border-radius: 8px;
  border: none;
  width: 15%;
  height: 3vw;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  float: right;
  justify-content: end;
  align-items: end;
  float: right;
  right: 0%;
  margin: 10px 0px;
  margin-bottom: 5%;
  align-items: end;
  /* margin-left: 75%; */
}

.Health3 .health-pagination-selected {
  color: var(--Secondary-500) !important;
  background-color: white !important;
}

.Health3 .health-pagination-selected::before {
  content: "";
  position: absolute;
  border: 1px solid red;
  bottom: 0;
  width: 45%;
  height: 2px;
  border-radius: 50px;
  background-color: var(--Secondary-500);
}

@media (max-width: 768px) {
  .button-group {
    width: 75%;
    gap: 20px;
    justify-content: space-around;
    margin: 0 auto;
    margin-top: 5px;
  }
  .Health3 .data_row1-1 {
    margin-left: 60px;
  }
  .Health3 .daily_body_chechup .pulse_rate label,
  .Health3 .daily_body_chechup .bloodPressure label,
  .Health3 .daily_body_chechup .Respiratory_Rate label,
  .Health3 .daily_body_chechup .FBS label,
  .Health3 .daily_body_chechup .ppbs label,
  .Health3 .daily_body_chechup .RBS label {
    font-size: 12px;
    font-weight: 600;
    width: 30%;
  }
  .Health3 .daily_body_chechup .pulse_rate input,
  .Health3 .daily_body_chechup .bloodPressure input,
  .Health3 .daily_body_chechup .Respiratory_Rate input,
  .Health3 .daily_body_chechup .FBS input,
  .Health3 .daily_body_chechup .ppbs input,
  .Health3 .daily_body_chechup .RBS input {
    padding: 5px;
    font-size: 13px;
    width: 40%;
  }
  .Health3 .table-row-heading{
    font-size: 1px;
  }
}
