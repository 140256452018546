/* Profile Card Container */
.profile-card {
    width: 40%;
    max-width: 400px;
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease-in-out;
    justify-content: space-between;
  }
  
  /* Hover Effect */
  .profile-card:hover {
    transform: scale(1.05);
  }
  
  /* Header */
  .profile-header h2 {
    color: #333;
    font-size: 22px;
    margin-bottom: 5px;
  }
  
  .profile-header p {
    color: #666;
    font-size: 14px;
  }
  
  /* Progress Bar */
  .progress-bar-container {
    width: 100%;
    background: #e0e0e0;
    border-radius: 50px;
    height: 10px;
    margin: 15px 0;
    position: relative;
    overflow: hidden;
  }
  
  .progress-bar-Profile {
    height: 100%;
    background: #6c7d02;
    border-radius: 50px;
    transition: width 0.5s ease-in-out;
  }
  
  /* Profile Completion Percentage */
  .profile-percentage {
    font-size: 14px;
    color: #444;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  /* Edit Profile Button */
  .edit-profile-btn {
    background: #6c7d02;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }
  
  .edit-profile-btn:hover {
    background: var(--secondary-green);
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .profile-card {
      max-width: 90%;
      padding: 15px;
    }
  }
  