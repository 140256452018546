.propActivity_MainPae {
  width: 95%;
  height: 100%;
  text-align: center;
  justify-content: center;
  padding-top: 1%;
  z-index: 1;
  margin: 0 auto;
}
.css-1ckzs39 {
  position: absolute;
  z-index: 1;
}
.css-1fdsijx-ValueContainer {
  padding: 6px 8px!important;
}
.propActivity_sect-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 1%;
  justify-content: space-between
}
.export_location{
  display: flex;
}

.propActivity_manage {
  width: 45%;
}
/* .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: none;
} */
.css-wop1k0-MuiProperty_Location_DataGrid-footerContainer {
  display: none !important;
}
.css-wjh20t-MuiPagination-ul {
  display: flex !important;
  /* justify-content: flex-end !important; */
}
/* nav li {
  margin: 0px !important;
} */
.pagination-selected {
  color: var(--Secondary-500) !important;
  background-color: white !important;
}
.pagination-selected::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 28%;
  width: 45%;
  height: 2px;
  border-radius: 50px;
  background-color: var(--Secondary-500);
}

.Table {
  position: relative;
  padding: 0%;
  margin-bottom: 10%;
}

.propActivity_toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
}
.ToggleButtonGroups button.Mui-selected {
  background-color: var(--primary-green);
  color: white;
  font-weight: 600;
}
.ToggleButtonGroups button:hover {
  background-color: var(--primary-green);
  color: white;
}
.ToggleButtonGroups-root {
  width: 30px;
  height: 32px;
}
.ToggleButtonGroups {
  background-color: rgb(255, 255, 255);
}
.Property_Location_DataGrid {
  padding-top: 3%;
}
.Property_Location_DataGrid .propActivity-table {
  width: 100%;
  overflow-x: auto;
}
.Property_Location_DataGrid .tableHeaderCell {
  color: var(--Greyscale-900);
  background: var(--Greyscale-50);
  font-family: Urbanist;
  font-size: var(--datagrid-header);
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
}
.Property_Location_DataGrid .tableBodyCell {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--datagrid-body);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.css-1ex1afd-MuiTableCell-root {
  padding: 1rem 1rem !important;
}
.css-1sl31xx {
  display: flex;
  align-items: center;
}
.arrow-icon {
  visibility: hidden;
}
.tableHeaderCell:hover .arrow-icon {
  visibility: visible;
}
.property-details-container {
  display: flex;
  height: fit-content;
  align-items: center;
  margin-top: 20px;
}
.property-details-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 10%;
}
.property-detail-item {
  flex: 0 0 28.33%;
  max-width: 33.33%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.property-detail-label {
  margin-bottom: 5px;
  font-weight: 600;
  font-style: normal;
}
.property-detail-input {
  width: 60%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.property-edit-button-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.property-details-button {
  background-color: var(--primary-green);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.css-t3ipsp-control{
  box-shadow: unset !important;
  border-color: #cccccc !important;

}
.css-13cymwt-control:hover {
  background: var(--Greyscale-50)!important;
  border-color: #cccccc !important;
}
.field-container {
  position: relative;
}

.propActivity_PopMain {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 80%;
  height: 100vh;
  background-color: var(--Greyscale-50);
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  padding-bottom: 2%;
  padding-top: unset;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; 
  -ms-overflow-style: none;
}
.propActivity_PopMain::-webkit-scrollbar {
  width: 5px;
}
.propActivity_PopMain::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.Toast {
  line-height: 1px;
}
.Toast h5 {
  color: rgb(255, 255, 255);
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.4px;
}
.error {
  color: #f75555;
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 1px;
  padding-bottom: 1%;
}

.propActivity_First {
  display: flex;
  justify-content: space-between; 
  height: 8%;
  width: 109%;
  cursor: pointer;
  align-items: center;
  font-style: normal;
  font-size: 18px;
  padding-left: 3%;
  padding-right: 2%;
  margin-left: -3%;
  background-color: #FFF;
}

.propActivity_First p {
  height: 100%;
  padding-left: 3%;
  display: flex;
  font-family: "Urbanist ", Arial, sans-serif;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: var(--popupform-header);
  line-height: 150%;
  color: #424242;
}
.property-form{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 3%;
  padding-right: 1%;

}
.propActivity_PopMain .propActivity_Name {
  width: 50%;
  padding-left: 3%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.propActivity_PopMain .propActivity_Name input {
  width: 100%;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  outline: none;
  background: var(--Greyscale-50);
}
.address_fields{
  width: 97%;
  justify-content: center;
  padding-left: 3%;
  font-family: "Urbanist ", Arial, sans-serif;
}
.address_fields input{
  width: 100%;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  outline: none;
  background: var(--Greyscale-50);
}
.address_container{
  padding-left: 3%;
  width: 50%;
  padding-block: 1%;
}
.address_heading{
  color: var(--Greyscale-900);
  font-weight: 600;
  padding-bottom: 1%;
}
.address_inner_container{
  border-radius: 8px;
  border :1px solid #cccccc;
  padding-block: 5%;
}

.propActivity_PopMain .propActivity_Name textarea{
  width: 100%;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  outline: none;
  background: var(--Greyscale-50);
  height: 40%;
  
}
input[type=time]::-webkit-datetime-edit-hour-field:focus,
input[type=time]::-webkit-datetime-edit-minute-field:focus,
input[type=time]::-webkit-datetime-edit-second-field:focus,
input[type=time]::-webkit-datetime-edit-ampm-field:focus,
input[type=time]::selection  {
  background-color: var(--primary-green);
}
.rmsc .dropdown-heading {
  height: 45px !important;
}

.propActivity_PopMain .propActivity_Name label sup {
  color: #dc3545;
}

.propActivity_PopMain .Text {
  width: 100%;
  justify-content: center;
  padding-left: 5%;
  line-height: 34px;
}

.propActivity_PopMain .Text input {
  width: 95%;
  height: 100px;
  padding: 8px 16px;
  border: 1px solid #899197;
  border-radius: 8px;
  outline: none;
  font-family: "Urbanist ", Arial, sans-serif;
}

.propActivity_Togglebutton-1 {
  padding-right: 80%;
}
.propActivity_ProductDropdown {
  width: 50%;
  padding-left: 5%;
}
.propActivity_ProductDropdown_Add {
  width: 10%;
  padding-left: 2%;
}

.dropdown {
  padding-left: 5%;
  position: relative;
  display: inline-block;
  width: 100%;
}
.dropbtn {
  background-color: #ffffff;
  color: #000000;
  padding: 10px 16px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  transition: transform 0.3s ease; 
}
.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1;
  width: 100%;
}
.dropdown-content a {
  display: block;
  padding: 10px 16px;
  color: #000000;
  text-decoration: none;
}
.dropdown-content a:hover {
  color: #ffffff;
}
.dropdown-content .clicked {
  display: none;
}
.show {
  display: block;
  cursor: pointer;
}
.data:hover {
  background-color: var(--primary-green);
  transition: 10ms;
  transform: scaleY(1);
  border-radius: 2px;
  margin-top: 0.5%;
}

.propActivity_Togglebutton-1 .propActivity_ToggleButtonGroups-1 button {
  color: #000000;
  border: none;
  background: #f5f5f5;
}

.propActivity_Togglebutton-1 .propActivity_ToggleButtonGroups-1 .Mui-selected {
  background-color: var(--primary-green);
  color: white;
}

.propActivity_Togglebutton-1 .propActivity_ToggleButtonGroups-1 button:hover {
  background-color: var(--primary-green);
  color: white;
}

.propActivity_Togglebutton-1 .propActivity_ToggleButtonGroups-1-root {
  width: 30px;
  height: 32px;
}

.propActivity_Togglebutton-1 .propActivity_ToggleButtonGroups-1 {
  background-color: rgb(255, 255, 255);
  color: #000000;
}

.propActivity_End {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 20px;
  width: 100%;
}

.propActivity_End .propActivity_buttona,
.propActivity_End .propActivity_buttonb {
  width: 47%;
  height: 38px;
  color: #616161;
  font-style: normal;
  font-size: var(--popupform-submit-cancel-button);
  border: 1px solid #616161;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}

.propActivity_End .propActivity_buttonb {
  background: var(--primary-green);
  color: #fefefe;
  border: none;
}

.propActivity_End .propActivity_buttonb:hover {
  background: #b4ba3d;
}
.Property-label{
  color: var(--Greyscale-900);
font-family: Urbanist;
font-size: var(--popupform-label);
font-style: normal;
font-weight: 600;
line-height: 150%; 
}
.custom-field{
  background-color: var(--Greyscale-50);
}
.custom-field::placeholder{
color: var(--Greyscale-500);
font-family: Urbanist;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 150%;
}
.custom-field_Address{
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
  outline: 0;
  background: var(--Greyscale-50);
}
.Currency-dropdown .css-1gytqch-ValueContainer {
  padding: 6px !important;

}
.Currency-dropdown .css-13cymwt-control {
  background: var(--Greyscale-50) !important;
}
/* timezone and Currency dropdown background */
.css-1fdsijx-ValueContainer::placeholder{
  background-color: var(--Greyscale-50);

}
.css-1hb7zxy-IndicatorsContainer::placeholder {
  background-color: var(--Greyscale-50);
}

@media (max-width: 1024px) {
 
  .property-form{
    padding-right: 2.5%;
  }
  .propActivity_export {
    width: 40%;
    margin-right: 1%;
  }
  .AddAmenity,
  .MainPae {
    position: relative;
  }


  .propActivity_toggle {
    width: 100%;
  }

  .Property_Location_DataGrid {
    padding-top: 2%; /* Adjust padding */
  }

  .property-details-form {
    justify-content: space-around !important;
  }
  .property-detail-input {
    min-width: 95%;
  }
}
@media (max-width: 768px) {
  .propActivity_MainPae {
    margin: 0;
    width: 100%;
  }
  .propActivity_PopMain{
    width: 90%;
  }
  .property-form{
    padding-right: 2.5%;
  }

  .propActivity_export {
    width: 30%;
    margin-right: 1%;
  }
  .propActivity_manage {
    width: 40%;
  }
  
  .propActivity_sect-1 .button_export {
    padding: 10px 12px; /* Adjusted padding */
    font-size: 14px; /* Adjusted font size */
  }
  .toggle {
    width: 16%;
  }
  .b {
    width: 64%;
  }
  .propActivity_manage {
    width: 60%;
  }
  .property-detail-item {
    flex: 0 0 39.33%;
    max-width: unset;
  }
}
@media (max-width: 600px) {
  .propActivity_End {
    flex-direction: column;
    align-items: center;
  }

  .propActivity_End .propActivity_buttona,
  .propActivity_End .propActivity_buttonb {
    width: 100%;
    margin-bottom: 10px;
  }
}
