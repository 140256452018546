.export_property{
  display: flex;
}


.propertyMaster_main_component {
  width: 95%;
  height: 100%;
  text-align: center;
  justify-content: center;
  padding-top: 1%;
  z-index: 1;
  margin: 0 auto;
}
.propertyMaster_main_sec_1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.propertyMaster_main_heading {
  width: 46%;
}

.property_search_main_container {
  position: relative;
  padding: 0%;
  margin-bottom: 10%;
}
.property_search_container-wrap {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: white;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;
  justify-content: space-between;
}
.property_search {
  position: relative;
  display: flex;
  font-weight: 600;
}
.property_data_grid {
  padding-top: 3%;
}
.property_data_grid .property_data_grid {
  width: 100%;
  overflow-x: auto;
}
.property_data_grid .propertytableHeaderCell {
  color: var(--Greyscale-900);
  background: var(--Greyscale-50);
  font-family: Urbanist;
  font-size: var(--datagrid-header);
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.property_data_grid .propertytableBodyCell{
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--datagrid-body);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.property_popup {
  width: 40%;
  height: 100vh;
  background-color: var(--Greyscale-50);
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  padding-bottom: 2%;
  padding-top: unset;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
  -ms-overflow-style: none;
}
.property_popup::-webkit-scrollbar {
  width: 5px;
}
.property_popup::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.property_popup_first {
  display: flex;
  justify-content: space-between;
  height: 8%;
  width: 103%;
  cursor: pointer;
  align-items: center;
  font-style: normal;
  font-size: 18px;
  padding-left: 4%;
  padding-right: 6%;
  margin-left: -3%;
  background-color: #fff;
}
.property_popup_first p {
  height: 100%;
  padding-left: 3%;
  display: flex;
  font-family: "Urbanist ", Arial, sans-serif;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: var(--popupform-header);
  line-height: 150%;
  color: #424242;
}
.property_popup .property_Field_container {
    width: 100%;
    justify-content: center;
    padding-left: 4%;
    padding-right: 4%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.property_popup .property_Field_container input {
  width: 100%;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  outline: none;
  background: var(--Greyscale-50);
}
.property_popup .property_Field_containerlabel sup {
  color: #dc3545;
}
.property_button_container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
 
  padding-top: 3%;
  width: 100%;
}
.property_button_container .property_buttonB,
.property_button_container .property_buttonA {
  width: 15.25rem;
  height: 38px;
  color: #616161;
  font-style: normal;
  font-size: var(--popupform-submit-cancel-button);
  border: 1px solid #616161;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}
.property_button_container .property_buttonB {
  background: var(--primary-green);
  color: #fefefe;
  border: none;
}
.Property_field_label {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%; 
}
.property_field {
  background-color: var(--Greyscale-50);
}
.property_field::placeholder {
  color: var(--Greyscale-500);
  font-family: Urbanist;
  font-size: var(--popupform-placeholder);
  font-style: normal;
  font-weight: 500;
  line-height: 150%; 
}
.property_button_container .property_buttonB:hover {
  background: var(--primary-green);
}
.custom_Toast{
  line-height: 1rem;
  width: 103%;
}
.custom_Toast h5{
color: var(--default-white);
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 150%;
}
.property-field-error {
  color: #f75555;
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 1px;
  margin-top: 4px;
  margin-bottom: 4px;
}

@media (max-width: 1024px) {
  
  .property_button_container .property_buttonB, .property_button_container .property_buttonA{
    width: 12rem;
  }
}
@media (max-width: 768px) {
  .propertyMaster_main_component {
    margin: 0;
    width: 100%;
  }
  .property_popup {
    width: 45%;
  }
  .property_button_container .property_buttonB, .property_button_container .property_buttonA{
    width: 10rem;
  }

}
