/* General Layout */
.web_nav {
    border-bottom: 1px solid #E2E8F0;
    background-color: #ffffff;
}

.web_navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Logo Styles */
.web_nav_logo_img {
    max-width: 150px;
    margin-left: 30px;
}

.web_nav_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.web_nav_item {
    margin-left: 3rem;
}

.web_nav_link {
    font-size: 1.3rem;
    font-weight: 400;
    color: var(--primary-green);
}

.web_nav_link:hover {
    color: var(--secondary-green);
}
/* Active Link Styling */
.web_nav_link.active {
    color: var(--primary-green);
    font-weight: bold;
}




/* Button Styles */
.web_nav_button {
    padding: 10px 20px;
    text-align: center;
    font-size: 14px;
    border: 2px solid var(--secondary-green);
    border-radius: 50px;
    font-weight: 900;
    cursor: pointer;
    background: transparent;
    color: var(--secondary-green);
    transition: background-color 0.3s ease-in-out;
}

.web_nav_button:hover {
    background-color: #4A5B00;
}

/* Right-side Button Group */
.web_nav_right {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

/* Hamburger Menu for Mobile */
.web_hamburger {
    display: none;
    cursor: pointer;
    z-index: 1001;
}

.web_bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: #000000;
    transition: all 0.3s ease-in-out;
}

/* Mobile Menu Styles */
.web_mobile_menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    z-index: 1000;
    padding: 1rem;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
}

.web_mobile_menu.active {
    transform: translateY(0);
}

/* Mobile Header (Logo + Close Button in One Row) */
.web_mobile_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    z-index: 1001;
}

.web_nav_logo_img_mobile {
    max-width: 120px;
}

.web_close_button {
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    color: #000000;
    background: none;
    border: none;
}

/* Mobile Navigation Links */
.web_mobile_nav {
    list-style: none;
    padding: 4rem 0 0;
    margin: 0;
    text-align: center;
}

.web_mobile_nav li {
    margin: 1rem 0;
}

.web_mobile_nav a {
    font-size: 1.3rem;
    font-weight: 500;
    color: #333;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
    .web_hamburger {
        display: block;
    }

    .web_nav_menu,
    .web_nav_right {
        display: none;
    }

    .web_hamburger.active .web_bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .web_hamburger.active .web_bar:nth-child(2) {
        opacity: 0;
    }

    .web_hamburger.active .web_bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .web_nav_logo_img {
        max-width: 150px;
        margin-left: 0px;
    }
}
