/* .export_container {
  display: flex;
  justify-content: flex-end;
  width: 42%;
} */
.button_export {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: var(--primary-green);
  width: 176px;
  color: var(--primary-green);
  font-style: normal;
  font-size: var(--export-button);
  padding: 10px 16px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 2%;
}
@media (max-width: 1024px) {
  .export_container {
    width: 40%;
    margin-right: 1%;
  }
}
@media (max-width: 768px) {
  .export_container {
    width: 30%;
    margin-right: 1%;
  }
  .employeemaster_sect-1 .button_export {
    padding: 10px 12px; /* Adjusted padding */
    font-size: 14px; /* Adjusted font size */
  }
}
