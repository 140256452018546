.booking_PopMain {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; */
  width: 75%;
  /* max-width: 50%; */
  height: 100vh;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  background: var(--Greyscale-50);
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  padding: 20px;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
  -ms-overflow-style: none;
}

.booking_PopMain::-webkit-scrollbar {
  width: 5px;
}

.booking_PopMain::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.booking_PopMain .inputBox {
  width: 50%;
  justify-content: center;
  padding-left: 5%;
  /* line-height: 34px; */
  font-family: "Urbanist ", Arial, sans-serif;
}
.booking_PopMain .inputBox input,
.currency-select {
  width: 100%;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  outline: none;
  /* height: 75%; */
}
.rmsc .dropdown-heading {
  height: 45px !important;
}

.booking_PopMain .inputBox label sup {
  color: #dc3545;
}

.error {
  color: #f75555;
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 1px;
}

.booking_First {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-around; /* Align X button to the right */
  height: 8%;
  width: 100%;
  /* box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.03); */
  cursor: pointer;
  align-items: center;
  font-style: normal;
  font-size: 18px;
  background-color: var(--default-white);
}

.booking_First p {
  width: 80%;
  height: 100%;
  /* padding-top: 5%; */
  display: flex;
  font-family: "Urbanist ", Arial, sans-serif;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #424242;
}
.booking_mainContainer1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.data_row1 {
  display: flex;
  gap: 0.5rem;
}

.data_row1-1 {
  width: 50%;
}
.data_row1-1 .data_label {
  font-weight: bold;
  margin-top: 1rem;
}

.custom-text-color {
  color: #f75555;
}
.mt-2 {
  margin-top: 0.5rem;
}
.custom-style {
  font-weight: bold;
  margin-top: 1rem;
}
.chooseRooms {
  display: flex;
  /* justify-content: space-between; */
}
.booking_mainContainer2 {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  width: 100%;
  gap: 1.5rem;
}
.custom-flex-baseline {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.custom-font {
  font-family: "YourFontFamily", sans-serif; /* Replace 'YourFontFamily' with the actual font family name */
  font-weight: bold;
}
.Bookingdiv1 {
  display: flex;
  gap: 1rem;
}
.btn_book {
  background-color: #5d7202;
  border: 1px solid; /* You might want to specify the border color and width explicitly */
  border-radius: 0.5rem;
  color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.btn_cancel {
  background-color: red;
  border: 1px solid; /* You might want to specify the border color and width explicitly */
  border-radius: 0.5rem;
  color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.booking_mainContainer1_1 {
  padding: 2%;
  width: 58%;
  background-color: var(--default-white);
  /* box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.03); */
}
.booking_mainContainer1_2 {
  padding: 2%;
  width: 38%;
  background-color: var(--default-white);
  /* box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.03); */
}

.booking_End {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* Ensure buttons are spaced evenly */
  padding-top: 20px;
  /* Adjust as needed */
  width: 100%;
}

.booking_End .next_btn,
.booking_End .cancel_btn {
  width: 25%;
  /* Adjust button width */
  height: 38px;
  color: #616161;
  font-style: normal;
  font-size: 16px;
  border: 1px solid #616161;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}

.booking_End .next_btn {
  background: var(--primary-green);
  color: #fefefe;
  border: none;
}

.booking_End .next_btn:hover {
  background: var(--primary-green);
}

.form_container {
  display: flex;
  gap: 1rem;
  width: 100%;
}
.form_container2 {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 50%;
}
.formLabel {
  font-weight: 600;
}
.formInput {
  /* Border */
  border-width: 1px;
  border-style: solid;
  border-color: #9e9c9c;
  /* Rounded corners */
  border-radius: 8px;

  /* Padding */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  /* Width and height */
  height: 2.75rem;
  width: 100%;
  outline: none;
}
.rmv_btn {
  background: var(--primary-green);
  color: #fefefe;
  border: none;
  width: 100px;
}
.form_field_container.rmdp-container {
  height: 44px !important;
}
.form_field_container .rmdp-input {
  height: 43px;
  border-radius: 8px;
  border: 1px solid #cccccc;
}
.flex {
  display: flex;
}

.rmv_btn:hover {
  background: var(--primary-green);
}
.mobile_input {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px 16px;
  outline: none;
}
.mobile_input::placeholder {
  color: var(--Greyscale-500);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.booking_error {
  color: #f75555;
  font-family: "Urbanist ", Arial, sans-serif;
  font-style: normal;
  font-size: 12px;
  margin-top: 3%;
  /* letter-spacing: 1px;*/
}
.details_title {
  font-weight: 700;
  font-size: 16px;
}
.info_container {
  display: flex;
  justify-content: space-between;
}
.info_container_data {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.info_heading {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--Greyscale-700);
}
.info_data {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.coupon_field {
  margin-top: 0.5rem;
  border: 1px solid var(--Greyscale-500);
  padding: 0.5rem;
  border-radius: 8px;
  width: 100%;
}
.field_container .css-1gytqch-ValueContainer {
  padding-left: 5% !important;
}
.booking_dropdown .css-1fdsijx-ValueContainer {
  padding: 5px 8px !important;
}
