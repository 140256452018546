.export_Treatments{
    display: flex;
  }
    
    .Treatments_data_grid {
      padding-top: 3%;
    }
    .Treatments_data_grid .Treatments_data_grid {
      width: 100%;
      overflow-x: auto;
    }
    .Treatments_data_grid .TreatmentstableHeaderCell {
      color: var(--Greyscale-900);
      background: var(--Greyscale-50);
      font-family: Urbanist;
      font-size: var(--datagrid-header);
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      cursor: pointer;
    }
    .Treatments_data_grid .tableBodyCell {
      color: var(--Greyscale-900);
      font-family: Urbanist;
      font-size: var(--datagrid-body);
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  
    .Treatments_PopMain {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      width: 40%;
      height: 100vh;
      background-color: var(--Greyscale-50);
      z-index: 1200;
      position: fixed;
      overflow-y: auto;
      top: 0;
      right: 0;
      padding-bottom: 2%;
      padding-top: unset;
      scrollbar-width: none; 
      scrollbar-color: transparent transparent; 
      -ms-overflow-style: none;
    }
    
    .dTreatments_PopMain::-webkit-scrollbar {
      width: 5px;
    }
    
    .Treatments_PopMain::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    
    .Treatments_First {
      display: flex;
      /* flex-direction: row; */
      justify-content: space-between; /* Align X button to the right */
      height: 8%;
      width: 100%;
      /* box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.03); */
      cursor: pointer;
      align-items: center;
      font-style: normal;
      font-size: 18px;
      /* padding-inline: 6%; */
      padding-left: 3%;
      padding-right: 2%;
      margin-left: -3%;
      margin-bottom: 3%;
      background-color: #FFF;
    }
    
    .Treatments_First p {
      /* width: 80%; */
      height: 100%;
      padding-left: 3%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 700;
      font-size: var(--popupform-header);
      line-height: 150%;
      color: #424242;
    }
    
    .Treatments_PopMain .Treatments_Name {
      width: 95%;
      justify-content: center;
      padding-left: 3%;
      
      font-family: "Urbanist ", Arial, sans-serif;
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
    }
    
    
    .Treatments_PopMain .Treatments_Name input {
      /* width: 100%; */
      align-items: center;
      padding: 10px 16px;
      border: 1px solid #cccccc;
      border-radius: 8px;
      outline: none;
      background: var(--Greyscale-50);
      /* height: 75%; */
      
    }
    .Treatments_PopMain .Treatments_Name textarea{
      width: 100%;
      align-items: center;
      padding: 10px 16px;
      border: 1px solid #cccccc;
      border-radius: 8px;
      outline: none;
      background: var(--Greyscale-50);
    }
    input[type=time]::-webkit-datetime-edit-hour-field:focus,
    input[type=time]::-webkit-datetime-edit-minute-field:focus,
    input[type=time]::-webkit-datetime-edit-second-field:focus,
    input[type=time]::-webkit-datetime-edit-ampm-field:focus,
    input[type=time]::selection  {
      background-color: var(--primary-green);
    }
    .rmsc .dropdown-heading {
      height: 45px !important;
    }
    
    .Treatments_PopMain .Treatments_Name label sup {
      color: #dc3545;
    }
  
  
    
  
  
    
    
   
    
   
    
    
  
    
    
      .Treatments_End {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding-top: 20px;
        width: 100%;
        padding: 5% 3%; 
    }
  
  
  .Treatments_End .Treatments_buttona, .Treatments_End .Treatments_buttonb {
    width: 100%;
    height: 38px;
    color: #616161;
    font-style: normal;
    font-size: var(--popupform-submit-cancel-button);
    border: 1px solid #616161;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;
    border-radius: 8px;
  }
  
  .Treatments_End .Treatments_buttonb {
    background: var(--primary-green);
    color: #fefefe;
    border: none;
  }
  
  .Treatments-label{
      color: var(--Greyscale-900);
    
    /* Body Large/Semibold/16px */
    font-family: Urbanist;
    font-size: var(--popupform-label);
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    }
    .custom-field{
      background-color: var(--Greyscale-50);
    }
    .custom-field::placeholder{
    color: var(--Greyscale-500);
    font-family: Urbanist;
    font-size: var(--popupform-placeholder);
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    }
    .Treatments-dropdown .css-1gytqch-ValueContainer{
      padding: 6px !important;
    }
    .Treatments-dropdown .css-13cymwt-control{
    background: var(--Greyscale-50) !important;
    }
    .treatmentfield_container {
      width: 100%;
      display: flex;
      flex-direction: row;
      
      
    }
    .fields-wrapper {
      display: flex;
      width: 100%;
    

    }
    
    .treatmentfield_container .serviceActivity_Togglebutton-1 .serviceActivity_ToggleButtonGroups-1 button {
      
      margin-top: 25%; 
      width: 120%;
      height: 120%;
    }
     .Treatments_PopMain .treatmentfield_container .Treatments_Name input {
        width: 90%;
    }
    @media (max-width: 1024px){
      .Treatments_PopMain {
        width: 45%;
      }
      .Treatments_First p {
        padding-left: 5.3%;
      }
      .Treatments-form{
        padding-left: 2.5%;
      }
    }
    @media (max-width: 768px) {
      .Treatments_PopMain {
        width: 50%;
      }
  
      .fields-wrapper {
        flex-direction: column;
      }
    
      .treatmentfield_container .serviceActivity_Togglebutton-1 .serviceActivity_ToggleButtonGroups-1 button{
       width: 100%;
       margin-top: 5px;
  

     }
     .treatmentfield_container .Treatments_Name {
        width: 100%;
        margin-bottom: 15px;
        margin-top: 20px;
        
      }
    }

    
 