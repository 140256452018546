.AddDivision {
  position: fixed;
  top: 0%;
  right: 25%;
  z-index: 9999;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.9);
}

.MainPae {
  width: 90%;
  height: 100%;
  text-align: center;
  justify-content: center;
  padding-top: 1%;
  z-index: 1;
  /* add this line */
}

.sect-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.sect-1 .h1 {
  float: left;
  font-family: "Urbanist", Arial, sans-serif;
  font-style: normal;

  font-size: 26px;
}

.a {
  display: flex;
  justify-content: flex-end;
  width: 17.4%;
}

.b {
  display: flex;
  justify-content: flex-end;
  width: 65%;
}

.sect-1 .buttona {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #5d7202;
  width: 180px;
  height: 35px;
  color: #5d7202;
  font-family: "Urbanist", Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
}

.sect-1 .buttonb {
  background-color: #5d7202;
  border: none;
  border-radius: 5px;
  width: 180px;
  height: 35px;
  color: rgb(255, 255, 255);
  font-family: "Urbanist", Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
}

.diet-plan-container {
  padding: 20px;
  border-radius: 8px;
  /* background-color: #f0f0f0; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.diet-plan-container .guest-diet-plan {
  width: 50%;
}

.data-row {
  /* display: flex; */
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.select-container {
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.date-picker-container {
  width: 50%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.diet-plan-container .select-diet {
  border: 1px solid black;
  padding: 20px;
  border-radius: 30px;
}
.diet-plan-container .select-tretment {
  border: 1px solid black;
  padding: 20px;
  border-radius: 30px;
}
.select-tretment .tretment-time {
  display: flex;
  gap: 20px;
  width: "100%";
}

.select-tretment .tretment-time .MuiFormControl-root {
  width: 100%;
}
.select-tretment .morning-tretment,
.select-tretment .evening-tretment {
  width: 48%;
  border: 1px solid black;
  /* border-radius: 10px; */
  padding: 10px;
}

.select-container:last-child,
.date-picker-container:last-child {
  margin-right: 0;
}

.data-label {
  font-size: 16px;
  margin-bottom: 5px;
}

.required {
  color: #ed3237;
}

.dietplan_dropdown {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ccc;
  height: 46px;
}

.dietplan_dropdown > .css-13cymwt-control {
  height: 100%;
}

.dietplan_dropdown > .css-t3ipsp-control {
  height: 100%;
}

.meal-inputs-container {
  margin: 20px;
}

.input-row {
  /* display: flex; */
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.meal-inputs-container h1 {
  font-size: 20px;
  margin: 0;
  width: 90px;
}

.meal-input {
  /* flex: 1; */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin: 3px;
}

.submit-container {
  display: flex;
  justify-content: flex-end;
}

.submit-button {
  background-color: var(--primary-green);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  width: 30%;
  margin: 20px;
}

.submit-button:hover {
  background-color: var(--secondary-green);
}

.error-message {
  color: red;
  margin-top: 5px;
}

/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure the popup is above other content */
  padding: 10px;
}

/* Popup Box */
.popup-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-y: auto;
  max-height: 80vh; /* Ensure the popup doesn't overflow the screen */
}

/* Title */
.popup-box h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

/* Therapy Selection Container */
.therapy-selection {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

/* Left and Right Columns for Therapy Slots */
.therapy-column {
  width: 48%; /* Divide the space equally */
}

.therapy-column h3 {
  font-size: 1.2em;
  color: #444;
  margin-bottom: 10px;
}

/* List of Slots */
.therapy-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.therapy-column li {
  margin-bottom: 10px;
  font-size: 1em;
}

.therapy-column label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.therapy-column input[type="radio"] {
  margin-right: 8px;
}

/* Buttons Container */
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.buttons button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttons button:hover {
  background-color: #0056b3;
}

/* Close Button Styling */
.close-button {
  background-color: #f44336; /* Red background for close button */
}

.close-button:hover {
  background-color: #d32f2f;
}

@media (max-width: 900px) {
  .data-row {
    flex-direction: column;
  }

  .select-container,
  .date-picker-container {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .data-label {
    font-size: 18px;
  }

  .input-row {
    flex-direction: column;
    align-items: flex-start;
  }

  h1 {
    width: auto;
    margin-bottom: 5px;
  }

  .meal-input {
    width: 100%;
  }

  .submit-button {
    width: 100%;
    padding: 12px;
  }
}

@media (max-width: 768px) {
  .select-tretment .tretment-time {
    flex-direction: column;
  }
}

.button_AddItemRow {
  font-size: 20px;
  padding: 0.5px 11px;
  color: white;
  background: var(--primary-green);
  border-radius: 50%;
}

.button_RemoveItemRow {
  font-size: 12px;
  padding: 3px 10px;
  color: white;
  background: var(--primary-green);
  border-radius: 50%;
}

.guest-diet-schedule {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.datewise-guest-treatment {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.datewise-guest-treatment:hover {
  transform: translateY(-10px);
}

.selected-date-treatment p:first-child {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 10px;
}

.selected-date-treatment p {
  font-size: 14px;
  color: #2c3e50;
  margin: 5px 0;
}

.selected-date-treatment p:nth-child(2),
.selected-date-treatment p:nth-child(3)
.selected-date-treatment p:nth-child(4)  {
  font-weight: bold;
  color: #2c3e50;
}



@media (min-width: 768px) {
  .guest-diet-schedule {
    gap: 30px;
  }
}
