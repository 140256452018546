:root {
    --primary-color: #ED3237;
    --default-white: #fff;
    --primary-green: #6C7D02;
    --secondary-green:#b4ba3d;
    --default-black: #000000;
    --Greyscale-900: #212121;
    --Greyscale-50: #FAFAFA;
    --Secondary-500: #FFC000;
    --Greyscale-800: #424242;
    --alert-status-error: #F75555;
    --alert-status-success: #0ABE75;
    --Greyscale-900:#212121;
    --Greyscale-700: #616161;
    --Secondary-30: #899197;
    --Greyscale-500: #9E9E9E;
  }