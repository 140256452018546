.flexone {
  display: flex;
  justify-content: space-between;
  width: 90%;

  .right {
    width: 7vw;
    display: flex;
    justify-content: space-between;
    .icon {
      background: #ffc000;
      color: white;
      font-size: 2rem;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding-left: 10%;
    }
  }
}

.title {
  font-family: "urbanistl";
  font-size: 3rem;
  margin-top: 1vh;
}

.desc {
  color: #7c7979;
  font-family: "urbanistl";
  font-size: 1.25rem;
  width: 80%;
}

.services {
  width: 100%;

  .heading {
    margin-top: 1vh;
    color: black;
    font-family: "urbanist";
    font-size: 1.25rem;
    margin-bottom: 2%;
  }

  .diseasegrid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
    .diseaseitem {
      display: flex;
      font-size: 1rem;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      color: #5d7202;
      background: #f0d996;
      border: 1px solid #ddd;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      font-family: "urbanistl";

      &:hover {
        background-color: #ffc000;
        color: #5d7202;
      }

      &.selected {
        background-color: #ffc000;
        color: #5d7202;
      }

      .icondiv {
        .icon {
          color: #5d7202 !important;
          font-size: 1rem;
        }
      }
    }
  }
 
}

.edit_plus{
  display: flex;
  gap: 10%;
}
