  .LoginPopUpMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .LoginPopUp {
    display: flex;
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
    width: 90%;
    max-width: 400px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }

  .LoginPopUp.selected {
    border: 2px solid #6c7d02;
    border-radius: 8px;
    cursor: pointer;
    background-color: #f9f9f9; /* Optional: Add a subtle background color */
  }
  
  .LoginPopUp:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .LoginPopUpLeft {
    margin-right: 16px;
  }
  
  .LoginPopUpLeft img {
    width: 40px;
    height: 40px;
  }
  
  .LoginPopUpContent h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .LoginPopUpContent p {
    margin: 4px 0 0;
    font-size: 14px;
    color: #666;
  }
  
  .LoginPopUpButton {
    margin-top: 16px;
    text-align: center;
  }
  
  .LoginPopUpButton button {
    background-color: #6c7d02;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .LoginPopUpButton button:hover {
    background-color: var(--secondary-green);
  }
  
  .LoginSignIn {
    margin-top: 8px;
    font-size: 14px;
    color: #666;
    text-align: center;
  }
  
  .LoginSignIn a {
    color: #6c7d02;
    text-decoration: none;
  }
  
  .LoginSignIn a:hover {
    text-decoration: underline;
  }
  