.ProfilePayment {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* margin: 10% 0px; */
    align-items: center;
}

.ProfilePaymentLeft, .ProfilePaymentRight {
    flex: 1 1 0; 
    min-width: 450px;
}
.ProfilePaymentLeft{
    padding: 2% 1%;

}
.ProfilePaymentRight{
    padding: 0;
    margin: 0;
}