.parent {
  display: grid;
  grid-template-columns: max-content auto;
  margin: 1rem auto;
  grid-auto-flow: column;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 0 !important;
  /* height: 14px; */
}

.dashboard_PopMain {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 80%;
  height: 100vh;
  background-color: var(--Greyscale-50);
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  padding-bottom: 2%;
  padding-top: unset;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;
}

.dashboard_PopMain::-webkit-scrollbar {
  width: 5px;
}

.dashboard_PopMain::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.dashboard_First {
  display: flex;
  justify-content: space-between;
  height: 8%;
  width: 100%;
  cursor: pointer;
  align-items: center;
  font-style: normal;
  font-size: 18px;
  padding-left: 3%;
  padding-right: 2%;
  margin-left: -3%;
  margin-bottom: 3%;
  background-color: #fff;
}

.dashboard_First p {
  height: 100%;
  padding-left: 6%;
  display: flex;
  font-family: "Urbanist ", Arial, sans-serif;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: var(--popupform-header);
  line-height: 150%;
  color: #424242;
}

.dashboard_Form {
  width: 100%;
  padding: 2%;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-check-input {
  margin-right: 0.5rem;
}

.invalid-feedback {
  color: red;
  font-size: 0.875rem;
}

/* CSS of Gender Count. */

.dashboard-card-container-Profile-plan {
  display: flex;
  justify-content: center;
  align-items: stretch; /* Ensures all child elements have the same height */
  flex-wrap: wrap; /* Makes them stack on mobile */
  gap: 20px;
  width: 100%;
  padding: 10px;
}

.dashboard-card-container-Profile-plan > div {
  flex: 1 1 400px; /* Grow & shrink dynamically */
  min-width: 300px;
  max-width: 500px;
  display: flex;
  flex-direction: column; /* Ensures internal content stretches */
}
.dashboard-card-container0 {
  width: 300px;
  height: 173px;
  margin: 0 0 0 0;
  padding: 10px; 
  background: #ffffff;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-title0 {
  font-size: 24px;
  margin: 0 0 0 0;
  color: #333;
  font-weight: 600;
}

.progress-container {
  position: relative;
  width: 250px;
  height: 100px;
  margin: 0 0 0 10px;
}

.semi-circle-chart {
  width: 100%;
  height: 100%;
  /* transform: rotate(180deg); */
  overflow: visible;
}

.tooltip-container {
  margin-top: -2rem;
  text-align: center;
}

.tooltip {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  margin: 5px 0;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.male-dot {
  background-color: #6C7D02;
}

.female-dot {
  background-color: #b4ba3d;
}


/* For Room Counts */
.dashboard-card-container1 {
  width: 100%;
  height: 197px;
  max-height: 300px;
  max-width: 400px; 
  padding: 10px;
  margin: -12.3rem 0 3rem 23.5rem;
  background-color: #ffffff;
  /* border-radius: 8px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Hides horizontal scrolling */
  transition: all 0.3s ease-in-out;
}

.dashboard-card-container1::-webkit-scrollbar {
  width: 6px; /* Adjust scrollbar width */
}

.dashboard-card-container1::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar handle */
  border-radius: 10px; /* Rounds the scrollbar handle */
}

.dashboard-card-container1::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

.dashboard-card-container1::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
}

.dashboard-title1 {
  font-size: 18px;
  margin-bottom: 15px;
  border-bottom: 1px solid #000000;
  font-family: "Urbanist", sans-serif;
  color: #000000;
  font-weight: bold;
  padding-bottom: 5px;
}

.dashboard-card1 {
  display: flex;
  flex-direction: row; /* Stack items vertically for better scroll view */
  /* gap: 10px; */
  margin-top: -7px;
  width: 100%;
}

.dashboard-card-item1 {
  flex: 1;
  background-color: #ffffff;
  text-align: start;
  color: #333333;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.dashboard-card-item1 h3 {
  font-size: 15px;
  border-bottom: 1px solid #d3d3d3;
  font-weight: 600;
  margin-bottom: 10px;
  color: #000000;
  padding-bottom: 5px;
}

.dashboard-card-item1 p {
  font-size: 14px;
  border-bottom: 1px dashed #d3d3d3;
  font-weight: bold;
  color: #333333;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.dashboard-card-item2 {
  flex: 1;
  background-color: #ffffff;
  text-align: end;
  color: #333333;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.dashboard-card-item2 h3 {
  font-size: 15px;
  border-bottom: 1px solid #d3d3d3;
  font-weight: 600;
  margin-bottom: 10px;
  color: #000000;
  padding-bottom: 5px;
}

.dashboard-card-item2 p {
  font-size: 14px;
  border-bottom: 1px dashed #d3d3d3;
  font-weight: bold;
  color: #333333;
  padding-bottom: 5px;
  margin-bottom: 10px;
}


@media (max-width: 600px) {
  .dashboard-card-container1 {
    width: 90%;
    margin: 0;
    max-width: 500px;
  }

  /* .dashboard-card1 {
    flex-direction: row;
  }

  .dashboard-card-item1 {
    width: auto;
  }

  .dashboard-card-item2 {
    width: auto;
  } */
}

/* .dashboard-card1:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
} */

/* To fetch Room Occupied Count */

.dashboard-card-container2 {
  display: flex;
  width: 20rem;
  height: 10.5rem;
  margin: -15.5rem 0 1.5rem 53rem;
  padding: 5px;
  background-color: #ffffff;
  /* background-image: linear-gradient(
    to right,
    var(--primary-green),
    var(--secondary-green)
  ); */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.dashboard-title2 {
  font-size: 17px;
  width: 8rem;
  margin-bottom: -62px;
  /* border-bottom: 1px solid #000000; */
  font-family: "Urbanist", sans-serif;
  color: #000000;
  font-weight: bold;
  padding-bottom: 34px;
}

.dashboard-card-item3 {
  background-color: #ffffff;
  text-align: center;
  color: #333333;
  transition: all 0.3s ease-in-out;
  padding: 0;
}

.dashboard-card-item4 {
  margin: 7rem 0 0 -1rem;
  background-color: #ffffff;
  text-align: left;
  color: #333333;
  transition: all 0.3s ease-in-out;
}

.dashboard-item-label3 {
  color: #000000;
  font-weight: 500;
  margin-top: -5rem;
}

.dashboard-item-label4 {
  color: #000000;
  font-weight: 500;
  margin-top: -4rem;
}

.roomcss1{
  display: flex;
  align-items: center; /* Vertically center the items */
  gap: 10px; /* Add 2px gap between span and h3 */
}


.dashboard-card-item4 .roomcss1 .dot1{
  height: 18px;
  width: 18px;
  background-color: #6C7D02;
  border-radius: 50%;
  display: inline-block;
  margin: -6.7rem 0 -1.7rem 0rem;
}

.roomcss2{
  display: flex;
  align-items: center; /* Vertically center the items */
  gap: 10px; /* Add 2px gap between span and h3 */
  margin-top: 2rem;
}

.dashboard-card-item4 .roomcss2 .dot2{
  background-color: #b4ba3d;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: inline-block;
  margin: -5.7rem 0 -1.7rem 0rem;
}

@media (max-width: 600px) {
  .dashboard-card-container2 {
    width: 90%;
    margin: 16px 0 0 0;
    max-width: 500px;
  }
}

@media (max-width: 374px) {
  .dashboard-card-container2 {
    width: 90%;
    margin: 16px 0 0 0;
    max-width: 500px;
  }

  .dashboard-item-label3 {
    color: #000000;
    font-weight: 500;
    margin-top: -4rem;
    font-size: 14px;
  }
  
  .dashboard-item-label4 {
    color: #000000;
    font-weight: 500;
    margin-top: -3rem;
    font-size: 14px;
  }

  .dashboard-card-item4 .roomcss1 .dot1{
    height: 16px;
    width: 16px;
    background-color: #6C7D02;
    border-radius: 50%;
    display: inline-block;
    margin: -6.7rem 0 -1.7rem -2rem;
  }

  .dashboard-card-item4 .roomcss2 .dot2{
    background-color: #b4ba3d;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    margin: -5.7rem 0 -1.7rem -2rem;
  }
}