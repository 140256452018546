.p-input {
  height: 7vh;
  width: 36vw;
  border: 1px lightgrey solid;
  border-radius: 5px;
}

#cal-button {
  min-width: 17vw;
  background: #5d7202;
  color: white;
  border-radius: 6px;
  height: 6vh;
  margin-left: 19vw;
  margin-top: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
}
#cal-button:hover {
  min-width: 17vw;
  background: #778b1e;
  color: white;
  border-radius: 6px;
  transition: all ease 500ms;
  margin-left: 19vw;
  margin-top: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
}


/* uihfuihffhd */
.mainContainer{
  display: flex;
}
.rightContainer{
  margin-top: 15%;
  padding: 0 !important;
}
.headContainer{
  margin-top: -5vh;
}
.labelProperty{
  font-family: urbanistl;
}
.compulsary{
  color: red;
}



@media only screen and (max-width: 800px) {
  #cal-button {
    min-width: 44vw;
    background: #5d7202;
    color: white;
    border-radius: 6px;
    height: 6vh;
    margin-left: 46vw;
    margin-top: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
      0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
      0 16px 16px rgba(0, 0, 0, 0.12);
  }
  #cal-button:hover {
    min-width: 44vw;
    background: #778b1e;
    color: white;
    border-radius: 6px;
    transition: all ease 500ms;
    margin-left: 46vw;
    margin-top: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
      0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
      0 16px 16px rgba(0, 0, 0, 0.12);
  }
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes fadeInZoom {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.popup {
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50vh;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  animation: fadeInZoom 0.5s ease-out;
}

.popup h3 {
  margin-top: 0;
}

.popup p {
  margin-bottom: 20px;
}

.progress-bar-container {
  width: 100%;
  height: 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-top: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #007bfd;
  transition: width 0.1s ease-in-out;
}

.onboardingImage{
  height: 66%;
  width: 100%;
}