 @import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@700&display=swap");

.steps_texthead {
  text-align: center;
  margin-top: 2rem;
  font-family: "Quicksand", sans-serif;
  font-size: 2rem;
  position: relative;
}

@media screen and (max-width: 500px) {
  .steps_texthead {
    font-size: 1.5rem;
  }
}

.steps_container {
  padding-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.steps_containerleft {
  width: 40%;
}

.steps_heading {
  color: #5d7202;
  font-size: 2rem;
  font-weight: 700;
}

.steps_text {
  color: #424242;
  font-size: 1.5rem;
  width: 80%;
  margin-bottom: 20px;
  transition: opacity 0.3s ease;
}

.steps_controls {
  display: flex;
  font-size: 2rem;
  color: #bdbdbd;
  margin-top: 5px;
}

.steps_prev:hover, .steps_next:hover {
  color: #5d7202;
  transition: all ease 500ms;
  cursor: pointer;
}

.steps_containerright {
  width: 40%;
}

.steps_image {
  position: relative;
  display: flex;
  justify-content: center;
}


.steps_image img {
  width: 100%;
  height: 60vh;
  transition: opacity 0.3s ease;
}

@media screen and (max-width: 768px) {
  .steps_container {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;
  }

  .steps_containerleft, .steps_containerright {
    width: 100%;
    text-align: center;
  }

  .steps_containerleft {
    margin-bottom: 2rem;
  }

  .steps_heading {
    font-size: 1.5rem;
  }

  .steps_text {
    font-size: 1.2rem;
    width: 100%;
  }

  .steps_controls {
    justify-content: center;
    margin-top: 1rem;
  }

  .steps_containerright .steps_image img {
    height: auto;
    max-width: 60%;
  }
}

center span {
  font-size: 1.5rem;
}

center {
  font-size: 18px;
  font-weight: 500;
} 


.step_button-wrapper-prev .step_button-prev {
  display: block;
  position: relative;
  width: 76px;
  height: 76px;
  margin: 0;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border: 0;
}

.step_button-wrapper-prev .step_button-prev:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 7px;
  border: 3px solid #424242; /* Update dynamically for light/dark mode */
  transition:
    opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.step_button-wrapper-prev .step_button-prev:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 7px;
  border: 4px solid #5d7202;
  transform: scale(1.3);
  transition:
    opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}

.step_button-wrapper-prev .step_button-prev:hover:before,
.step_button-wrapper-prev .step_button-prev:focus:before {
  opacity: 0;
  transform: scale(0.7);
  transition:
    opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.step_button-wrapper-prev .step_button-prev:hover:after,
.step_button-wrapper-prev .step_button-prev:focus:after {
  opacity: 1;
  transform: scale(1);
  transition:
    opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.step_button-wrapper-prev .step_button-prev-box {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.step_button-wrapper-prev .step_button-prev-elem {
  display: block;
  width: 30px;
  height: 30px;
  margin: 24px 18px 0 22px;
  transform: rotate(360deg);
  fill: #f0eeef;
}

.step_button-wrapper-prev .step_button-prev:hover .step_button-prev-box,
.step_button-wrapper-prev .step_button-prev:focus .step_button-prev-box {
  transition: 0.4s;
  transform: translateX(-69px);
}


.step_button-wrapper-next .step_button-next {
  display: block;
  position: relative;
  width: 76px;
  height: 76px;
  margin: 0;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border: 0;
}

.step_button-wrapper-next .step_button-next:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 7px;
  border: 3px solid #424242; /* Update dynamically for light/dark mode */
  transition:
    opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.step_button-wrapper-next .step_button-next:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 7px;
  border: 4px solid #5d7202;
  transform: scale(1.3);
  transition:
    opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}

.step_button-wrapper-next .step_button-next:hover:before,
.step_button-wrapper-next .step_button-next:focus:before {
  opacity: 0;
  transform: scale(0.7);
  transition:
    opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.step_button-wrapper-next .step_button-next:hover:after,
.step_button-wrapper-next .step_button-next:focus:after {
  opacity: 1;
  transform: scale(1);
  transition:
    opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.step_button-wrapper-next .step_button-next-box {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.step_button-wrapper-next .step_button-next-elem {
  display: block;
  width: 30px;
  height: 30px;
  margin: 24px 18px 0 22px;
  transform: rotate(360deg);
  fill: #f0eeef;
}

.step_button-wrapper-next .step_button-next:hover .step_button-next-box,
.step_button-wrapper-next .step_button-next:focus .step_button-next-box {
  transition: 0.4s;
  transform: translateX(-69px);
}
