
@import "./assets//colors//colors.css";
@import "./assets/fonts/static/fontSize.css";

/* 
 @import url('https://fonts.googleapis.com/css2?family=Urbanist&display=swap');

*{

  font-family: 'Urbanist', sans-serif;
  font-weight: 500;
}  */

@font-face {
  font-family: urbanist;
  src: url(assets/fonts/static/Urbanist-Regular.ttf);
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Urbanist", sans-serif;

    /* font-weight: 400; */
  }
}
/* 
@tailwind base;
@tailwind components;
@tailwind utilities; */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
