.itemmaster_MainPage {
  width: 95%;
  height: 100%;
  text-align: center;
  justify-content: center;
  padding-top: 1%;
  z-index: 1;
  margin: 0 auto;
}

.itemmaster_sect-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.itemmaster_manage {
  width: 45%;
}

.export_items {
  display: flex;
}

.itemmaster_sect-2 {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: white;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;
  justify-content: space-between;
}

.itemmaster_toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.toggle_flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ToggleButtonGroups button.Mui-selected {
  background-color: var(--primary-green);
  color: white;
  font-weight: 600;
}

.ToggleButtonGroups button:hover {
  background-color: var(--primary-green);
  color: white;
}

.ToggleButtonGroups-root {
  width: 40px;
  height: 32px;
}

.ToggleButtonGroups {
  background-color: rgb(255, 255, 255);
}

.itemdatgrid {
  padding-top: 3%;
}

.itemdatgrid .itemmaster-table {
  width: 100%;
  overflow-x: auto;
}

.itemdatgrid .tableHeaderCell {
  color: var(--Greyscale-900);
  background: var(--Greyscale-50);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
}

.itemdatgrid.tableBodyCell {
  color: var(--Greyscale-900);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
}
.css-1ex1afd-MuiTableCell-root {
  padding: 1rem 1rem !important;
}
.css-1sl31xx {
  display: flex;
  align-items: center;
}
.css-1kuq5xv-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
}

/* Popup */

.item_PopMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 42%;
  height: 100vh;
  background: var(--Greyscale-50);
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;
}

.item_PopMain::-webkit-scrollbar {
  width: 5px;
}
.item_PopMain::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.item_First {
  display: flex;
  justify-content: space-around;
  height: 8%;
  width: 100%;
  background: var(--default-white);
  cursor: pointer;
  align-items: center;
  font-style: normal;
  font-size: 18px;
}

.item_First p {
  width: 80%;
  height: 100%;
  display: flex;
  font-family: "Urbanist ", Arial, sans-serif;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: var(--popupform-header);
  line-height: 150%;
  color: #424242;
}

.Item_Form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 5%;
  gap: 0.5rem;
}

.item_PopMain .item_name {
  width: 100%;
  padding-left: 3%;
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.item_PopMain .item_name input {
  width: 100%;
  align-items: center;
  background: var(--Greyscale-50);
  padding: 10px 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  outline: none;
}

.item_name label {
  color: var(--Greyscale-900);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.item_name input::placeholder {
  color: var(--Greyscale-500);
  font-family: Urbanist;
  font-size: var(--popupform-placeholder);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.item_PopMain .item_name label sup {
  color: #dc3545;
}

.item_dropdown {
  width: 100%;
  padding-left: 3%;
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.combo_CustomService {
  max-height: 60%;
}

.item_dropdown label {
  color: var(--Greyscale-900);
  font-size: var(--popupform-label);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.item_Togglebutton-1 {
  /* padding-right: 0; */
  width: 100%;
  padding-left: 3%;
  height: 40px !important;
}

.item_Togglebutton-1 .item_ToggleButtonGroups-1 {
  color: #000000;
  height: 100%;
  width: 100%;
}

.item_Togglebutton-1 .item_ToggleButtonGroups-1 button {
  color: #000000;
  border: none;
  background: #f5f5f5;
  width: 40%;
}

.item_Togglebutton-1 .item_ToggleButtonGroups-1 button:hover {
  background-color: var(--primary-green);
  color: white;
}

.item_Togglebutton-1 .item_ToggleButtonGroups-1 .Mui-selected {
  background-color: var(--primary-green);
  color: white;
}

.item_end {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding-top: 20px;
  width: 100%;
  padding-left: 3%;
  padding-right: 2%;
}

.item_end .item_buttonb,
.item_end .item_buttona {
  width: 100%;
  height: 38px;
  color: #616161;
  font-style: normal;
  font-size: var(--popupform-submit-cancel-button);
  border: 1px solid #616161;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}
.item_end .item_buttonb {
  background: var(--primary-green);
  color: #fefefe;
  border: none;
}

.item_end .item_buttonb:hover {
  background: var(--primary-green);
}

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
  .item_PopMain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 45%;
    height: 100vh;
    background: var(--Greyscale-50);
    z-index: 1200;
    position: fixed;
    overflow-y: auto;
    top: 0;
    right: 0;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    -ms-overflow-style: none;
  }
}

@media (max-width: 600px) {
}
