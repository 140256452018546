.sec-left,
.sec-right {
  /* height: 100vh; */
  width: 50%;
  display: flex;
}

.sec-left {
  visibility: visible;
}

.sec-right {
  width: 50%;
}

.sec-right h1 {
  font-size: 30px;
  font-weight: bolder;
  color: var(--primary-green);
}

/* Container Styles */
.sec-right > div {

  width: 100%;
  padding: 20px;
  background-color: white;
}
.sec1-right > div {
  width: 100%;
  padding: 20px;
  background-color: white;
}
/* Logo Styles */
#logo {
  width: 17%;
  height: auto;
}
.loginsec{
  display: flex;
  flex-direction: column;
}
/* Typography Styles */
#typo1 {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#typo2 {
  font-size: 16px;
  color: #555;
  padding-top: 3%;
}

/* Input Styles */
.inputn {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
}

/* Button Styles */
#ca-button {
  width: 100%;
  padding: 10px;
  background-color: #4caf50; 
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}


/* Error Styles */
.errorl,
.error {
  color: red;
  margin-top: 5px;
}
.loginForgotPasswordMain{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


@media only screen and (max-width: 800px) {
  .sec-left {
    visibility: hidden;
    width: 0;
  }

  .sec-right {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
.loginsec{
  padding-left: 25%;
}
}
