.property_listingMainContainer {
    display: flex;
    flex-direction: row;
  }
  
  .property_listingSecLeft {
    width: 40%;
  }
  
  .property_listingSecRight {
    width: 60%;
  }
  
  .property_listingRightContainer {
    padding: 20px;
  }
  
  .property_listingHeadContainer {
    text-align: center;
  }
  
  #property_listingLogo {
    width: 100px;
    height: auto;
  }
  
  .property_listingTypo1 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .property_listingTypo2 {
    font-size: 18px;
    color: gray;
  }
  
  .property_listingTypo3 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .property_listingLabel {
    font-family: urbanistl;
  }
  
  .property_listingCompulsory {
    color: red;
  }
  
  .property_listingRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .property_listingField {
    width: 48%;
  }
  
  .property_listingInput {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .property_listingError {
    color: red;
  }
  
  .property_listingButton {
    width: 100%;
    padding: 10px;
    background-color: var(--primary-green);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .property_listingButton:hover {
    background-color: var(--secondary-green);
  }
  
  /* Mobile View */
  @media only screen and (max-width: 768px) {
    .property_listingMainContainer {
      flex-direction: column;
    }
  
    .property_listingSecLeft {
      display: none;
    }
  
    .property_listingSecRight {
      width: 100%;
    }
  
    .property_listingRow {
      display: block;
    }
  
    .property_listingField {
      width: 100%;
    }
  }
  