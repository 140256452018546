/* .MUI_Input .MuiInput-underline::after{
    border-bottom: none !important;
}

.MUI_Input .MuiInput-underline:before {
    border-bottom: none !important;
}

.MUI_Input .MuiInput-underline:after{
    border-bottom: none !important;
}

.MUI_Input .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
} */

.MultiCheck_box .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
    background-color: transparent !important;
}

.MultiCheck_box  .MuiSelect-select:focus{
    background-color: transparent !important;
}

 .MultiCheckBox .MuiSvgIcon-root {
    fill: var(--primary-green) !important;
}
